import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { algo, fullStack, system } from '../../assets/data/images';
import { Avatar, Button } from '@material-ui/core';

// function TabPanel(props) {

//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const {isMobile} = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    console.log('handleChange:', newValue)
    setValue(newValue);
    props.setTabIndex(newValue);
  };
 
  return (
    <Box sx={{ display:'flex', width: '100%' }}>
      {
      isMobile?
      <Box sx={{ display:'flex', borderBottom: 1, padding:'5px', borderColor: 'divider', fontWeight:'bold'}}>
        <Tabs orientation='vertical' value = {value} onChange = {handleChange} aria-label = "basic tabs example">

          <Tab className='MuiTab-wrapper' style={{borderRadius:'10px', justifyContent:'flex-start', padding:'5px 10px', border:'1px solid white', fontWeight:'bold', color:'#37465A'}} label="Full-Stack, Web & Mobile" {...a11yProps(0)}
            icon={ <Avatar src={fullStack} style={{marginTop:'8px'}}></Avatar>}>
          </Tab>
          <Tab className='MuiTab-wrapper' style={{marginTop:'5px', padding:'5px 10px', borderRadius:'10px', border:'1px solid white', fontWeight:'bold', color:'#37465A'}} label="Algorithms & Data Structures" {...a11yProps(0)}
            icon={ <Avatar src={algo} style={{marginTop:'8px'}}></Avatar>}>
          </Tab>

          <Tab className='MuiTab-wrapper' style={{marginTop:'5px', padding:'5px 10px', borderRadius:'10px', border:'1px solid white', fontWeight:'bold', color:'#37465A'}} label="System Design & Architecture" {...a11yProps(0)}
            icon={ <Avatar src={system} style={{marginTop:'8px'}}></Avatar>}>
          </Tab>        
        </Tabs>
      </Box>:
            <Box sx={{ display:'flex', borderBottom: 1, borderColor: 'divider' , marginLeft:'1em',  fontWeight:'bold'}}>
            <Tabs className='MuiTab-wrapper' orientation='horizontal' style={{display:'flex'}} value = {value} onChange = {handleChange} aria-label = "basic tabs example">
              <Tab className='MuiTab-wrapper' style={{padding:'5px 10px', borderRight:'1px solid white', fontWeight:'bold', color:'#37465A'}} label="Full-Stack, Web & Mobile" {...a11yProps(0)}
                  icon={ <Avatar src={fullStack}></Avatar>}>
              </Tab>
              <Tab className='MuiTab-wrapper' style={{padding:'5px 10px', borderRight:'1px solid white', fontWeight:'bold', color:'#37465A'}} label="Algorithms & Data Structures" {...a11yProps(0)}
                  icon={ <Avatar src={algo}></Avatar>}>
              </Tab>

              <Tab className='MuiTab-wrapper'  style={{fontWeight:'bold', color:'#37465A', display:'flex'}} label="System Design & Architecture" {...a11yProps(0)}
                  icon={ <Avatar src={system}></Avatar>}>
              </Tab>
             </Tabs>
          </Box>}
    
    </Box>
  );
}