import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import './SkillTest.scss';
import QuizTest from "./QuizTest";
import { Button, Grid } from "@material-ui/core";
import SessionClock from "../../utils/clock/SessionClock";
import { API, fetchData, createNewTest } from "../../../config/apiService";
import { saveAssessNRank } from "../../../utils/skillRankUtils";
import { SetPopupContext } from "../../home/component/MainRouter";
import { Close } from "@material-ui/icons";

const QuizTestCatalog = ({ width, height, setModal }) => {
    const setPopup = useContext(SetPopupContext);
    const shortlistedCandidate = useSelector(state => state.shortlistReducer.shortlistedCandidates);
    const [topics, setTopics] = useState();
    const [quizzes, setQuizzes] = useState();
    const [selected, setSelected] = useState();
    const [start, setStart] = useState();
    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');

    const childRef = useRef();
    const time = 10; //10 minutes

    const clockNotification = () => {
        //when given time finished
        setDone(0);
    }

    useEffect(() => {
        loadTabData();
    }, [])

    const loadTabData = async () => {
        let address = `${API.skill}/quiz?topic=skills`
        let temp = await fetchData(address, "Quiz Catalog");
        setTopics(temp.quizzes);
    }

    const onChange = (e) => {
        if (e.target.name === 'testName')
            setName(e.target.value.trim());
        if (e.target.name === 'companyName')
            setCompanyName(e.target.value.trim());
    }

    const sendTestLink = async () => {
        let candidateData = [];
        for (let candidate of shortlistedCandidate) {
            candidateData.push({
                candidateId: candidate._id,
                emailId: candidate.email
            });
        }
        const testData = {
            name: name,
            topic: selected,
            company:companyName,
            candidateData: candidateData
        }
        const api = `${API.test}/create`;
        const result = await createNewTest(api, testData);
        console.log(result);
        if(result){
            setPopup({
                open: true,
                severity: "success",
                message: "Test link sent successfully",
            });
            setModal(false);
        }
        else{
            setPopup({
                open: true,
                severity: "error",
                message: "Something went wrong, please try again!",
            });
        }
    }

    const stop = () => {
        console.log("Test Stop: ", selected);
        setStart();
        if (childRef.current) {
            childRef.current.stopClock();
        }
    }

    const onItemSelect = (skill) => {
        setStart();
        setSelected(skill);
        if (childRef.current) {
            childRef.current.stopClock();
        }
    }

    const setDone = (score, assess) => {
        if (score >= 1) {
            //save score into database
            console.log("Saving quiz test info", score, assess);
            let data = {};
            data.assess = assess;
            let unqId = [];
            for (let x in assess) {
                unqId.push(x);
            }
            data.title = selected;
            data.id = selected + "-" + unqId.join('-');

            saveAssessNRank(data, "Quizzes", "quiz", setPopup);

        } else {

        }
        setStart();
        if (childRef.current) {
            childRef.current.stopClock();
        }
    }

    return <div className="topic-chip-container" style={{ overflow: 'scroll', padding: '1em', background: 'white', marginTop: '40px', marginLeft: '10px', marginBottom: '40px', width: width, height: height, borderRadius: '10px', border: '0.5px solid lightgray' }}>
        <div className="flex justify-between">
            <h1 className="x_576e2b6f">Quiz Assessment Test</h1>
            <span><Button color="secondary" variant="outlined" size="small" endIcon={<Close />} onClick={() => setModal(false)} >Close</Button></span>
        </div>
        <hr></hr>
        {!start && <h3>Select a topic</h3>}
        {topics && topics.map((item, key) => {
            return <div key={key} className="topic-chip hovered" onClick={() => onItemSelect(item.skill_name)}>
                <div className="justify-content-center align-self-center">
                    <img className="topic-logo1" />
                    <span style={{ color: '#37465A' }}><b>&nbsp;&nbsp;{item.skill_name}</b></span>
                </div>
            </div>


        })}
        {selected ? <>
            <h1 style={{ marginTop: '1em', marginBottom: '0.5em' }}>
                <span className="x_576e2b6f">{selected} : Quiz Test</span>
                <hr></hr>
            </h1>

            <Grid item xs={11} >
                <div style={{ marginBottom: '0.5em', borderRadius: '10px', padding: '10px', fontSize: '1.2em', border: '0.5px solid lightgray' }}>
                    <span style={{ fontWeight: 'bold', color: 'blue', padding: '1em', }}>Test Details:</span>
                    <ul style={{ paddingLeft: '2em', marginTop: '1em', }}>
                        <li>{`No. of questions: 40`}</li>
                        <li>{`Test duration: 60 Minutes`}</li>
                        <li>{`Test Link Expires in : 2 days`}</li>
                        <li></li>
                        {/* <li>{`Immediately after starting the test, questions will appear`}</li> */}
                        <li>{`Several unique methods have already been implemented into the system to detect cheating and inappropriate behavior.`}</li>
                        <li>{`Copy/paste of AI contents is prohibited.`}</li>
                        {/* <li>{`A unique and accurate answer will earn you more points.`}</li> */}
                        {/* <li>{`Up to 100 points will be assigned to each successful submission.`}</li> */}
                    </ul>
                </div>

                <div>
                    <label for="testName" className="after:content-['*'] block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name
                        <sup style={{ color: 'red', fontFamily: 'sans-serif', fontWeight: 'bold' }}>&nbsp;* min-6</sup>
                    </label>
                    <input type="text" name="testName" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Ex- NIT Delhi 2024" required onChange={onChange} />

                    <label for="companyName" className="after:content-['*'] block mb-2 text-sm font-medium text-gray-900 dark:text-white">Company/Organization
                        <sup style={{ color: 'red', fontFamily: 'sans-serif', fontWeight: 'bold' }}>&nbsp;* min-6</sup>
                    </label>

                    <input type="text" name="companyName" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Ex- Joblient" required onChange={onChange} />
                </div>
            </Grid>

            <Grid className="md:flex justify-center items-center">
                <Grid className="">
                    <Button disabled={name?.length < 6 || companyName?.length < 3} style={{ margin: '1.4em', width: '10em', color: 'white', background: name?.length < 6 || companyName?.length < 3 ? '#808080' : '#9B4C91', }} onClick={() => { sendTestLink() }}> Send Test Link</Button>
                </Grid>
                {/* <Grid className="ml-10">
                    <div className={`${start ? 'mv-rail-sticky-400' : ''}`}>
                        <div id="content_7_btf_wrapper" className="adunitwrapper content_btf_wrapper mv-size-320x50">
                        </div>
                    </div>
                </Grid> */}
                {/* <Grid className="md:mt-4 ml-6">
                    <SessionClock  clockNotification={clockNotification} ref={childRef} time={time} reset={"true"}></SessionClock>
                </Grid> */}
            </Grid>

            {/* {start&&quizzes&&<QuizTest questions = {quizzes} setDone= {setDone}></QuizTest>} */}
        </> : <></>
        }
        {/* <div style={{height:'10em'}}></div> */}
    </div>

}

export default QuizTestCatalog;