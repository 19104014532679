export const designDemo = {
    "draw": 
    {
        "nodes": [
            {
                "id": "n1",
                "w": "112.98",
                "h": "40.00",
                "x": "68.24",
                "y": "213.50",
                "s": {
                    "type": "Flow",
                    "shape": "PreDefinedProcess"
                },
                "a": "",
                "r": 270
            },
            {
                "id": "n2",
                "w": "116.00",
                "h": "40.00",
                "x": "67.74",
                "y": "359.26",
                "s": {
                    "type": "Flow",
                    "shape": "PreDefinedProcess"
                },
                "a": "",
                "r": 270
            },
            {
                "id": "n3",
                "w": "110.00",
                "h": "40.00",
                "x": "67.74",
                "y": "534.01",
                "s": {
                    "type": "Flow",
                    "shape": "PreDefinedProcess"
                },
                "a": "",
                "r": 270
            },
            {
                "id": "n4",
                "w": "101.25",
                "h": "103.75",
                "x": "252.14",
                "y": "358.89",
                "s": {
                    "type": "Flow",
                    "shape": "Decision"
                },
                "a": "Load\n Balancer",
                "r": 0
            },
            {
                "id": "n5",
                "w": "57.50",
                "h": "83.75",
                "x": "616.02",
                "y": "385.15",
                "s": {
                    "type": "Flow",
                    "shape": "Process"
                },
                "a": "",
                "r": 0
            },
            {
                "id": "n6",
                "w": "57.50",
                "h": "83.75",
                "x": "602.27",
                "y": "373.90",
                "s": {
                    "type": "Flow",
                    "shape": "Process"
                },
                "a": "",
                "r": 0
            },
            {
                "id": "n7",
                "w": "57.50",
                "h": "83.75",
                "x": "586.02",
                "y": "360.15",
                "s": {
                    "type": "Flow",
                    "shape": "Process"
                },
                "a": "App Servers",
                "r": 0
            },
            {
                "id": "n8",
                "w": "78.75",
                "h": "40.00",
                "x": "210.90",
                "y": "107.51",
                "s": {
                    "type": "Flow",
                    "shape": "PaperTap"
                },
                "a": "CDN Country",
                "r": 0
            },
            {
                "id": "n9",
                "w": "78.25",
                "h": "41.25",
                "x": "239.12",
                "y": "589.39",
                "s": {
                    "type": "Flow",
                    "shape": "PaperTap"
                },
                "a": "CDN \nUS",
                "r": 0
            },
            {
                "id": "n10",
                "w": "50.00",
                "h": "40.00",
                "x": "636.24",
                "y": "74.93",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 0
            },
            {
                "id": "n11",
                "w": "50.00",
                "h": "40.00",
                "x": "676.24",
                "y": "74.68",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "Kafka",
                "r": 0
            },
            {
                "id": "n12",
                "w": "50.00",
                "h": "40.00",
                "x": "716.24",
                "y": "74.43",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 0
            },
            {
                "id": "n13",
                "w": "57.50",
                "h": "83.75",
                "x": "428.18",
                "y": "605.23",
                "s": {
                    "type": "Flow",
                    "shape": "Process"
                },
                "a": "",
                "r": 0
            },
            {
                "id": "n14",
                "w": "57.50",
                "h": "83.75",
                "x": "414.43",
                "y": "593.98",
                "s": {
                    "type": "Flow",
                    "shape": "Process"
                },
                "a": "",
                "r": 0
            },
            {
                "id": "n15",
                "w": "57.50",
                "h": "83.75",
                "x": "398.18",
                "y": "580.23",
                "s": {
                    "type": "Flow",
                    "shape": "Process"
                },
                "a": "Auth Servers",
                "r": 0
            },
            {
                "id": "n16",
                "w": "50.00",
                "h": "40.00",
                "x": "617.32",
                "y": "606.01",
                "s": {
                    "type": "Flow",
                    "shape": "MultiDocument"
                },
                "a": "Cache",
                "r": 0
            },
            {
                "id": "n17",
                "w": "57.50",
                "h": "83.75",
                "x": "927.77",
                "y": "100.40",
                "s": {
                    "type": "Flow",
                    "shape": "Process"
                },
                "a": "",
                "r": 0
            },
            {
                "id": "n18",
                "w": "57.50",
                "h": "83.75",
                "x": "914.02",
                "y": "89.15",
                "s": {
                    "type": "Flow",
                    "shape": "Process"
                },
                "a": "",
                "r": 0
            },
            {
                "id": "n19",
                "w": "57.50",
                "h": "83.75",
                "x": "897.77",
                "y": "75.40",
                "s": {
                    "type": "Flow",
                    "shape": "Process"
                },
                "a": "Workers",
                "r": 0
            },
            {
                "id": "n20",
                "w": "40.94",
                "h": "74.38",
                "x": "854.50",
                "y": "683.37",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n21",
                "w": "40.94",
                "h": "74.37",
                "x": "854.54",
                "y": "650.36",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n22",
                "w": "40.94",
                "h": "74.37",
                "x": "854.55",
                "y": "617.36",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n23",
                "w": "50.00",
                "h": "50.00",
                "x": "930.13",
                "y": "435.17",
                "s": {
                    "type": "Text",
                    "content": "Replication",
                    "margin": {
                        "right": 0,
                        "bottom": 0,
                        "left": 0,
                        "top": 0
                    }
                },
                "a": "",
                "r": 0
            },
            {
                "id": "n24",
                "w": "50.00",
                "h": "50.00",
                "x": "126.14",
                "y": "135.61",
                "s": {
                    "type": "Text",
                    "content": "Static Resources",
                    "margin": {
                        "right": 0,
                        "bottom": 0,
                        "left": 0,
                        "top": 0
                    }
                },
                "a": "",
                "r": 340.3686422609113
            },
            {
                "id": "n25",
                "w": "64.44",
                "h": "68.37",
                "x": "147.93",
                "y": "564.65",
                "s": {
                    "type": "Text",
                    "content": "Static Resources",
                    "margin": {
                        "right": 0,
                        "bottom": 0,
                        "left": 0,
                        "top": 0
                    }
                },
                "a": "",
                "r": 24.386072528817067
            },
            {
                "id": "n26",
                "w": "103.21",
                "h": "50.00",
                "x": "586.09",
                "y": "169.98",
                "s": {
                    "type": "Text",
                    "content": "Publish Events To Topic",
                    "margin": {
                        "right": 0,
                        "bottom": 0,
                        "left": 0,
                        "top": 0
                    }
                },
                "a": "",
                "r": 270.94819504578004
            },
            {
                "id": "n27",
                "w": "80.00",
                "h": "40.00",
                "x": "920.32",
                "y": "204.27",
                "s": {
                    "type": "Flow",
                    "shape": "Process"
                },
                "a": "Log Aggregation",
                "r": 0
            },
            {
                "id": "n28",
                "w": "80.00",
                "h": "40.00",
                "x": "1019.57",
                "y": "204.27",
                "s": {
                    "type": "Flow",
                    "shape": "Process"
                },
                "a": "Auditing",
                "r": 0
            },
            {
                "id": "n29",
                "w": "80.00",
                "h": "40.00",
                "x": "820.82",
                "y": "204.02",
                "s": {
                    "type": "Flow",
                    "shape": "Process"
                },
                "a": "Monitoring",
                "r": 0
            },
            {
                "id": "n30",
                "w": "40.94",
                "h": "74.38",
                "x": "1015.25",
                "y": "683.37",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n31",
                "w": "40.94",
                "h": "74.37",
                "x": "1015.29",
                "y": "650.36",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n32",
                "w": "40.94",
                "h": "74.37",
                "x": "1015.30",
                "y": "617.36",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n33",
                "w": "40.94",
                "h": "74.38",
                "x": "855.75",
                "y": "544.87",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n34",
                "w": "40.94",
                "h": "74.37",
                "x": "855.79",
                "y": "511.86",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n35",
                "w": "40.94",
                "h": "74.37",
                "x": "855.80",
                "y": "478.86",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n36",
                "w": "40.94",
                "h": "74.38",
                "x": "1016.25",
                "y": "547.12",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n37",
                "w": "40.94",
                "h": "74.37",
                "x": "1016.29",
                "y": "514.11",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n38",
                "w": "40.94",
                "h": "74.37",
                "x": "1016.30",
                "y": "481.11",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n39",
                "w": "40.94",
                "h": "74.38",
                "x": "1015.75",
                "y": "400.87",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n40",
                "w": "40.94",
                "h": "74.37",
                "x": "1015.79",
                "y": "367.86",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n41",
                "w": "40.94",
                "h": "74.37",
                "x": "1015.80",
                "y": "334.86",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n42",
                "w": "40.94",
                "h": "74.38",
                "x": "855.25",
                "y": "402.12",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n43",
                "w": "40.94",
                "h": "74.37",
                "x": "855.29",
                "y": "369.11",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n44",
                "w": "40.94",
                "h": "74.37",
                "x": "855.30",
                "y": "336.11",
                "s": {
                    "type": "Flow",
                    "shape": "DirectData"
                },
                "a": "",
                "r": 270.62733250933195
            },
            {
                "id": "n45",
                "w": "70.00",
                "h": "67.44",
                "x": "1015.67",
                "y": "366.07",
                "s": {
                    "type": "Text",
                    "content": "DB\nMaster\nShard N-Z",
                    "margin": {
                        "right": 0,
                        "bottom": 0,
                        "left": 0,
                        "top": 0
                    }
                },
                "a": "",
                "r": 358.04128803944946
            },
            {
                "id": "n46",
                "w": "70.00",
                "h": "67.44",
                "x": "854.42",
                "y": "366.07",
                "s": {
                    "type": "Text",
                    "content": "DB\nMaster\nShard A-M",
                    "margin": {
                        "right": 0,
                        "bottom": 0,
                        "left": 0,
                        "top": 0
                    }
                },
                "a": "",
                "r": 358.04128803944946
            },
            {
                "id": "n47",
                "w": "70.00",
                "h": "67.44",
                "x": "1018.17",
                "y": "514.57",
                "s": {
                    "type": "Text",
                    "content": "DB\nSlave\nShard N-Z",
                    "margin": {
                        "right": 0,
                        "bottom": 0,
                        "left": 0,
                        "top": 0
                    }
                },
                "a": "",
                "r": 358.04128803944946
            },
            {
                "id": "n48",
                "w": "70.00",
                "h": "67.44",
                "x": "1020.67",
                "y": "654.57",
                "s": {
                    "type": "Text",
                    "content": "DB\nSlave\nShard N-Z",
                    "margin": {
                        "right": 0,
                        "bottom": 0,
                        "left": 0,
                        "top": 0
                    }
                },
                "a": "",
                "r": 358.04128803944946
            },
            {
                "id": "n49",
                "w": "65.01",
                "h": "67.44",
                "x": "853.18",
                "y": "648.40",
                "s": {
                    "type": "Text",
                    "content": "DB\nSlave\nShard A-M",
                    "margin": {
                        "right": 0,
                        "bottom": 0,
                        "left": 0,
                        "top": 0
                    }
                },
                "a": "",
                "r": 358.04128803944946
            },
            {
                "id": "n50",
                "w": "65.01",
                "h": "67.44",
                "x": "858.18",
                "y": "504.65",
                "s": {
                    "type": "Text",
                    "content": "DB\nSlave\nShard A-M",
                    "margin": {
                        "right": 0,
                        "bottom": 0,
                        "left": 0,
                        "top": 0
                    }
                },
                "a": "",
                "r": 358.04128803944946
            },
            {
                "id": "n51",
                "w": "38.79",
                "h": "36.90",
                "x": "540.36",
                "y": "359.49",
                "s": {
                    "type": "Flow",
                    "shape": "Process"
                },
                "a": "REST\nAPI",
                "r": 0
            },
            {
                "id": "n52",
                "w": "69.44",
                "h": "67.28",
                "x": "400.00",
                "y": "358.25",
                "s": {
                    "type": "Flow",
                    "shape": "SequentialData"
                },
                "a": "",
                "r": 0
            },
            {
                "id": "n53",
                "w": "140.48",
                "h": "40.00",
                "x": "399.74",
                "y": "76.71",
                "s": {
                    "type": "Flow",
                    "shape": "Terminator"
                },
                "a": "Authentication",
                "r": 0
            },
            {
                "id": "n54",
                "w": "69.44",
                "h": "67.28",
                "x": "391.92",
                "y": "358.45",
                "s": {
                    "type": "Flow",
                    "shape": "SequentialData"
                },
                "a": "API Gateway",
                "r": 0
            }
        ],
        "connectors": [
            {
                "id": "c1",
                "s": "n1",
                "t": "n8",
                "sp": {
                    "x": 68.24,
                    "y": 157.01
                },
                "tp": {
                    "x": 171.53,
                    "y": 121.17
                },
                "type": "Straight",
                "si": "port3",
                "ti": "",
                "td": "Arrow"
            },
            {
                "id": "c2",
                "s": "n2",
                "t": "n8",
                "sp": {
                    "x": 87.74,
                    "y": 359.26
                },
                "tp": {
                    "x": 210.9,
                    "y": 127.51
                },
                "type": "Straight",
                "si": "port2",
                "ti": "port2",
                "td": "Arrow"
            },
            {
                "id": "c3",
                "s": "n3",
                "t": "n9",
                "sp": {
                    "x": 87.74,
                    "y": 534.01
                },
                "tp": {
                    "x": 200,
                    "y": 589.39
                },
                "type": "Straight",
                "si": "port2",
                "ti": "port1",
                "td": "Arrow"
            },
            {
                "id": "c4",
                "s": "n2",
                "t": "n4",
                "sp": {
                    "x": 87.74,
                    "y": 359.26
                },
                "tp": {
                    "x": 201.52,
                    "y": 358.89
                },
                "type": "Straight",
                "si": "port2",
                "ti": "port1",
                "td": "Arrow"
            },
            {
                "id": "c5",
                "s": "n52",
                "t": "n51",
                "sp": {
                    "x": 429.63,
                    "y": 358.55
                },
                "tp": {
                    "x": 520.97,
                    "y": 359.49
                },
                "type": "Straight",
                "si": "",
                "ti": "port1",
                "td": "Arrow"
            },
            {
                "id": "c6",
                "s": "n52",
                "t": "n15",
                "sp": {
                    "x": 400,
                    "y": 391.89
                },
                "tp": {
                    "x": 398.58,
                    "y": 538.35
                },
                "type": "Straight",
                "si": "port2",
                "ti": "",
                "td": "Arrow"
            },
            {
                "id": "c7",
                "t": "n43",
                "sp": {
                    "x": 644.77,
                    "y": 383.18
                },
                "tp": {
                    "x": 818.11,
                    "y": 368.7
                },
                "type": "Straight",
                "si": "",
                "ti": "port4",
                "td": "Arrow",
                "a": "Writes\n"
            },
            {
                "id": "c8",
                "s": "n5",
                "t": "n16",
                "sp": {
                    "x": 616.27,
                    "y": 427.03
                },
                "tp": {
                    "x": 617.2,
                    "y": 586.01
                },
                "type": "Straight",
                "si": "",
                "ti": "",
                "td": "Arrow"
            },
            {
                "id": "c9",
                "s": "n13",
                "t": "n16",
                "sp": {
                    "x": 456.93,
                    "y": 605.23
                },
                "tp": {
                    "x": 592.32,
                    "y": 605.89
                },
                "type": "Straight",
                "si": "port3",
                "ti": "",
                "td": "Arrow"
            },
            {
                "id": "c10",
                "s": "n7",
                "t": "n10",
                "sp": {
                    "x": 586.02,
                    "y": 318.27
                },
                "tp": {
                    "x": 611.24,
                    "y": 74.93
                },
                "type": "Orthogonal",
                "si": "",
                "ti": "port1",
                "td": "Arrow"
            },
            {
                "id": "c11",
                "s": "n12",
                "t": "n19",
                "sp": {
                    "x": 741.24,
                    "y": 74.43
                },
                "tp": {
                    "x": 869.02,
                    "y": 75.4
                },
                "type": "Straight",
                "si": "port3",
                "ti": "port1",
                "td": "Arrow",
                "a": "Subscribe Topic for Events"
            },
            {
                "id": "c12",
                "s": "n5",
                "sp": {
                    "x": 644.77,
                    "y": 395.18
                },
                "tp": {
                    "x": 957.22,
                    "y": 504.15
                },
                "type": "Straight",
                "si": "",
                "ti": "",
                "td": "Arrow",
                "a": "Reads\n\n"
            },
            {
                "id": "c13",
                "s": "n5",
                "sp": {
                    "x": 644.77,
                    "y": 413.85
                },
                "tp": {
                    "x": 797.63,
                    "y": 566.42
                },
                "type": "Straight",
                "si": "",
                "ti": "",
                "td": "Arrow",
                "a": "Reads\n\n"
            },
            {
                "id": "c14",
                "s": "n3",
                "t": "n4",
                "sp": {
                    "x": 87.74,
                    "y": 515.02
                },
                "tp": {
                    "x": 225.87,
                    "y": 383.84
                },
                "type": "Straight",
                "si": "",
                "ti": "",
                "td": "Arrow"
            },
            {
                "id": "c15",
                "s": "n1",
                "t": "n4",
                "sp": {
                    "x": 88.24,
                    "y": 213.5
                },
                "tp": {
                    "x": 225,
                    "y": 334.82
                },
                "type": "Straight",
                "si": "port2",
                "ti": "",
                "td": "Arrow"
            },
            {
                "id": "c16",
                "s": "n7",
                "t": "n29",
                "sp": {
                    "x": 614.77,
                    "y": 343.49
                },
                "tp": {
                    "x": 820.82,
                    "y": 224.02
                },
                "type": "Straight",
                "si": "",
                "ti": "port2",
                "td": "Arrow"
            },
            {
                "id": "c17",
                "s": "n6",
                "t": "n27",
                "sp": {
                    "x": 631.02,
                    "y": 360.37
                },
                "tp": {
                    "x": 920.32,
                    "y": 224.27
                },
                "type": "Straight",
                "si": "",
                "ti": "port2",
                "td": "Arrow"
            },
            {
                "id": "c18",
                "s": "n6",
                "t": "n28",
                "sp": {
                    "x": 631.02,
                    "y": 363.59
                },
                "tp": {
                    "x": 1019.57,
                    "y": 224.27
                },
                "type": "Straight",
                "si": "",
                "ti": "port2",
                "td": "Arrow"
            },
            {
                "id": "c19",
                "s": "n38",
                "t": "n30",
                "sp": {
                    "x": 979.12,
                    "y": 480.7
                },
                "tp": {
                    "x": 978.06,
                    "y": 682.96
                },
                "type": "Orthogonal",
                "si": "port4",
                "ti": "port4",
                "td": "None"
            },
            {
                "id": "c20",
                "s": "n35",
                "t": "n20",
                "sp": {
                    "x": 818.62,
                    "y": 478.45
                },
                "tp": {
                    "x": 817.31,
                    "y": 682.96
                },
                "type": "Orthogonal",
                "si": "port4",
                "ti": "port4",
                "td": "None"
            },
            {
                "id": "c21",
                "t": "n41",
                "sp": {
                    "x": 614.77,
                    "y": 358.27
                },
                "tp": {
                    "x": 978.62,
                    "y": 334.45
                },
                "type": "Straight",
                "si": "",
                "ti": "port4",
                "td": "Arrow"
            },
            {
                "id": "c22",
                "s": "n1",
                "t": "n53",
                "sp": {
                    "x": 68.24,
                    "y": 157.01
                },
                "tp": {
                    "x": 329.5,
                    "y": 76.71
                },
                "type": "Orthogonal",
                "si": "",
                "ti": "port1",
                "td": "Arrow"
            },
            {
                "id": "c23",
                "s": "n52",
                "t": "n53",
                "sp": {
                    "x": 399.97,
                    "y": 325.09
                },
                "tp": {
                    "x": 399.74,
                    "y": 96.71
                },
                "type": "Straight",
                "si": "",
                "ti": "port2",
                "td": "Arrow"
            },
            {
                "id": "c24",
                "s": "n4",
                "t": "n54",
                "sp": {
                    "x": 302.77,
                    "y": 358.89
                },
                "tp": {
                    "x": 357.2,
                    "y": 358.45
                },
                "type": "Straight",
                "si": "port3",
                "ti": "port1",
                "td": "Arrow"
            }
        ]
    }
}

export const sectionDetails = [`This system provides users with a one-stop shop for all their job search needs, including resources for preparing for interviews, creating resumes, and applying for jobs. It also provides a platform for recruiters and interviewers to post jobs, review resumes, and conduct interviews with potential candidates, and receive personalized guidance from experts. It is designed to help job seekers quickly find the perfect job opportunity and make the most of any interview.
`,
`A Smart Hiring System uses algorithms to assess job applicants and predict their potential for success in the role. It looks at factors such as previous experience, qualifications, skills, and performance in interviews to make an informed decision on who to hire.
It is designed to save time and money by streamlining the recruitment process, giving employers access to a larger pool of potential applicants, and providing customizable reporting tools to help employers make data-driven decisions. 
`,
`Competitive Learning System is based on the idea that one should first learn all the essential knowledge and skills before attempting to take on a difficult task. Once one has a good grasp of the material, one should then take the time to prepare for the challenge by practicing and refining their skills. Finally, when one is ready, they should take the challenge and test their abilities.
`,
`To Take Candidate Interviews online with Joblient is incredibly easy. The platform allows companies to create interviews with questions from a library of pre-determined questions or create their own. Companies can then share the interview with candidates, who can take the interview from any device with an internet connection. The interviews can be recorded and scored so the company can easily compare candidates and make a hiring decision.
`,
`This course is designed to help you develop a strong foundation in design principles 
and practices, allowing you to create designs with clarity and purpose. You will learn 
to analyze and solve design problems, create effective system and workflows, and be able 
to communicate your design decisions to stakeholders.  
`
]

export const whyJoblient = [
  `Our platform offers employers a more efficient way to assess candidates. It eliminates the need to manually read through resumes and conduct many interviews to gauge a candidate's capabilities. Instead, employers can use our platform to assess candidates by simulating the tasks associated with the job role and looking at the performance of the candidate in those tasks. This allows employers to make decisions based on a candidate's actual skills rather than relying on traditional methods which may be more subjective.`,
  `Joblient's model of ranking candidates takes into account not only how frequently they update their profiles, but also how actively they have participated in assessments and learning activities. This is beneficial for recruiters as it allows them to easily pick out the most qualified candidates for further interviewing.`,
  `Joblient's assessment and learning based model helps recruiters to identify the most qualified candidates by considering factors such as the candidate's engagement level on the platform, their performance on assessments, and their willingness to participate in learning activities. Thus, recruiters can easily identify higher-ranking candidates and conduct more tailored interviews.`,
  `The main reason we started Joblient is that traditional pre-employment assessment platforms or manual are not a fair way for companies to evaluate candidates. At Joblient, our mission is to help companies find great candidates by assessing on-the-job skills required for a role.`
]

export const about = [
    `
    The most reliable platform for recruiter to get technically pre-assessed, immediate joiners, within budget.
    `,
    `By upskilling, a candidate becomes eligible to be included on pre-assessed category and increases their chances of being selected for the role.`
]