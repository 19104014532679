import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

const AddCandidate = () => {
  const [candidateDetails, setCandidateDetails] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    switch (name) {
      case 'name':
        if (!value.trim()) return 'Name is required';
        break;
      case 'email':
        if (!value.trim()) {
          return 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          return 'Email is invalid';
        }
        break;
      case 'phone':
        if (!value.trim()) {
          return 'Phone number is required';
        } else if (!/^\d{10}$/.test(value)) {
          return 'Phone number is invalid';
        }
        break;
      default:
        return '';
    }
    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCandidateDetails({ ...candidateDetails, [name]: value });
    setErrors({ ...errors, [name]: validateField(name, value) });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formIsValid = true;
    const newErrors = {};

    Object.keys(candidateDetails).forEach((key) => {
      const error = validateField(key, candidateDetails[key]);
      if (error) {
        formIsValid = false;
        newErrors[key] = error;
      }
    });

    setErrors(newErrors);

    if (formIsValid) {
      // Form is valid, you can proceed with submission
      console.log('Form submitted:', candidateDetails);
      // Reset form if necessary
      setCandidateDetails({
        name: '',
        email: '',
        phone: '',
      })
    }
  };

  return (
    <Box>
      <Card sx={{ minWidth: 275, maxWidth: 500 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Fill in candidate details
          </Typography>
          <form onSubmit={handleSubmit} noValidate>
            <TextField
              id="name"
              name="name"
              label="Name"
              type="text"
              fullWidth
              margin="normal"
              variant="outlined"
              value={candidateDetails.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              id="email"
              name="email"
              label="Email ID"
              type="email"
              fullWidth
              margin="normal"
              variant="outlined"
              value={candidateDetails.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              id="phone"
              name="phone"
              label="Phone Number"
              type="tel"
              fullWidth
              margin="normal"
              variant="outlined"
              value={candidateDetails.phone}
              onChange={handleChange}
              error={!!errors.phone}
              helperText={errors.phone}
            />
            <Button type="submit" variant="contained" sx={{ marginTop: 2 }}>
              Submit
            </Button>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AddCandidate;
