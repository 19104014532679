import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Grid,
  Paper,
  TextField,
  Modal,
  FormControlLabel,
  MenuItem,
} from "@material-ui/core";
import { Radio } from "@mui/material";
import AddIcon from '@mui/icons-material/AddBox';
import ClearAll from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/CloseTwoTone';
import { SetPopupContext } from "../home/component/MainRouter";
import {API, fetchData, saveData} from "../../config/apiService"
import {manageInterview} from "../../state/action-creators/workspace"
import { showErrorToast } from "../../utils/toastMsg";

const ScheduleInterview = (props)=>{
    const dispatch = useDispatch();
    const setPopup = useContext(SetPopupContext);

    const [templateData, setTemplateData] = useState();
    const workspace = useSelector(state=>state.workspace);
    const [interview, setInterview] = useState({mode:'interview', email:'', type: 1,  item:''})
    const [ques, setQues] = useState([]);

    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = async()=>{
        let tempData = await fetchData(API.template, "Template", dispatch, manageInterview, setPopup);
        setTemplateData(tempData);
        //console.log('refreshData invoked:', sessions)
    }

    const handleAdd = ()=>{
       
        let arr = ques.filter( i=>i.item.title == interview.item.title);
        //console.log('interview', interview, arr)
       if (interview.item != '' && arr.length == 0 && ques.length<3) {
            let temp = [...ques];
            temp.push({...interview});
            setQues(temp);
        } else if (ques.length==3) {
            setPopup({
                open: true,
                severity: "fail",
                message: "Maximum 3 assignments are allowed",
                });
        }
    }
    
    const validations = (data)=>{
        if(data.email=="") {
            setPopup({
            open: true,
            severity: "fail",
            message: "Email validation failed",
            });
            return false;
        }
        return true;
    }
    const prepareData = ()=>{

        const link ='aAhgtkb'; //getSessionLink(); 
        let items = ques.map((_, i)=>ques[i].item);
        let data = {
            createdBy: 'Recruiter',
            email:interview.email,
            assignment:ques[0].item.title,
            //workspace:'ms',
            sessionLink: link,
            //actions: {text:'Feedback', feedback: ''},
            mode:ques[0].mode,
            assessments: items,
           // feedback: {label:'Feedback', status:'', value:'' }
        }
        return data;
    }
    const saveLink = ()=>{
        let data = prepareData();
        let verify = validations(interview);
        if(verify) {
            let success = saveData(API.interview, data, "Interview", setPopup );
            if(success) {
                props.refreshData();
                //props.setPopup(false);
            }
        }
    }

    return <Modal open={props.open}>
      <Paper
        className='w-full md:w-1/2 md:ml-96'
        style={{
            padding: "20px",
            outline: "none",
            minWidth: "50%",
           // width:'90%',
           // marginLeft:'5%',
            marginTop:'5%',
            //maxHeight:'70%',
            height:'auto; overflow'
       }}
      >
      <Grid container direction="column" spacing = {2}>
            <Grid item container direction="row" alignItems="flex-start" justifyContent="flex-end">
                <Grid item ><CloseIcon onClick={()=>{props.setOpen(false); props.refreshData()}}/></Grid>
            </Grid>
            <Grid item>
                <h2>Create an Interview</h2>
                { templateData && templateData.length === 0 && <h5 style = {{color:'red'}}>No Test Template Available, Create First</h5>}

            </Grid>
            <Grid item>
                <hr></hr>
            </Grid>
            <Grid item container direction = "row" spacing = {2} alignItems = "center">
                <Grid item xs = {3}>
                    Select Option
                </Grid>
                <Grid item xs = {2} >
                <FormControlLabel 
                    control={
                    <Radio 
                        name="code"
                        checked={interview.type == 1}
                        onChange={(event) => {
                            let temp = {...interview};
                            temp.type = 1;
                            setInterview(temp)
                        }}
                    />
                    }
                    label="&nbsp;Code"
                />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        control={
                        <Radio
                            name="design"
                            checked={interview.type == 2}
                            onChange={(event) => {
                                let temp = {...interview};
                                temp.type = 2;
                                setInterview(temp)
                            }}
                        />
                        }
                        label="&nbsp;Design"
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel disabled={true}
                        control={
                        <Radio
                            name="set"
                            checked={interview.type==3}
                            onChange={(event) => {
                                setInterview({...interview}.type=3)
                            }}
                        />
                        }
                        label="Test Set"
                    />
                </Grid>
            </Grid>
            <Grid container item direction="row" spacing={2} alignItems="center">
                <Grid item xs={3}>
                    Candidate
                </Grid>
                <Grid item xs={6}>
                    <TextField className='ml-28 w-full'
                        label="Candidate Email"
                        variant="outlined"
                        value = {interview.email}
                        onChange={(event) => {
                            let temp = {...interview};
                            temp.email = event.target.value;
                            setInterview(temp)
                        }}
                        fullwidth >
                    </TextField>
                </Grid>
            </Grid>
            <Grid container item direction="row" spacing={2} alignItems="center">
                <Grid item xs={3}>
                    Assessment
                </Grid>
                <Grid item xs={6}>
                    <TextField className='w-full'
                        select
                        label="Select Assessment"
                        variant="outlined"
                        onChange={(event) => {
                            let temp = {...interview};
                            temp.item = event.target.value;
                            setInterview(temp)
                        }}
                        fullwidth = "true" >
                        { interview.type === 1 ? templateData && templateData.filter(item=>item.category=='code').map((item, key)=><MenuItem id={key} value={item}>{item.title}</MenuItem>)
                          :
                          templateData && templateData.filter(item=>item.category=='design').map((item,key)=><MenuItem id={key} value={item}>{item.title}</MenuItem>)
                        }
                    </TextField>
                </Grid>
                <Grid item xs={2}>
                    <Button style={{marginTop:'-0.5em', marginLeft:'0em', marginBottom:'0em'}}
                        color="primary"
                        onClick={ ()=>{ handleAdd(interview) }}>
                        <AddIcon/>
                        <ClearAll style={{marginLeft:'0.7em'}} onClick={()=>{ setQues([]) }}/>
                    </Button>

                </Grid>
            </Grid>
            <Grid item>
                <div style={{marginTop:'1em',  marginBottom:'1.1rem', display:'block'}}>
                    <h4><strong>Assessments: (Max: 3)</strong></h4>
                
                    <ul style={{marginTop:'1em',marginLeft:'1.1em', marginBottom:'0rem'}}>
                    { 
                        ques.map( (item, key) => {
                            return <li id={key}>{item.type==1? 'Code':'Design'}:&nbsp;{item.item.title}</li>
                            }
                        )
                    }
                    </ul>
                </div>
            </Grid>
            <Grid item>
                <Button
                    disabled = {ques.length == 0}
                    variant="contained"
                    style={{background:'#239ce2',color:'white', padding: "10px 50px", marginLeft:'0px' }}
                    onClick={() =>{ saveLink(); props.setOpen(false); props.refreshData()}}
                >
                    Create Link
                </Button>
            </Grid>
            
      </Grid>
    </Paper>
  </Modal>
}

export default ScheduleInterview;