export const resumeBulk = [
  {
    "name": "AJIT HEGDE",
    "designation": "Manager",
    "mobile": "919731558496",
    "email": "ajitrhegde400@gmail.com",
    "coreSkills": [
      "AWS",
      "Java",
      "JavaScript",
      "Postgres",
      "Microservices",
      "Spring Boot",
      "Kafka",
      "Redis",
      "Mongo",
      "Spark",
      "Devops",
      "jQuery",
      "HTML"
    ],
    "techSkills": [
      "Agile",
      "SDLC",
      "Design",
      "Docker",
      "Redis",
      "JUnit",
      "Cloud Watch",
      "EC2",
      "S3",
      "Kibana",
      "Rest Api"
    ],
    "experience": 11,
    "education": "Bachelors  in  Electronics  and  Communication  Engineering  from  Visvesvaraya Technological "
  },
  {
    "name": "AMIT KUMAR ",
    "designation": "CTO at HireHunch",
    "mobile": "919740526537",
    "email": "amitbgp@gmail.com",
    "coreSkills": [
      "J2EE",
      "Microservices",
      "Spring Boot",
      "Typescript",
      "MySQL",
      "JavaScript",
      "Java",
      "Redis",
      "Mongo",
      "Kafka",
      "AWS",
      "NodeJS,",
      "Node",
      "ReactJS,",
      "GCP"
    ],
    "techSkills": [
      "Design",
      "Agile",
      "Redis",
      "ElasticSearch",
      "Grafana",
      "JPA",
      "Rest Api",
      "Docker"
    ],
    "experience": 17,
    "education": "B.M.S College Of Engineering"
  },
  {
    "name": "Sunil Kumar Shah",
    "designation": "Senior Principal",
    "mobile": "919899527044",
    "email": "sshah2k9@gmail.com",
    "coreSkills": [
      "AWS",
      "Java",
      "Microservices",
      "Kafka",
      "Postgres"
    ],
    "techSkills": [
      "Design"
    ],
    "experience": 17,
    "education": "B.E.(CSE)"
  },
  {
    "name": "Deepak Kumar",
    "designation": "Software Engineer",
    "mobile": "919306926942",
    "email": "kumarr.deepak2016@gmail.com",
    "coreSkills": [
      "Angular",
      "AWS",
      "Java",
      "Redis",
      "Postgres",
      "MySQL",
      "Mongo",
      "Spring Boot",
      "JavaScript",
      "J2EE",
      "C++",
      "Python",
      "HTML"
    ],
    "techSkills": [
      "Redis",
      "EC2",
      "JPA",
      "Design",
      "Rest Api",
      "Multithreading"
    ],
    "experience": 5,
    "education": "MCA   "
  },
  {
    "name": "MANISH KUMAR",
    "designation": "Lead Software Engineer",
    "mobile": "9164948291",
    "email": "mkj.online@gmail.com",
    "coreSkills": [
      "Microservices",
      "Spring Boot",
      "Java",
      "AWS",
      "jQuery",
      "JavaScript",
      "HTML",
      "CSS",
      "MySQL",
      "Mongo",
      "Kafka"
    ],
    "techSkills": [
      "Design",
      "EC2",
      "S3",
      "ElasticSearch",
      "Agile",
      "Rest Api",
      "Multithreading"
    ],
    "experience": 12,
    "education": "Completed B.Tech. (Computer Engineering as major) from Sikkim Manipal"
  },
  {
    "name": "Nishant Singh",
    "designation": "Principal Engineer",
    "mobile": "919591447568",
    "email": "Nishantcs3@gmail.com",
    "coreSkills": [
      "Java",
      "J2EE",
      "Spring Boot",
      "AWS",
      "Microservices",
      "Azure",
      "Mongo",
      "Kafka",
      "Python",
      "MySQL",
      "jQuery",
      "HTML",
      "CSS"
    ],
    "techSkills": [
      "Design",
      "JPA",
      "Rest Api",
      "JUnit",
      "VPC",
      "ElasticSearch"
    ],
    "experience": 11,
    "education": "B.Tech: C.S.E "
  },
  {
    "name": "Ved Prakash",
    "designation": "Team Lead",
    "mobile": "919123248734",
    "email": "ved.cse@gmail.com",
    "coreSkills": [
      "Algorithms",
      "ALGO",
      "Kafka",
      "Java",
      "Angular",
      "Angular2",
      "Mongo",
      "JavaScript",
      "Python",
      "AWS",
      "GCP",
      "MySQL",
      "jQuery",
      "Node",
      "React JS",
      "Node JS",
      "CSS",
      "django",
      "matlot",
      "ML/DL",
      "Microservices",
      "Spring Boot"
    ],
    "techSkills": [
      "Design",
      "Agile",
      "Design Patterns",
      "JPA",
      "Elastic-Search",
      "Docker",
      "ELASTIC Search"
    ],
    "experience": 6,
    "education": "PG Diploma Master's Data "
  }
]