import { Grid, Paper } from "@material-ui/core"

import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import './QueTemplate.scss';


import { getStyle } from "@syncfusion/ej2-diagrams/src/diagram/utility/uml-util";
import TopicSelector from "./TopicSelector";
import { qaSets } from "../../data/interviews/QASets";
//import Qset from "../../data/interviews/Microservices Interview Set 1"
const COLORS = ['#757575','#34aadc','#bb86fc', 'rgb(190 163 163)', '#f1c40f', '#5856d6','#e75f79','#4cd964']

const QASetTemplate = (props)=>{
    const [index, setIndex] = useState(0);
    const [show, setShow] = useState(false);
    const topic = 'ms';
    const [qa, setQa] = useState();

    const setColor = (key)=> {return {color: `${COLORS[key % COLORS.length]}`}}
    const setBk = (key)=> {return {background: `${COLORS[key % COLORS.length]}`}}
    const setBrd = (key)=> {return {border: `2px solid ${COLORS[key % COLORS.length]}`}}

    const selectNext = (page)=>{
        setIndex(page);
    }

    useEffect(()=>{
        let temp = qaSets(topic);
        setQa(temp);
    }, [])

    const templateSelector = ()=>{

    }

    return  qa&&<Grid container direction="row" justifyContent="center" style={{marginBottom: '50px', marginTop:'20px'}}  onMouseOver={()=>setShow(true)} onMouseLeave={()=>setShow(false)}>
            {/* <Grid item xs={1} container alignItems="center" justifyContent="flex-end">
                {show&&
                <Grid className="navPageL" style={{ marginLeft:'2em'}} onClick={()=> {selectNext( index <= 1 ? 0 : (index - 1))}}>
                        <svg viewBox="0 0 24 24" width="48px" height="48px" x="0" y="0" preserveAspectRatio="xMinYMin meet" class="artdeco-icon" focusable="false">
                        <path d="M10,12l6,8.94L14.45,22,8.26,12.85a1.5,1.5,0,0,1,0-1.69L14.45,2,16,3.06Z" class="large-icon" style={{fill: 'currentColor'}}></path></svg>
                </Grid>}
            </Grid>
            <Grid item xs={8}>
                <div className="heading">
                    <p className="note">
                        {qa[index].title}
                    </p>
                </div>
            </Grid>
            <Grid item xs={1} container alignItems="flex-start">
                {show&&
                <div className="navPageR" onClick={()=> {selectNext( index < qa.length - 1 ? (index + 1) : 0)}}>
                    <svg viewBox="0 0 24 24" width="48px" height="48px" x="0" y="0" preserveAspectRatio="xMinYMin meet" className="artdeco-icon" focusable="false">
                    <path d="M14,12L8,3.06,9.55,2l6.19,9.15a1.5,1.5,0,0,1,0,1.69L9.55,22,8,20.94Z" className="large-icon" style={{fill: 'currentColor'}}></path></svg>
                </div>}
            </Grid> */}
            <Grid item container  direction="row" justifyContent="center" style={{display:'flex', flexWrap:'nowrap' }} onMouseOver={()=>setShow(true)} onMouseLeave={()=>setShow(false)}>
                    <Grid item xs={1} container alignItems="center" justifyContent="flex-end" style={{marginTop:'-3em'}}>
                        {show?
                        <div  className="navL" onClick={()=> {selectNext( index == 0 ? (qa.length - 1) : (index - 1))}}>
                                <svg viewBox="0 0 24 24" width="48px" height="48px" x="0" y="0" preserveAspectRatio="xMinYMin meet" class="artdeco-icon" focusable="false">
                                <path d="M10,12l6,8.94L14.45,22,8.26,12.85a1.5,1.5,0,0,1,0-1.69L14.45,2,16,3.06Z" class="large-icon" style={{fill:'currentColor'}}></path></svg>
                        </div>
                        :<></>}
                    </Grid>
                    <Grid item justifyContent="center" alignContent="center">
                        <div className="heading">
                            <p className="note">
                                {qa[index].title}
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={1} container alignItems="center" style={{marginTop:'-3em'}}>
                        {show?
                        <div className="navR" onClick={()=> {selectNext( index < qa.length - 1 ? (index + 1) : 0)}}>
                            <svg viewBox="0 0 24 24" width="48px" height="48px" x="0" y="0" preserveAspectRatio="xMinYMin meet" class="artdeco-icon" focusable="false">
                            <path d="M14,12L8,3.06,9.55,2l6.19,9.15a1.5,1.5,0,0,1,0,1.69L9.55,22,8,20.94Z" class="large-icon" style={{fill: 'currentColor'}}></path></svg>
                        </div>:<></>}
                    </Grid>
            </Grid>
            <Grid item xs={10} >
                <Grid>
                    { qa[index].QA.map((ques, key)=>{
                        return <Grid container direction="row" >
                                    <Grid item xs={1}>
                                        <div className="num" style = {setBk(key)} >{ key < 9 ? `0${key+1}` : `${key+1}` }</div>
                                    </Grid>

                                    <Grid item xs={11} container direction="column">
                                        <Grid>
                                            <div className="context" style = {setBk(key)}>
                                                <p className="note" style = {setBrd(key)}>
                                                    {ques.key}
                                                </p>
                                            </div>
                                        </Grid>
                                        <Grid> 
                                            <p className="ans" style = {setBrd(key)}>
                                                {/* {ques.value} */}
                                                {ques.value.split('.').map((item, k)=><span style = {setColor(k)}>
                                                     {item}.
                                                </span>)}
                                            </p>
                                        </Grid>
                                    </Grid>
                             </Grid>
                        }
                    )}
                </Grid>
            </Grid>


    </Grid>
}

export default QASetTemplate;