import { Button, Checkbox, FormControlLabel, Grid, ListItem, MenuItem, Modal, Paper, TextField } from "@material-ui/core";
import { useContext, useEffect, useRef, useState } from "react";
import { ArrowDown } from "react-feather";
import ReactToPrint from "react-to-print";

import TextEditor from "../editor/editor-text/TextEditor";
import "./interviews.scss";
import { API, fetchDataWoToken, saveDataWoToken } from "../../config/apiService";
import { useLocation, } from "react-router-dom";
import { SetPopupContext } from "../home/component/MainRouter";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { oneDark } from "react-syntax-highlighter/dist/cjs/styles/prism"
import rehypeRaw from "rehype-raw"
import ThumbnailDiagram from "../blogs/design/ThumbnailDiagram";
import { a2b } from "../../utils/decodeUtil";
import Joblient from "../appbar/Jonlient";
import { renderMD } from "../../utils/skillRankUtils";


const AssessmentReport = (props)=>{
    const [session, setSession] = useState();
    const [code, setCode] = useState();
    const [design, setDesign] = useState();
    const [quiz, setQuiz] = useState();
    const location = useLocation();
    const userId = props.userId;
    
//     const location = useLocation();
//     //console.log('props:', location);
    let urlParams = location.pathname.split(':');

    useEffect(() => {
        getData();
    }, []);

    const getData = async()=>{

        let address = "";
        if (urlParams.length>0 && urlParams[0].indexOf('assess')>-1) {
            let urlParam =  urlParams[0] + "";
            let index = urlParam.lastIndexOf("/");
            let tiny = urlParam.substring(index+1, urlParam.length);

            console.log('tiny123', tiny);
            address =  `${API.assessReport}?tiny=${tiny}`;

        } else if (userId) {
            address = `${API.assessReport}?userId=${userId}`
        } else {
            let tiny = localStorage.getItem('tiny');   //'rawHyNcQkr';
            address = `${API.assessReport}?tiny=${tiny}`
        }


       // console.log('location:', document.location.host, 'tiny', localStorage.getItem('tiny'));
        //let urlParams = location.pathname.split(':');

        let res = await fetchDataWoToken(address, "Assessment Test Report");
        if (res) {
            //console.log('retrieveAssessments:', res);
            // let codeAssess = res.filter(v=> v.assessments[0].category==='code');
            // console.log('codeAssess:', codeAssess)
             setCode(res.codeAssess);
             setDesign(res.designAssess);
             setQuiz(res.quizAssess);
            // let des = res.filter(v=> v.assessments[0].category==='design');
            // setDesign(des)
            console.log('desAssess:', res);

            setSession(res);
            //setFeedback({rating: res.feedback.rating, comment: res.feedback.comment});
            //console.log('report:', res)
        }
    }

    let reportRef = useRef();

    const getCodeContent = ()=>
         <div>
           <h4>Code Assessments<hr></hr></h4>
           <br></br>
            {code&&code.map((item, key)=>
            <div key={key}  style={{padding:'5px', borderRadius:'10px', background:'#f2f0ff', marginBottom:'3em'}}>
                {/* {item.category ==='design' ? <div className="pagebreak"> </div> : <></>}
                    
                {item.category ==='design' ? <h4>Design Assessment<br/></h4> : <h4>Code Assessment<br/></h4>}
                <h5>Problem: <span>{item.title}</span></h5>
                <br></br> */}
             

                <h4>Problem: <span>{item.assessments[0].title}</span></h4>
                <br></br>
                <div style={{color:'blue', marginBottom:'0em'}}>
                    <span ><strong>Test Status:</strong> {item.assessments[0].status}&nbsp;&nbsp;</span>
                </div>
                <div style={{color:'gray', marginBottom:'2em'}}>
                    <span ><strong>Taken On:</strong> {new Date(item.createdAt).toLocaleDateString('en-GB', {
                                        day: 'numeric', month: 'short', year: 'numeric'})}&nbsp;&nbsp;</span>
                </div>
                <div style={{}}>
                    <TextEditor style={{}}  toolbar={false} content={item.assessments[0].desc} readOnly={true}></TextEditor>
                </div>
                
                <div style={{marginTop:'1em'}}>
                    <div style={{overflow:'hidden', 'maxHeight':'100%'}}>
                        <h4>Solution:</h4>
                        { !item.assessments[0].code ? 'Not Available' :
                        <ReactMarkdown
                            children={item.assessments[0].code}
                            rehypePlugins={[rehypeRaw]}
                            components={{
                                code({ node, inline, className, children, ...props }) {
                                    const match = /language-(\w+)/.exec(className || "")
                                    return !inline && match ? (
                                        <SyntaxHighlighter
                                            {...props}
                                            children={String(children).replace(/\n$/, "")}
                                            style={oneDark}
                                            language={match[1]}
                                            PreTag="div"
                                        />
                                    ) : (
                                        <code {...props} className={className}>
                                            {children}
                                        </code>
                                    )
                                },
                            }}
                        />}

                    </div>
                    {/* <div>
                       {item.draw&&<ThumbnailDiagram item = {item} zoom = {0.5} height = '600px' width = '100%'/>}
                    </div>  */}
                </div>
            </div>)}
    </div>

   const getDesignContent = ()=>
        <div>

        {design&&design.map((item, key)=>
        <div key={key} style={{padding:'5px', borderRadius:'10px', background:'#f2f0ff', marginBottom:'3em'}}>
            <div className="pagebreak"> </div>
            {
                key===0&&<>
                <h4>Design Assessments<hr></hr></h4>
                <br></br>
                </>
            }
            <h4>Problem: <span>{item.assessments[0].title}</span></h4>
            <br></br>
            <div style={{color:'blue', marginBottom:'0em'}}>
                <span ><strong>Test Status:</strong> {item.assessments[0].status}&nbsp;&nbsp;</span>
            </div>
            <div style={{color:'gray', marginBottom:'2em'}}>
                <span ><strong>Taken On:</strong> {new Date(item.createdAt).toLocaleDateString('en-GB', {
                                    day: 'numeric', month: 'short', year: 'numeric'})}&nbsp;&nbsp;</span>
            </div>
            <div style={{}}>
                <TextEditor style={{}}  toolbar={false} content={item.assessments[0].desc} readOnly={true}></TextEditor>
            </div>
            <div>
                {item.assessments[0].draw&&<ThumbnailDiagram item = {item.assessments[0]} zoom = {0.5} height = '600px' width = '100%'/>}
            </div>
     </div>)}
   </div>

    const renderQuizContent = ()=>
    <div>
    {/* <div className="pagebreak"> </div> */}
    {/* {quiz&&quiz.map((item, key)=> */}
    {quiz&&Object.entries(quiz).map(([key,value], i)=>
        <div key={key} style={{padding:'5px', borderRadius:'10px', background:'#f2f0ff', marginBottom:'3em'}}>

            {
                i===0&&<>
                <h4>Quiz Assessments<hr></hr></h4>
                <br></br>
                </>
            }
            <h4>Topic: <span>{key}</span></h4>
            <br></br>
            <div style={{color:'blue', marginBottom:'0em'}}>
                <span ><strong>Test Score:</strong> {value.score}&nbsp;&nbsp;</span>
            </div>
            <div style={{color:'gray', marginBottom:'2em'}}>
                <span ><strong>Taken On:</strong> {new Date(value.createdAt).toLocaleDateString('en-GB', {
                                    day: 'numeric', month: 'short', year: 'numeric'})}&nbsp;&nbsp;</span>
            </div>
            <div style={{marginBottom:'2em'}}>
                {Object.entries(value.questions).map(([item, val], j)=><div style={{display:'grid', background:'white', marginBottom:'0.6em'}}>
                    <span style={{fontWeight:'bold'}}>Question {j+1} : {renderMD(val?.question?.substring(4,val.question.length).replace(".",""))}</span>
                    <span style={{color : `${val.correct===true ? 'green':'red'}`}}>Answer: {val.correct? 'Correct': 'Wrong'}</span>
                  </div>
                )}
            </div>
            <div >
            </div>
        </div>)}
    </div>

    return session == null ? <div>Assessment report not available</div> : <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid container direction="row" style={{padding:'50px'}}>

                <Grid item xs={12} style={{marginBottom:'20px'}}>
              
                    <ReactToPrint
                        trigger={() => {
                            return (
                            <Button style={{background:'#DBEEEC', float:'right', margin:'auto', marginLeft:'1em', height:'2.6em', borderRadius:'20px'}} variant="contained" color="seconary" >
                                Print Report <ArrowDown />
                            </Button>
                            );
                        }}
                        content={() => reportRef.current}
                    />
                </Grid>
                <Grid item xs={12} ref={reportRef} style={{ padding:'20px', borderRadius:'10px', border:'1px solid lightgray'}}>

                        <Grid container direction="column" spacing={2} >

                        <Grid item xs={12} style={{marginBottom:'1em'}}>
                                <span style={{fontWeight:'600', fontSize:'1.8em'}}>Candidate Assessment Report</span>
                                <div style={{float:'right', marginTop:'-1em'}}><Joblient ></Joblient> </div>
                                <hr></hr>
                                <h4>Name: {session.name}</h4>
                        </Grid>

                            {/* <Grid item style={{color:'gray', marginBottom:'0em'}}>
                                <span ><strong>Candidate:</strong> {session.email?session.email:localStorage.getItem('name')},&nbsp;&nbsp;  
                                <strong>Interviewed By:</strong>  {session.createdBy},&nbsp;&nbsp;&nbsp;&nbsp;
                                <strong>On:</strong> {new Date(session.createdOn).toLocaleDateString('en-GB', {
                                        day: 'numeric', month: 'short', year: 'numeric'})} </span>
                            </Grid> */}
    
                            {/* <Grid item style={{color:'gray', marginBottom:'2em'}}>
                                <span ><strong>Test Status:</strong> {session.assessments[0].status}&nbsp;&nbsp;</span>
                            </Grid> */}

                            {/* <Grid item container direction="row">
                                <Grid item xs={3} >
                                    <TextField style={{width:'12em'}}
                                        select
                                        label="Overall Rating"
                                        variant="outlined"
                                        value = {feedback.rating}
                                        onChange={(event) => {
                                            let temp = {...feedback};
                                            temp.rating = event.target.value;
                                            setFeedback(temp)
                                        }}
                                        >
                                        <MenuItem id={1} value="ThumbsUp">Thumbs Up</MenuItem>
                                        <MenuItem id={2} value="ThumbsDown">Thumbs Down</MenuItem>
                                    </TextField>
                                </Grid>

                            </Grid> */}
                            {/* <Grid item xs ={12}>
                                <span>Overall Comment</span>
                                <TextEditor toolbar={true} content={feedback.comment} contentChanged={(content)=> {let temp = {...feedback};
                                    temp.comment = content;
                                    setFeedback(temp)}} />
                            </Grid> */}
                            {quiz&&Object.entries(quiz).length>0&&<Grid item xs ={12}>
                                {renderQuizContent()}
                                <hr></hr>
                            </Grid>}
                            {code&&code.length>0&&<Grid item xs ={12}>
                                {getCodeContent()}
                                <hr></hr>
                            </Grid>}
                            {design&&design.length>0&&<Grid item xs ={12}>
                                {getDesignContent()}
                            </Grid>}

                        </Grid>
                </Grid>
            </Grid>
        </Grid>  
}

export default AssessmentReport;