export const aws_qa_set =[
    {
        "id": 1,
        "title": "AWS Services Interview - Advance",
        "tags" :"aws services",
        "active": true,
        "difficulty": 5,
        "format": "plain",
        
        "sets": [
            {
                "tags":"aws,s3,athena",
                "Q":`How to develop cost-effective custom event logger? `,
                "A":`We developed Event Logger which can scale 100k/sec with cost-effective using (S3 +Athena) to store relevant search and improved the latency.`
            },
            {
                "tags": "aws,bigdata,search",
                "Q":`How to consume bigdata and make it highly searchable? `,
                "A":`Big data processing with Apache Kafka, Storm, and storing it in Mongo,ElasticSearch & S3 for better searching capabilities in a scale of up to 5-10M/day/customer.`
            },
            {
                "tags": "elastic search",
                "Q":`What are the reporting and sharing in elastic search? `,
                "A":`We developed Event Logger which can scale 100k/sec with cost-effective using (S3 +Athena) to store relevant search and improved the latency.`
            },
            {
                "tags": "strom,pipeline",
                "Q":`Have you ever written pipeline for continuous delivery?`,
                "A":`Created Strom pipeline to validate the integrity of the document by validating different data stores and providing the auto-remediation option for recovery.`
            },
            {
                "tags": "reporting framework,tableau",
                "Q":`Have you develop and reporting framework using hadoop and tableau?`,
                "A":`We have developed such framework and used Cloudera Hadoop with Tableau Desktop & Service to provide reporting solution with ETL capabilities`
            },
        ]
    },

    {
        "id": 2,
        "title": "AWS Services Interview - Advance",
        "tags" :"aws services",
        "active": true,
        "difficulty": 5,
        "format": "plain",
        
        "sets": [
            {
                "tags":"aws,s3,athena",
                "Q":`Have you deveoped any Incident Autohealing system? `,
                "A":`We have developed it using AWS CloudTrail, CloudWatch LogGroups & EventBridge,Lamba Function, ElasticSearch/Kibana.`
            },
            {
                "tags": "aws,bigdata,search",
                "Q":`What are different aws offerings to create and deploy a service? `,
                "A":`Cloud Compute (AMI, EC2, Security Groups) , Storage (S3,CloudFront , SQS), Database (DocumentDB, RDS), Monitoring (CloudWatch, CloudTrail).`
            },
            {
                "tags": "elastic search",
                "Q":`How to monitor and alert various aws services? `,
                "A":`Application & CloudWatch Metrics to Grafana using Spring Boot Actuator &Prometheus. Creation of Dashboards & Alerting on Grafana and its integration with PagerDuty.`
            },
            {
                "tags": "strom,pipeline",
                "Q":`Can you describe log tracing component in springboot?`,
                "A":`We integrated Netflix Spring Cloud Sleuth & Zipkin for Distributed log tracing.`
            },
            {
                "tags": "reporting framework,tableau",
                "Q":`Have you developed any kubernetes cron job?`,
                "A":`Developed Kubernetes Cron Jobs module for SLA Triggers.`
            },
        ]
    },
    {
        "id": 3,
        "title": "AWS Services Interview - Advance",
        "tags" :"aws services",
        "active": true,
        "difficulty": 5,
        "format": "plain",
        
        "sets": [
            {
                "tags":"aws,s3,athena",
                "Q":`Did you develop any microservice to check vulnerabilities?`,
                "A":`Developed microservices for scanning the Git/GitHub/Perforce SCM Repositories & PR's for secrets/vulnerabilities detection & their lifecycle management`
            },
            {
                "tags": "aws,bigdata,search",
                "Q":`Have you devloped and design system to perform real-time data streaming?`,
                "A":`I ahve designed system to performing Real Time data streaming leveraging Hadoop, Kafka Connect,HDFS, Hive, PrestoDB, Tableau.`
            },
            {
                "tags": "elastic search",
                "Q":`Have you work with Splunk?`,
                "A":`Worked with Splunk to integrate API's for executing daily crons to export Splunk Dashboards & Reports. Modified the log format to records for efficient extraction of analytics field using splunk queries.`
            },
            {
                "tags": "strom,pipeline",
                "Q":`Have you developed and API Performance Testing framework?`,
                "A":`Developed CI Integrated Plug & Play Performance Framework based on Jmeter engine and generates/notifies results via Email/Einstein Analytics.`
            },
            {
                "tags": "reporting framework,tableau",
                "Q":`Have you developed any data connector?`,
                "A":`Developed Custom File, Sql , Mongo and HDFS Kafka Sink Connectors in Standalone/Distributed mode`
            },
        ]
    },
    {
        "id": 4,
        "title": "AWS Services Interview - Advance",
        "tags" :"aws services",
        "active": true,
        "difficulty": 5,
        "format": "plain",
        
        "sets": [
            {
                "tags":"aws,s3,athena",
                "Q":`Have you worked with any JSON Web Token Management System?`,
                "A":`Designed & Created JSON Web Token Management System which serves as Backbone of system for request authentication and handles millions of requests/day.`
            },
            {
                "tags": "aws,bigdata,search",
                "Q":`Are you familiar with Kafka Streams?`,
                "A":`Developed Kafka Streams for converting JSON message Topics into Avro format using Schema Registry and Kafka Rest Proxy Server.`
            },
            {
                "tags": "elastic search",
                "Q":`Have you use KSql, can you provide some example?`,
                "A":`Have written multiple KSql queries to filter data`
            },
            {
                "tags": "strom,pipeline",
                "Q":`Are you aware where you can apply Observability?`,
                "A":`Prometheus, Grafana, Splunk, Graylog.`
            },
            {
                "tags": "reporting framework,tableau",
                "Q":`What are different trade-off's on microservice?`,
                "A":`High Availability ,Scalability ,Async Programming, Blue-Green Deployment.`
            },
        ]
    }
]