import { Grid, Typography } from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Leader from './Leader';
import { useEffect, useState } from 'react';
import { API, fetchData } from '../../config/apiService';
const LIMIT = 12;
const SHIFT = 2;

const LeaderBoard = (props)=>{
    const  userId = props.userId;
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState(0);
    const [leads, setLeads] = useState();

    console.log('LeaderBoard userId:::', props)

    useEffect( ()=> {
        getData();
    }, [userId, page])
   
    const getData = async()=>{
      console.log('`${API.leader}?userId=${userId}&page=${page}&size=${LIMIT}`', `${API.leader}?userId=${userId}&page=${page}&size=${LIMIT}`)

      let res = await fetchData(`${API.leader}?userId=${userId}&page=${page}&size=${LIMIT}`, "Leaderboard", null, null, null);
      //console.log('response data:', res);
      if(page == 1) {
        setPagination(Math.ceil(res.count/LIMIT));
      }
      setLeads(res.leads);
      //Create candidate relative ranking
      // let leadsTemp = leaders.map((item, key)=>{
      //     let temp = {...item};
      //     if(item.coins < lastCoins) {
      //         lastCoins = item.coins;
      //         rank = rank + 1;
      //     }
      //     temp.rank = rank;
      //     return temp;
      // })
   
    }

    return (
      <>
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          style={{ padding: "10px 20px 10px 10px", minHeight: "93vh" }}
          spacing={1}
        >
 
          <Grid
            container
            item
            xs
            direction="column"
          >
            {leads && leads.length > 0 ? (
                <Leader leads={leads} />
            ) : (
              <Typography variant="h5" style={{ textAlign: "center" }}>
                No leads found
              </Typography>
            )}
          </Grid>
          <Grid item >
            <Pagination count={pagination}   color="primary" 
                onChange = {(ev,value)=> { setPage(value)}}
                renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                
                />
                )}/>
          </Grid>
        </Grid>
      </>
    );

}

export default LeaderBoard;