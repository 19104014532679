import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import LocalLibraryOutlinedIcon from "@material-ui/icons/LocalLibraryOutlined";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import CodeTwoToneIcon from "@material-ui/icons/CodeTwoTone";
import CollectionsIcon from "@material-ui/icons/BookRounded";
import DashboardIcon from "@material-ui/icons/DashboardRounded";
import BookmarkIcon from "@material-ui/icons/Bookmark"

import React from "react";

export const defaultMenu = [
  {
    icon: <HomeOutlinedIcon />,
    title: "Dashboard",
    items: [
      {
        icon: <BookmarkIcon />,
        title: "Rev List",
      }
    ]
  },
  {
    icon: <LocalLibraryOutlinedIcon />,
    title: "Code",
    items: [
      {
        icon: <CodeTwoToneIcon />,
        title: "Practice",
        items: [
          {
            title: "Coding Set 1",
            to: "/thedowtheory"
          },
          {
            title: "Coding Set 2",
            to: "/chart"
          },
          {
            title: "Coding Set 3",
            to: "/trendlines"
          },
          {
            title: "Coding Set 4",
            to: "/sandr"
          }
        ]
      },
      {
        icon: <CollectionsIcon/>,
        title: "Test",
        items: [
          {
            title: "The Dow Theory",
            to: "/thedowtheory"
          },
          {
            title: "Charts & Chart Patterns",
            to: "/chart"
          },
          {
            title: "Trend & Trend Lines",
            to: "/trendlines"
          },
          {
            title: "Support & Resistance",
            to: "/sandr"
          }
        ]
      },
      {
        title: "Skill",
        items: [
          {
            title: "The Dow Theory",
            to: "/thedowtheory"
          },
          {
            title: "Charts & Chart Patterns",
            to: "/chart"
          },
          {
            title: "Trend & Trend Lines",
            to: "/trendlines"
          },
          {
            title: "Support & Resistance",
            to: "/sandr"
          }
        ]
      }
    ]
  },
  {
    icon: <TrendingUpOutlinedIcon />,
    title: "Design"
  },
  {
    icon: <TrendingUpOutlinedIcon />,
    title: "Courses"
  },
  {
    icon: <DescriptionOutlinedIcon />,
    title: "Blog"
  }
];
