export const resSchema = {
	"heading" :{
		"name": "",
		"designation": "",
	},
    "contactinfo": {
        "email": {
            "section": "Email",
            "content": ""
        },
        "mobile": {
            "section": "Mobile",
            "content": ""
        },
        "experience": {
            "section":"Total work experience (yrs)",
            "content": "0"
        },
        "linkedin": {
            "section":"Social Link",
            "content": ""
        },
    },
	"aboutme": {
		"section":"About me",
		"content":""
	},
	"summary": {
		"section":"Profile Summary",
		"content":[""]
	},
	"coreskills": {
		"section":"Core Competencies",
		"content": []
	},
	"techskills": {
		"section":"Technical Skills",
		"content": []
	},
	"personalinfo": {
		"section": "Personal Information",
		"dob":"",
		"location": "",
	},
	"workexp": {
		"section":"Work Experience",
		"works": []
	},
	"courses": {
		"section": "Courses & Certifications",
		"content": []
	},
	"education": {
		"section": "Education History",
		"content": []
	},
	"awards": {
		"section": "Awards & Recongnition",
		"content": []
	}
}

export const resPreFilled = {
	"heading" :{
		"name": "Avnish Kumar Gupta",
		"designation": "Staff Software Engineer",
	},
    "contactinfo": {
        "email": {
            "section": "Email",
            "content": "a.kumar@gmail.com"
        },
        "mobile": {
            "section": "Mobile",
            "content": "9999999991"
        },
        "experience": {
            "section":"Total work experience (yrs)",
            "content": "2"
        },
        "linkedin": {
            "section":"Social Link",
            "content": "https: //www.linkedin.com/in/a-kumar-042b27145/"
        },
    },
	"aboutme": {
		"section":"About me",
		"content":"**Senior Software Engineer with Extensive Experience Leading Complex Projects and Developing Innovative Solutions."
	},
	"summary": {
		"section":"Profile Summary",
		"content":["Experienced backend developer with **8 years of expertise in **designing and implementing scalable, secure, and efficient web applications. Proficient in multiple **programming languages,databases, and development frameworks. Adept at collaborating with cross-functional teams, communicating technical concepts, and delivering high-quality code on time."]
	},
	"coreskills": {
		"section":"Core Competencies",
		"content": [
			{
				"skill":"Java",
				"rating": 5
			},
			{
				"skill":"Golang",
				"rating": 5
			},
			{
				"skill":"Python",
				"rating": 5
			},
			{
				"skill":"Microservices",
				"rating": 5
			},
			{
				"skill":"DSA",
				"rating": 5
			},
			{
				"skill":"System Design",
				"rating": 5
			},
			{
				"skill":"Spring Boot",
				"rating": 5
			},
			{
				"skill":"Kafka",
				"rating": 5
			}
		]
	},
	"techskills": {
		"section":"Technical Skills",
		"content": ["Elixir","Redis","MySQL","ELASTIC Search", "Docker", "Kubernetese"
        ,"Kibana", "Grafana","Shell Scripting", "MongoDB",
        "Design Patterns", "JUnit","Rest Api","Design","AWS GCP"
		]
	},
	"personalinfo": {
		"section": "Personal Information",
		"dob":"20-June-1980"
	},
	"workexp": {
		"section":"Work Experience",
		"works": [
			{
				"post":"Senior Software Engineer",
				"start": "Jan 2022",
				"end": "Present",
				"company":"Gojek",
				"tech": "**Java, Golang, Python, Elixir, **Elastic Search, Kafka, CI-CD, Docker, Kubernetes, Redis, GCP, Git, Graylog, Grafana,JIRA, ArgoCD",
				"desc": ["**Reporting & Analytic Service provides regular reports to millions of merchants on daily basis like payout, settlement, and merchant-specific reports. Whereas **Analytic Service provides merchant-related metrics across different categories, such as Business, Operations, Campaigns, and Insights."
				]
			},
			{
				"post":"Senior Software Engineer",
				"start": "Feb 2018",
				"end": "May 2022",
				"company":"RedBus",
				"tech": "**Java8, SpringBoot, Golang, Python, Elastic Search, Kafka, Jenkins, CI-CD, **Docker, Kubernetes, Redis, MySQL, MongoDB, AWS, Git, ELK, CloudWatch, JIRA, ArangoDB.",
				"desc": ["Tracking Service provides live Bus tracking on maps & ETA to millions of passengers on daily basis.",
				         "GTFS Service provides Bus schedules on the transit section under Google maps. Nearly ~2M bus search quires land on the transit page.",
								 "Reporting Service provides ~1M reports to Bus Operator.",
								 "Accounting Service helps to calculate the total fare of the ~1M tickets on daily basis."
				]
			},
			{
				"post":"Software Engineer",
				"start": "Apr 2016",
				"end": "Jul 2018",
				"company":"Cognizant",
				"tech": "Java8, SpringBoot,MySQL,Jenkins, Git, JIRA",
				"desc": ["**Dell EMC Collector Enterprise is an application that automates technical support for your Dell server, storage, and networking devices"
				]
			},
			{
				"post":"Software Engineer",
				"start": "Apr 2016",
				"end": "Jul 2018",
				"company":"Velankani Communications Technologies (VCTI)",
				"tech": "**Java8, SpringBoot, Hibernate, **MySQL, Maven, Git, Jenkins",
				"desc": ["**Cisco Prime Network App as an extensible integration platform for network and service management.",
            "**TCL Cloud Platform provides cloud space(Servers, Storage, Networking) to the clients."
				]
			}
		]
	},
	"courses": {
		"section": "Courses & Certifications",
		"content": ["Java SE: Programming","AWS Professional Level"
		]
	},
	"education": {
		"section": "Education History",
		"content": ["C-DAC Bengaluru -- PGDM CSE -- 2014","MDU Rohtak -- B.Tech CSE -- 2013"
		]
	},
	"awards": {
		"section": "Awards & Recongnition",
		"content": ["**TrailBlazer award for extraordinary work on GTFS feed generation at redBus.,",
            "**OnTheSpot award for elegant performance on the B2B platform at redBus",
            "**Best Employee award at Cognizant",
            "**Best Employee award at Velankani Software"
		]
	}
}


export const resEmpty = {
	"heading" :{
		"name": "Your Name",
		"designation": "Your Designation",
	},
    "contactinfo": {
        "email": {
            "section": "Email",
            "content": "xyz@gmail.com"
        },
        "mobile": {
            "section": "Mobile",
            "content": "9999999991"
        },
        "experience": {
            "section":"Total work experience (yrs)",
            "content": "1"
        },
        "linkedin": {
            "section":"Social Link",
            "content": "https: //www.linkedin.com/in/abc-xyz-042b27145/"
        },
    },
	"aboutme": {
		"section":"About me",
		"content":"Explain about your current role, ex. **Senior Software Engineer** with Extensive Experience Leading Complex Projects and Developing Innovative Solutions."
	},
	"summary": {
		"section":"Profile Summary",
		"content":["Add your profile summary, ex. Experienced backend developer with **8 years** of expertise in **designing and implementing scalable**, secure, and efficient web applications. Proficient in multiple **programming languages,databases, and development frameworks**."]
	},
	"coreskills": {
		"section":"Core Competencies",
		"content": [
			{
				"skill":"Java",
				"rating": 5
			},
			{
				"skill":"Microservices",
				"rating": 4
			},
		]
	},
	"techskills": {
		"section":"Technical Skills",
		"content": ["AWS","Redis","MySQL",]
	},
	"personalinfo": {
		"section": "Personal Information",
		"dob":"28-July-1986",
		"location": "Bengaluru",
	},
	"workexp": {
		"section":"Work Experience",
		"works": [
			{
				"post":"Your Post i.e. Senior Software Engineer",
				"start": "Jan 2022",
				"end": "Present",
				"company":"Company Name",
				"tech": "add tech **skills",
				"desc": ["Add your project description, ex. Provides regular **reports to millions of merchants on daily basis",
				"Add your next project description"]
			},
			{
				"post":"Your Post i.e. Senior Software Engineer",
				"start": "Feb 2018",
				"end": "May 2022",
				"company":"Company i.e RedBus",
				"tech": "Add tech skills i.e **Java8, SpringBoot",
				"desc": ["Tracking Service provider.",
				         "GTFS Service provider", 
								 "Reporting Service provides ~1M reports to Bus Operator.",
				]
			},
		]
	},
	"courses": {
		"section": "Courses & Certifications",
		"content": ["Java SE: Programming","AWS Professional Level"
		]
	},
	"education": {
		"section": "Education History",
		"content": ["C-DAC Bengaluru -- PGDM CSE -- 2014","IIIT Hyderabad -- B.Tech CSE -- 2013"
		]
	},
	"awards": {
		"section": "Awards & Recongnition",
		"content": ["**TrailBlazer** award for extraordinary work on GTFS feed generation at redBus.,",
            "**Best Employee** award at Cognizant",
		]
	}
	}

	export const resSampleDataShort = {
	"heading" :{
		"name": "Your Name",
		"designation": "Staff Software Engineer",
	},
    "contactinfo": {
        "email": {
            "section": "Email",
            "content": "xyz@gmail.com"
        },
        "mobile": {
            "section": "Mobile",
            "content": "9999999991"
        },
        "experience": {
            "section":"Total work experience (yrs)",
            "content": "1"
        },
        "linkedin": {
            "section":"Social Link",
            "content": "https: //www.linkedin.com/in/abc-xyz-042b27145/"
        },
    },
	"aboutme": {
		"section":"About me",
		"content":"Explain about your current role, ex. **Senior Software Engineer** with Extensive Experience Leading Complex Projects and Developing Innovative Solutions."
	},
	"summary": {
		"section":"Profile Summary",
		"content":["Add your profile summary, ex. Experienced backend developer with **8 years** of expertise in **designing and implementing scalable**, secure, and efficient web applications. Proficient in multiple **programming languages,databases, and development frameworks**."]
	},
	"coreskills": {
		"section":"Core Competencies",
		"content": [
			{
				"skill":"Java",
				"rating": 5
			},
			{
				"skill":"Microservices",
				"rating": 5
			},
		]
	},
	"techskills": {
		"section":"Technical Skills",
		"content": ["AWS","Redis","MySQL",]
	},
	"personalinfo": {
		"section": "Personal Information",
		"dob":"28-July-1986",
		"location": "Bengaluru",
	},
	"workexp": {
		"section":"Work Experience",
		"works": [
			{
				"post":"Senior Software Engineer",
				"start": "Jan 2022",
				"end": "Present",
				"company":"Gojek",
				"tech": "**Java**, Golang, Python, Elixir, **Elastic Search, Kafka**, CI-CD, Docker, Kubernetes, Redis, GCP, Git, Graylog, Grafana,JIRA, ArgoCD",
				"desc": ["Add your project description, ex. Provides regular **reports to millions of merchants on daily basis",
				"Add your next project description"]
			},
			{
				"post":"Senior Software Engineer",
				"start": "Feb 2018",
				"end": "May 2022",
				"company":"RedBus",
				"tech": "**Java8, SpringBoot, Golang, Python, Elastic Search, Kafka**, Jenkins, CI-CD, **Docker, Kubernetes, Redis, MySQL, MongoDB, AWS**, Git, ELK, CloudWatch, JIRA, ArangoDB.",
				"desc": ["Tracking Service provides live Bus tracking on maps & ETA to millions of passengers on daily basis.",
				         "GTFS Service provides Bus schedules on the transit section under Google maps. Nearly ~2M bus search quires land on the transit page.",
								 "Reporting Service provides ~1M reports to Bus Operator.",
				]
			},
		]
	},
	"courses": {
		"section": "Courses & Certifications",
		"content": ["Java SE: Programming","AWS Professional Level"
		]
	},
	"education": {
		"section": "Education History",
		"content": ["C-DAC Bengaluru -- PGDM CSE -- 2014","IIIT Hyderabad -- B.Tech CSE -- 2013"
		]
	},
	"awards": {
		"section": "Awards & Recongnition",
		"content": ["**TrailBlazer** award for extraordinary work on GTFS feed generation at redBus.,",
            "**OnTheSpot** award for elegant performance on the B2B platform at redBus",
            "**Best Employee** award at Cognizant",
		]
	}
	}

	export const resTMPLT = [
		{
			key: 'TEMPLATE - EMPTY',
			value : resEmpty
		},
		{
			key: 'TEMPLATE - PRE FILLED',
			value: resPreFilled
		},
	]
