import { Button, Checkbox, Grid } from "@material-ui/core"
import { API, saveData } from "../../config/apiService";
import { useState } from "react";
import { showErrorToast } from "../../utils/toastMsg";


const CodeDesignCatalog = ({topic, coins, setViewTest})=>{
    
    const [url, setUrl] = useState();

    const startNow = async()=>{
        let data = {
            category: topic
        }
        let res = await saveData(API.question, data );
       
        if(res && res.data) {
            console.log('start test success:', res.data._id)
            //let tempUrl = `${window.location.hostname}:${window.location.port}/interview:${btoa(res.data._id)}`
            let tempUrl = `/interview:${btoa(res.data._id)}`
            setUrl(tempUrl);
        } else {
            console.log("Unable to start test", res)
        }
    }

    const startTest = (value)=>{
        let type = localStorage.getItem("type");
        if (type === 'guest') {
            showErrorToast("Please logout and signup your account to start your test", 5000);
        }
        setViewTest(value);
    }

    return <div  className="topic-chip-container" style={{padding:'1em', background:'white', marginTop:'40px', marginLeft: '10px', marginBottom:'100px', width:'96%', borderRadius:'10px', border:'0.5px solid lightgray'}}>
            <Grid item xs = {11} >
                <div style={{marginBottom:'0.5em', borderRadius:'10px', padding:'10px',fontSize:'1.2em', }}>
                    <span style={{fontWeight:'bold',color:'blue', padding:'1em', color:'#5eac1a'}}>Test Instructions:</span>
                    <ul  style={{paddingLeft:'2em', marginTop:'1em',  color:'#5eac1a'}}>
                        <li>{`No. of questions: 1`}</li>
                        <li>{`Test duration: 40 Minutes`}</li>
                        <li>{`Immediately after starting the test, questions will appear`}</li>
                        <li>{`Several unique methods have already been implemented into the system to detect cheating and inappropriate behavior.`}</li>
                        <li>{`Copy/paste of AI contents is prohibited.`}</li>
                        <li>{`A unique and accurate answer will earn you more points.`}</li>
                        <li>{`Submitted solution may be directly verified by employer/recruiter`}</li>
                        <li>{`Up to ${coins} points will be assigned to each successful submission.`}</li>
                        <li>{`Do not press the back button, it may end the test or reduce the maximum point in half and the attempt will increase by 1.`}</li>
                    </ul>
                </div>
                {console.log('url!==null:::',url, url&&url.length>0)}
                <div style={{display:'inline-block', paddingLeft:'1.2em'}}>

                    <Checkbox disabled={url} onChange={()=>{startNow()}}>
                    </Checkbox><span style={{cursor:'pointer'}}>{`I am agree with given instructions above`}</span>
                    {/* <Checkbox disabled={url} checked={url&&url.length>0} onChange={()=>{startNow()}}>
                    </Checkbox><span style={{cursor:'pointer'}}>{`Are you agree with instructions ?`}</span> */}
                </div>
                <Button disabled={!url} style={{}}>
                    <a  href={url} target="_blank" style={{ textDecoration:'none', padding:'0.5em', margin:'1.4em', width:'10em', color:'white',
                        background:`${url? 'rgb(155 76 145)' : 'lightgray'}`, borderRadius:'5px', border:'0.5px solid lightgray'}} 
                        onClick={()=>{ startTest('') }}> Start Test 
                    </a>
                </Button>
            </Grid>
    </div>
}

export default CodeDesignCatalog;