import React from "react";
import CV from "./CV";


function ResumeLayout() {
  return (
    // <div style={style}>
    //   <Header/>
    //   <CV/>
    // </div>
    <CV/>
  )


}

export default ResumeLayout;