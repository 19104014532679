import React from 'react'
import { useLocation } from 'react-router-dom';

const InvalidTest = (props) => {
  const location = useLocation();
  return (
    <div className='flex flex-col justify-center p-10 space-y-3'>
      <h2 className=' text-center text-red-500'>{location.state.reason}</h2>

      <span >We're sorry, but the test you are trying to access is either no longer available or has already been completed.</span>

      <span >If you believe this is an error, please check the test link or reference number to ensure it's correct. For further assistance or to explore other available tests, please contact your recruiter if the problem does not solves.</span>

      <span className='text-center'>Thank you for your understanding...</span>
    </div>
  )
}

export default InvalidTest;