import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import { fetchData, saveData } from "../../config/apiService";
import { API } from "../../config/apiService";
import ProfileTile from "./ProfileTile";
import ProfileSortOption from "./ProfileSortOption";
import { isMobile } from "../../utils/mobileUtil";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ChatView from "../chat/ChatView";
import Loader from "../loader/Loader";
import { showErrorToast } from "../../utils/toastMsg";
import { useSelector, useDispatch } from "react-redux";
import { shortlistCandidate } from "../../state/action-creators/test";
import InfiniteScroll from "react-infinite-scroll-component";
// import ScrollComponent from "./ScrollComponent";


const ProfileSearch = () => {
  const [searchOptions, setSearchOptions] = useState({});
  const [profiles, setProfiles] = useState([]);
  const clsMenu = `menuNormal fontS-false`
  const [curProfile, setCurProfile] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let q = useSelector(state => state.search.pquery);
  const [page, setPage] = useState(1);

  const left = isMobile ? 12 : 8;
  const iconSize = isMobile ? '40px' : '30px';

  const [pagination, setPagination] = useState(1);
  const [height, setHeight] = useState('75.8vh');
  //console.log('ProfileSearch123: ', q)

  // const { items, target } = InfiniteScroll()

  useEffect(() => {
    if (q) {  // if query change then need to reset
      //console.log('query reset ..', q)
      setPage(1);
      if (profiles.length > 0) {
        setProfiles([]); // need to reset
      }
      setTimeout( ()=>{searchProfile(true, 1);}, 10);
    
      setHeight('75.8vh');
    }

  }, [q])

  const useStyles = makeStyles((theme) => ({
    noBorder: {
      border: "none",
    },
  }));

  const shortList = (candidateId) => {
    console.log(candidateId);
    dispatch(shortlistCandidate(candidateId));
  }

  // const setOptions = (key, value) => {
  //   let temp = {...searchOptions}
  //   temp[key] = value;
  //   temp['touched'] = true;
  //   setSearchOptions(temp);
  // }

  const searchProfile = async (reset, pageNew) => {
    // let query = "";
    // if(searchOptions.search) {
    //   query = `search=${searchOptions.search}`;
    // }

    // if(searchOptions.sort) {
    //   query = `${query}&sort=${searchOptions.sort}`;
    // }
    let newPage = pageNew || page;  // on query change need to reset page to 1

    //console.log('query::::', q.query, newPage);

    let url = '';
    if (q.query) {
      url = `${API.resume}/profile?page=${newPage}&limit=10&${q.query}`
    } else {
      url = `${API.resume}/profile?page=${newPage}&limit=10`;
    }
    setPage(newPage + 1);
    console.log('profile url:', newPage, url);
    //setLoading(true);
    let result = await fetchData(url);
    //setLoading(false);
    //console.log('search result length:', result.length);

    let temp = { ...searchOptions }
    temp['touched'] = null;
    setSearchOptions(temp);


    if (result && result.length > 0) {
      if (reset) {
        setProfiles(result);
      } else {
        setProfiles([...profiles, ...result]);
      }
    } else if(reset){
      setProfiles([]);
    }
    setCurProfile(null);
  }

  const handleProfile = (profile) => {
    setCurProfile(profile);
    if (!profile.userId) {
      showErrorToast("User Profile Incomplete");
    }
  }

  const refresh = (setItems) => {};

  const classes = useStyles();

  return (<Grid
    item
    container
    direction="row"
    alignItems="flex-start"
    spacing={0}
    style={{ width: '99.9%', marginTop: '0em', display: 'flex', justifyContent: 'center' }}
  >
    {(!isMobile || isMobile && !curProfile) ? <Grid item xs={left} style={{ height: `${isMobile ? '' : 'calc(100vh - 188px)'}`, overflow: 'auto' }} >
      {(profiles && profiles.length > 0) ? <>
        <Grid item>
          <Typography style={{ display: 'flex', justifyContent: 'center', marginTop: '0.2em', marginBottom: '0.4em' }} variant={'h5'}>{`Matching Candidate Profiles for '${q.search}'`}</Typography>
        </Grid>
        <Grid item>
          {
            <InfiniteScroll
                dataLength={profiles.length} //This is important field to render the next data
                next={() => {
                  searchProfile();
                }}
                hasMore={true && profiles.length < 500}
                height = 'calc(100vh - 229px)'
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
                // below props only if you need pull down functionality
                // refreshFunction={refresh}
                // pullDownToRefresh
                // pullDownToRefreshThreshold={50}
                // pullDownToRefreshContent={
                //   <h3 style={{ textAlign: "center" }}># 8595; Pull down to refresh</h3>
                // }
                // releaseToRefreshContent={
                //   <h3 style={{ textAlign: "center" }}># 8593; Release to refresh</h3>
                // }
              >
                <div style={{ minHeight: "100vh" }}>
                  {profiles.map((profile, key) => (
                  <Grid container key={key} style={{ background: `${profiles[key] === curProfile ? 'rgb(174 198 205)' : '#f6fbff'}`, marginBottom: '1.7em', borderRadius: '5px', padding: '0.7em 0.5em 0.5em 0.5em' }}>
                      <Grid item xs={12} style={{ marginBottom: '0.4em' }}>
                        <button className={clsMenu} style={{ marginLeft: 'auto', float: 'right' }} onClick={() => shortList(profile)} >Shortlist</button>
                        {profile.userId && <p className={clsMenu} aria-disabled={profile.userId } onClick={() => handleProfile(profile)} style={{ marginLeft: 'auto', marginRight:'2em', float: 'right' }}><span><QuestionAnswerIcon />Send Message</span></p>}
                      </Grid>
                      <ProfileTile key={key} profile={profile} mode={"applicant"} search={q.search} />
                      <br></br>
                  </Grid>)
                  )}
                </div>
            </InfiniteScroll>
          }
        </Grid>

      </>
        :
        <Grid item style={{ display: 'flex', justifyContent: 'center', marginTop:'1em'}}>
          {profiles && <h3>No Matching Profile</h3>}
        </Grid>}
    </Grid>
      : <div style={{ width: '100%' }}>
        <button className="back" onClick={() => setCurProfile(null)} style={{ display: 'flex', marginLeft: '20px', justifyContent: 'flex-start', color: '#0a55ad', fontSize: '16px' }}><ArrowBackIcon /><span >Back&nbsp;</span></button>
      </div>
    }
    {curProfile && curProfile.userId && <Grid item xs={isMobile ? 12 : 4}>
      <ChatView profile={curProfile} chatType={"user"} />
    </Grid>}
  </Grid>)

}

export default ProfileSearch;