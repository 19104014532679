import { Collapse, Grid, Typography } from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Leader from './Leader';
import { useEffect, useState } from 'react';
import { API, fetchData } from '../../config/apiService';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import AssessmentSum from './AssessmentSum';

import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LeaderBoard from './LeaderBord';
import AssessmentReport from '../interviewer/AssessmentReport';

const AssessmentView = (props)=>{
    const userId = props.userId;
    const [assess, setAssess] = useState();
    const [open, setOpen] = useState(false);
    const [lead, setLead] = useState();
    const [showLead , setShowLead] = useState();
    const [showAssReport, setShowAssReport] = useState();

    //console.log('AssessmentView:', props);

    useEffect( ()=> {
        getData();
    }, [])

    const getData = async()=>{
      let address = `${API.leader}`;
      let query = `userId=${userId}`;
      //console.log('response data:1', userId, query);

      if(userId && userId != 'undefined' && userId != '' && userId != null) {
        address = `${API.leader}?${query}`;
        //console.log('address123333:::', userId, address);
      }
      console.log('address123:::', userId, address);

      let res = await fetchData(address, "Fetch Assessment", null, null, null);
      console.log('response data:', res);
      setLead(res);

      let temp =  res && res.assessments && Object.keys(res.assessments).map((key, i) =>{ return {key: key, value: res.assessments[key]}});
      //console.log('temp:', temp);
      setAssess(temp);
    }

    return (
      <>
        <Grid className='profileAssess'
          container
          item
          direction="column"
          //alignItems="left"
          style={{ padding: "20px"}}
        >
        <Grid item>
          <AssessmentSum lead={lead} setShowLead={setShowLead}  showLead={showLead} setShowAssReport={setShowAssReport} showAssReport={showAssReport} ></AssessmentSum>
        </Grid>
            {showLead && <Grid item xs={12} alignItems="flex-start" style={{background:'white', marginTop:'1.5em', marginBottom:'0em'}}>
              <LeaderBoard userId = {userId} ></LeaderBoard>
            </Grid>
            }
            {showAssReport && <Grid item xs={12} alignItems="flex-start" style={{background:'white', marginTop:'1.5em', marginBottom:'0em'}}>
              <AssessmentReport userId = {userId} ></AssessmentReport>
            </Grid>
            }
          <Grid item>
              <div style={{display:'inline-flex', margin:'10px 0' , cursor:'pointer'}} onClick={()=>setOpen(!open)}>
                <div style={{}} className="pageTitle">Candidate Assessments</div>
                <IconButton style={{marginLeft:'1.2em', marginTop:'0.1em', height:'1.8em'}}
                    aria-label="expand row"
                    size="small"
                    onClick={()=>setOpen(!open)}
                    >
                    {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </div>
          </Grid>
          
          {/* <div style={{display:'inline-flex',marginTop:'-0.2em', marginLeft:'0.3em', alignItems:'center', justifyContent:'center'}} >
                                <div><strong>{ass.op}</strong>:&nbsp;{ass.title}</div>
                                <div style={{display:'flex'}} ><CurrencyBitcoinIcon className='bitCoinSM'></CurrencyBitcoinIcon><div style={{marginTop:'0em', marginLeft:'0.5em'}}>{ass.coins}</div></div>
                            </div>) */}

          <Collapse in={open} timeout="auto" unmountOnExit>
          <Grid
              container
              item
              xs
              direction="column"
            >
            <div>{ 
                assess&&assess.map((item, i) => (
                  <div key={i}> 
                    <div style={{display:'flex', background:`${i%2===0?'rgb(240 235 255)':'rgb(251 240 240)'}`, marginTop:'0.5em', verticalAlign:'middle', padding:'0.4em', borderRadius:'5px', border:'1px solid lightgray' }}>
                        <div style={{color:'rgb(0 57 255)', width:'6em', alignItems:'center'}}><strong>{item.key}:</strong></div>
                        <div>
                          {
                            item.value.map((ass, i) => <div key={i} style={{display:'inline-block', marginLeft: '2em', background:'#d2d8ff',padding:'0.2em',  borderRadius:'0.5em'}}>
                              {ass.title.split('Set-').length>1 ? <div>{ass.title.split('Set-')[0]}: Set - {(i+1)}</div> : <div>{ass.title}</div>}
                              <div style={{display:'flex'}} ><CurrencyBitcoinIcon className='bitCoinSM'></CurrencyBitcoinIcon><div style={{marginTop:'0.3em', marginLeft:'0.5em'}}>{ass.coins}</div></div>
                            </div>)
                          }
                      </div>
                    </div>
                    <br></br>
                  </div>
                ))
            }
            </div>
          </Grid>
        </Collapse>
        </Grid>
      </>
    );

}

export default AssessmentView;