const COLORS = ['#757575','#34aadc','#bb86fc', 'rgb(190 163 163)', '#f1c40f', '#5856d6','#e75f79','#4cd964',
'#747775', '#a8c7fa','#f9dedc','#c4c7c5','#444746',
'#0550ae','#cf222e','#82071e','#116329','#953800','#ffd8b5','#0550ae','#8250df','#57606a','#8c959f'
]

export  const setColor = (key)=> {return {color: `${COLORS[key % COLORS.length]}`}}
export  const setBk = (key)=> {return {background: `${COLORS[key % COLORS.length]}`}}
export const setBrd = (key)=> {return {border: `2px solid ${COLORS[key % COLORS.length]}`}}

export const setPaperTheme = (key)=> {return {
    border: `2px solid ${COLORS[key % COLORS.length]}`,
    backgroundColor: `${COLORS[key % COLORS.length]}`,
    color: 'white',
    
}}