import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography,
  Modal,
  Slider,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import axios from "axios";
import { SetPopupContext } from "./MainRouter";
import apiList from "../../../config/apiList";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { isMobile } from "../../../utils/mobileUtil";
import ChatView from "../../chat/ChatView";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const useStyles = makeStyles((theme) => ({
  body: {
    height: "inherit",
  },
  statusBlock: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
  },
  jobTileOuter: {
    padding: "10px",
    margin: "0px 0",
    boxSizing: "border-box",
    width: "100%",
  },
  popupDialog: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ApplicationTile = (props) => {
  const classes = useStyles();
  const { application } = props;
  const setPopup = useContext(SetPopupContext);
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(application.job.rating);

  const appliedOn = new Date(application.dateOfApplication);
  const joinedOn = new Date(application.dateOfJoining);

  const fetchRating = () => {
    axios
      .get(`${apiList.rating}?id=${application.job._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setRating(response.data.rating);
        console.log(response.data);
      })
      .catch((err) => {
        // console.log(err.response);
        console.log(err.response.data);
        setPopup({
          open: true,
          severity: "error",
          message: "Error",
        });
      });
  };

  const changeRating = () => {
    axios
      .put(
        apiList.rating,
        { rating: rating, jobId: application.job._id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setPopup({
          open: true,
          severity: "success",
          message: "Rating updated successfully",
        });
        fetchRating();
        setOpen(false);
      })
      .catch((err) => {
        // console.log(err.response);
        console.log(err);
        setPopup({
          open: true,
          severity: "error",
          message: err.response.data.message,
        });
        fetchRating();
        setOpen(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const colorSet = {
    applied: "#3454D1",
    shortlisted: "#DC851F",
    accepted: "#09BC8A",
    rejected: "#D1345B",
    deleted: "#B49A67",
    cancelled: "#FF8484",
    finished: "#4EA5D9",
  };

  return (
    <Paper className={classes.jobTileOuter} elevation={3}>
        <Grid container item xs={12} spacing={1} direction="column" style={{fontWeight:500, lineHeight:'2.5em', fontSize:`${isMobile?'1.52em':'12px'}`}}>
          <Grid item>
            <Typography variant="h5">{application.job.title}</Typography>
          </Grid>
          <Grid item>Posted By: {application.recruiter.name}</Grid>
          <Grid item>Role : {application.job.jobType}</Grid>
          <Grid item>Salary : &#8377; {application.job.salary} per month</Grid>
          <Grid item>
           
            Duration :{" "}
            {application.job.duration !== undefined
              ? `${application.job.duration} month`
              : `Flexible`}
          </Grid>
          <Grid item>
            {application.job.skillsets.map((skill) => (
              <Chip label={skill} style={{fontSize:`${isMobile?'1.1em':''}`, marginRight: "2px", marginBottom:'5px' }} />
            ))}
          </Grid>

          <Grid item>Applied On: {appliedOn.toLocaleDateString('en-GB', {
            day: 'numeric', month: 'short', year: 'numeric'})}
          </Grid>
          {(application.status === "accepted" ||
            application.status === "finished")&&
            <Grid item>Joined On: {joinedOn.toLocaleDateString()}
            </Grid>
          }
      
          <Grid item>
            <Paper
              className={classes.statusBlock}
              style={{
                fontSize:`${isMobile?'1.52em':'12px'}`,
                background: colorSet[application.status],
                color: "#ffffff",
                height:`${isMobile?'50px':'32px'}`,
                width:'35%'
              }}
            >
              {application.status}
            </Paper>
          </Grid>

          {(application.status === "accepted" ||
            application.status === "finished") && 
            <Grid item>
              <Button style={{height:'30px'}}
                variant="contained"
                color="primary"
                className={classes.statusBlock}
                onClick={() => {
                  fetchRating();
                  setOpen(true);
                }}
              >
                Rate Job
              </Button>
            </Grid>
            }
        </Grid>
      <Modal open={open} onClose={handleClose} className={classes.popupDialog}>
        <Paper
          style={{
            padding: "20px",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minWidth: "30%",
            alignItems: "center",
          }}
        >
          <Rating
            name="simple-controlled"
            style={{ marginBottom: "30px" }}
            value={rating === -1 ? null : rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ padding: "10px 50px" }}
            onClick={() => changeRating()}
          >
            Submit
          </Button>
        </Paper>
      </Modal>
    </Paper>
  );
};

const Applications = (props) => {
  const setPopup = useContext(SetPopupContext);
  const clsMenu = `menuNormal fontS-${isMobile}`
  const [applications, setApplications] = useState();
  const [curApp, setCurApp] = useState();
  const left = isMobile ? 12 : 8;

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(apiList.applications, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setApplications(response.data);
      })
      .catch((err) => {
        // console.log(err.response);
        console.log(err.response.data);
        setPopup({
          open: true,
          severity: "error",
          message: "Error",
        });
      });
  }

  return (
      applications&&<Grid
      item 
      container
      direction="row"
      alignItems="flex-start"
      spacing={2}
      style={{width:'stretch', marginTop:'3em', width:'99%'}}
        
    >
    {(!isMobile || isMobile && !curApp ) ? <Grid  item xs={left} style={{height: 'calc(100vh - 272px)', overflow: 'auto'}} >
      <Grid item>
        {applications.length > 0 && <Typography style={{display:'flex', justifyContent:'center'}} variant={`${isMobile?'h3':'h5'}`}>My Applications</Typography>}
      </Grid>
      <Grid
        container
        item
        xs
        direction="column"
        style={{ width: "100%" , fontSize:'16px'}}
        alignItems="stretch"
      >
        {applications.length > 0 ? (
          applications.map((app, key) => (
            <div key={key} style={{background:`${app===curApp?'rgb(174 198 205)':'#a6a6fe'}`, marginBottom:'0.2em', borderRadius:'5px', padding:'0.7em 0.5em 0em 0.5em'}}>
              {/* <Grid item style={{marginBottom:'0.4em', marginTop:'0', height:`${isMobile?'60px':'24px'}`}}>
                  <p className={clsMenu} onClick={() =>setCurApp(app)} style={{marginLeft:'2em', float:'right'}}><span><QuestionAnswerIcon style={{fontSize:`${isMobile?'36px':''}`}}/>Send Message</span></p>
              </Grid> */}
              <Grid item>
                <ApplicationTile application={app} />
              </Grid>
            </div>
          ))
        ) : (
          <Typography variant="h5" style={{ textAlign: "center" }}>
            No Applications Found
          </Typography>
        )}
      </Grid>
    </Grid>
    :<div>
    <button className="back" onClick={()=>setCurApp(null)} style={{color:'#0a55ad', fontWeight:'bold', fontSize:'40px'}}><ArrowBackIcon/><span >Back&nbsp;</span></button>
    </div>
    }
    {/* {curApp && <Grid item xs={isMobile?12: 4}>
        <ChatView profile={{...curApp, appId:curApp._id, name:curApp.recruiter.name}} setCurApp= {setCurApp}   />
      </Grid>} */}
    </Grid>
  );
};

export default Applications;
