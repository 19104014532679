const buildSysout = (problem, level)=>{
    let out = ""; //
    let inout = problem.type.split('#');
    let params = inout[0].split('-');
    let allCases = "\t";

    for (let k = 0; k < problem[level].length; k++) {
        out = "";
        for(let i = 0; i < params.length; i++) {
            if (params[i].length === 2) {
                out +=  `[${problem[level][k].in[i]}]`;  
            } else {
                out +=  `${problem[level][k].in[i]}`
            }


            if (params.length>1  &&  i<params.length-1)  {
                out +=', '
            }
        }

        let expected = '';
       
        if(inout[1].length === 2) {
            expected = `[${problem[level][k].out}]`; 
        } else
            expected = `${problem[level][k].out}`;

        allCases += `console.log(solve(${out}) == ${expected})`

        if (problem[level].length > 1) {
            allCases +='\n\t';
        }
    }
    allCases = allCases.replaceAll('{', '').replaceAll('}', '');
    return allCases;
}

const buildCode = (problem, level)=> {

    //console.log('problem:', problem);

    let funcRet = 'result';
    let funcVar = 'result = null'
    let inout = problem.type.split('#');
    
    //################################## RETURN TYPE AND FUNC VARIABLES ########################

   //################################## FUNC ARGUMENTS ##########################################
    let args = "";
    
    let params = inout[0].split('-');

    let ar = 0;
    let or = 0;
    for(let i = 0; i < params.length; i++) {
        if (params[i].length ===2) {
            args += `arr${ar>0 ? (ar + 1): ''}`;
            ar++;
        } else if (params[i] === 'i') {
            args += `num${or>0 ? (or + 1): ''}`;
            or++;
        } else if (params[i] === 's') {
            args += `str${or>0 ? (or + 1): ''}`;
            or++;
        } else if (params[i] === 'b') {
            args += `b${or>0 ? (or + 1): ''}`;
            or++;
        }

        if (params.length>1 && i < params.length - 1) {
            args +=', '
        }
    }

    //############################## BUILD OUTPUT MATCH ###################################
    let sysout = buildSysout(problem, level);

return`
function solve(${args}) {
    \t${funcVar}

    \t// WRITE YOUR CODE HERE
    


    \treturn result;
}

function main() {
    ${sysout}
}

main();
`
}

const replace = (code, problem, level)=>{

    let sysoutLevel = buildSysout(problem, level);
    let arrayOfLines = code.match(/[^\r\n]+/g);

    let i = 0;
    let codeLevel = "";
    let flag = false;
    while(i < arrayOfLines.length) {
        let line = arrayOfLines[i];

        if (flag === false) {
            codeLevel +=  line +"\n";
        } else {

        }
        if (line.indexOf('main') >= 0) {
            flag = true;
        }
        if (flag === true && line.trim().length===1 && line.indexOf('}') >= 0) {
            codeLevel +="\r\n";
            codeLevel += sysoutLevel + "\r\n";
            codeLevel +=line +"\r\n";
            flag = false;
        }
        i++;
    }

    return `
${codeLevel}
    `

}

export const buildJSMain = (problem)=>{
    if(problem) {
        return buildCode(problem, 'sample')
    }
}


const selected = {
    "title": "Number Sum Recursive",
    "desc": "<p>Given an integer array, write a program to sum them recursive.</span></p><blockquote>Example.</blockquote><blockquote>input: arr = [4,7,3,6,8,1] </blockquote><blockquote>output: 29</blockquote>",
    "category": "code",
    "level": "easy",
    "mode": "test",
    // "type": "sa-i#s",
    // "sample": [{ "in": ["{3,5}"], "out": 8 }],
    "type": "ia-i-s-ba-b#i",
    "sample": [{ "in": ["{3,5}",6, "7", "{true, false}", true], "out": 8 }],
    "easy": [{ "in": ["{3,5}",6, "7", "{true, false}", true], "out": 8 }],
    // "easy": [
    //   { "in": ["{'3','5','6','7'}", 5], "out": 21 },
    //   { "in": ["{3,7}"], "out": 9 },
    //   { "in": ["{3,5,7}"], "out": 15 }
    //]
  }
  const selected3 = {
    "level": "easy",
    "mode": "test",
    "type": "sa#s",
    "sample": [{ "in": ["{'acb','bcd'}"], "out": '23' }],
    "easy": [{ "in": ["{'acb','bcd'}"], "out": '29' }]
  }

  const selected4 = {
    "level": "easy",
    "mode": "test",
    "type": "sa-i#ia",
    "sample": [{ "in": ["{'acb','bcd'}", 4], "out": "{ 3,6}"}],
    "easy": [{ "in": ["{'acb','bcd'}", 8], "out": "{12,11}" }]
  }

// let rr = buildCode(selected4, 'sample');
// console.log(rr);
// console.log('REPLACE MAIN WITH EASY')
// let essy = replace(rr, selected4, 'easy');

// console.log(essy)
