import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabLinkGroup(props) {
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.setTabIndex(newValue);
  };
 
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ alignContent:'space-evenly' }}>
        <Tabs value = {value} onChange = {handleChange} aria-label = "basic tabs example">
          <Tab style={{fontSize:'10px', fontWeight:'bold', color:'#37465A'}} label="Search Candidate&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" {...a11yProps(0)} />
          <Tab style={{fontSize:'10px', fontWeight:'bold', color:'#37465A'}} label="Job Applicants&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" {...a11yProps(0)}/>
          <Tab style={{fontSize:'10px', fontWeight:'bold', color:'#37465A'}} label="Import Candidate&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" {...a11yProps(0)}/>
          <Tab style={{fontSize:'10px', fontWeight:'bold', color:'#37465A'}} label="Add Candidate&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" {...a11yProps(0)}/>
        </Tabs>
      </Box>
    </Box>
  );
}