import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Input,
} from '@material-ui/core';
import * as XLSX from 'xlsx';

const ImportCandidate = () => {
  const [fileFormat, setFileFormat] = useState('xlsx');
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleImport = () => {
    if (!selectedFile) {
      alert('Please select a file.');
      return;
    }
    console.log(`Parsing file format: ${fileFormat}, file name: ${selectedFile.name}`);
    if (fileFormat === 'xlsx') {
      // For XLSX files
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        console.log('Parsed XLSX:', jsonData);
      };
      reader.readAsArrayBuffer(selectedFile);
    }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="file-format-select-label">Select File Format</InputLabel>
        <Select
          labelId="file-format-select-label"
          value={fileFormat}
          onChange={(e) => setFileFormat(e.target.value)}
          fullWidth
        >
          <MenuItem value="csv">CSV</MenuItem>
          <MenuItem value="xlsx">XLSX</MenuItem>
          <MenuItem value="json">JSON</MenuItem>
        </Select>
      </FormControl>
      <Box mt={2}>
        <Input type="file" onChange={handleFileSelect} />
      </Box>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleImport} >
          Import File
        </Button>
      </Box>
    </Box>
  );
};

export default ImportCandidate;
