import { useEffect, useState } from "react";
import { API, fetchData } from "../../config/apiService";
import { Grid, Typography } from "@material-ui/core";
import { isMobile } from "../../utils/mobileUtil";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ProfileTile from "../profilesearch/ProfileTile";
import ChatView from "./ChatView";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const ChatListView = (props)=>{
  
    const [profiles, setProfiles] = useState();

    const [curProfile, setCurProfile] = useState();
    const [loading, setLoading] = useState(false);

    const left = isMobile ? 12 : 6;
    const iconSize = isMobile ? '40px' : '30px';

    const [pagination, setPagination] = useState(1);

    const clsMenu = `menuNormal fontS-${isMobile}`

    const usrType = localStorage.getItem("type");

    useEffect(()=>{
       
        getChatListInfo();
       
    },[])

    const getChatListInfo = async()=>{
        console.log('ChatInfo143:')
        let res = await fetchData(`${API.chat}`);
       
        console.log('ChatInfo:', res)
        if (res) {
            setProfiles(res);
        }
    }

    const handleProfile = (profile)=>{
        setCurProfile(profile);
        profile.unRead = '';
        console.log('profiles::::::::::', profiles)
    }

    return (<Grid
        item 
        container
        direction="row"
        alignItems="flex-start"
        spacing={0}
        style={{width:'99.9%', padding:'10px', display:'flex', justifyContent:'center', borderRadius:'10px', border:'1px solid lightgray'}}
      >
          <Grid item xs={12} style={{display:'flex', justifyContent:'center'}}>

          </Grid>
            {(!isMobile || isMobile && !curProfile ) ?<Grid  item xs={left} style={{ height:'calc(100vh - 212px)', overflow:'auto'}} >
            {(profiles && profiles.length > 0) ?<>
              <Grid item>
                  <Typography style={{display:'flex', justifyContent:'center', marginBottom:'0.4em'}} variant='h5'>Messaging</Typography>
              </Grid>
              <Grid item>
                {
                  profiles.map((profile, key) => 
                  
                  <Grid container key={key} style={{background:`${profiles[key]===curProfile?'rgb(174 198 205)':'rgb(255 251 228)'}`, marginBottom:'1.7em', borderRadius:'5px', padding:'0.7em 0.5em 0.5em 0.5em'}}>
                    <Grid item xs={12} style={{marginBottom:'0.4em'}}>
                       {usrType.charAt(0)===profile.unRead && <div style={{marginLeft:'auto', float:'right', color:'white', marginTop:'-0.1em', marginLeft:'0em',   height:'10px', width:'10px',  border:'8px solid #28d6f0', borderRadius:'100%'}}></div>}
                        {usrType !== 'recruiter' && <p className={clsMenu}>To: {profile.name}</p>}
                        <p className={clsMenu} onClick={() =>handleProfile(profile)} style={{marginLeft:'auto', float:'right'}}><span><QuestionAnswerIcon style={{fontSize:`${isMobile?'36px':''}`}}/>Send Message</span></p>
                    </Grid>
                    {usrType !== 'recruiter' && <Grid item xs={12}> 
                      <div> Applied : {profile.title}</div>
                    </Grid>}

                    {/* <ProfileTile key={key} profile={profile} mode={"recruiter"}/> */}
                    {usrType === 'recruiter' && 
                       <ProfileTile key={key} profile={profile} mode={"recruiter"}/>
                    }
                    <hr></hr>
                  </Grid>
                  
                  )
                }
              </Grid>
  
            {/* <Grid item xs={8} style={{display:'flex', justifyContent:'center'}}>
              <Pagination count={pagination} color="primary" 
                  renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                  )}/>
            </Grid>  */}
            
            </>
            :
            <Grid item style={{display:'flex', justifyContent:'center'}}>
              {profiles && <h3>No Active Chat Available</h3>}
            </Grid>}
      </Grid>
      :<div style={{width:'100%'}}>
      <button className="back" onClick={()=>setCurProfile(null)} style={{display:'flex', justifyContent:'flex-start', color:'#0a55ad', marginLeft:'-5px', marginBottom:'20px'}}><ArrowBackIcon/><span >Back&nbsp;</span></button>
      </div>
      }
      {curProfile && curProfile.userId && <Grid item xs={isMobile?12: 6}>
        <ChatView profile={curProfile} chatType={"user"}/>
      </Grid>}
  </Grid>)


    // return (
    //         <>
    //         <Grid item>
    //             <Typography style={{display:'flex', justifyContent:'center', marginBottom:'0.4em'}} variant={`${isMobile?'h3':'h5'}`}>All Users</Typography>
    //         </Grid>
    //         {
    //             chatList && chatList.map((profile, key) => {
    //                 return <div>
        
    //                         <Grid container key={key} style={{background:`${profile.userId===userId?'rgb(174 198 205)':'rgb(255 251 228)'}`, marginBottom:'1.7em', borderRadius:'5px', padding:'0.7em 0.5em 0.5em 0.5em'}}>
    //                             <Grid item xs={12} style={{marginBottom:'0.4em'}}>
    //                             <p className={clsMenu} onClick={() =>handleProfile(profile)} style={{marginLeft:'auto', float:'right'}}><span><QuestionAnswerIcon style={{fontSize:`${isMobile?'36px':''}`}}/>Send Message</span></p>
    //                             </Grid>
    //                             <div>{profile.name}</div>
    //                             <br></br>
    //                         </Grid> 
    //                 </div>

    //             })
    //         }

    //         </>
    //     )
    
    }
  

export default ChatListView;