const keySkills = [
    'netcore',
    'adonet',
    'aspnet',
    'aspnetmvc',
    'aspnetwebapi',       
    'agile&scrum',        
    'android',
    'angular',
    'angularjs',
    'azure',
    'azureservicebus',    
    'c#',
    'css',
    'cosmosdb',
    'dependencyinjection',
    'designpatterns',     
    'devops',
    'entityframework',
    'flutter',
    'git',
    'golang',
    'graphql',
    'html5',
    'ionic',
    'java',
    'javascript',
    'kotlin',
    'linq',
    'laravel',
    'mongodb',
    'mysql',
    'nodejs',
    'oop',
    'objective-c',
    'php',
    'pwa',
    'python',
    'react',
    'reacthooks',
    'reactnative',
    'reactiveprogramming',
    'redis',
    'redux',
    'ruby',
    'rubyonrails',
    'sql',
    'spring',
    'swift',
    't-sql',
    'typescript',
    'uxdesign',
    'unittesting',
    'vuejs',
    'wcf',
    'wpf',
    'websecurity',
    'websockets',
    'xamarin',
    'ios',
    'jquery',
    'apidesign',
    'availability&reliability',
    'captheorem',
    'cdn',
    'caching',
    'concurrency',
    'cryptography',
    'ddd',
    'databases',
    'docker',
    'kubernetes',
    'layering&middleware',
    'loadbalancing',
    'microservices',
    'nosql',
    'reactivesystems',
    'soa',
    'softwarearchitecture',
    'softwaretesting',
    'arrays',
    'backtracking',
    'big-onotation',
    'binarytree',
    'bitmanipulation',
    'blockchain',
    'datastructures',
    'divide&amp;conquer',
    'dynamicprogramming',
    'fibonacciseries',
    'graphtheory',
    'greedyalgorithms',
    'hashtables',
    'heapsandmaps',
    'linkedlists',
    'queues',
    'recursion',
    'searching',
    'sorting',
    'stacks',
    'strings',
    'trees',
    'trie',
  ]

  export const matchSkills = (text)=>{
    console.log('xxxx:', text);
    let tokens = text.replaceAll('.', '').toLowerCase().split(/(?:,| |:)+/);     //.split(/[\s,]+/);
    let result = [];
    let marked = new Set();
    for (let x = 0 ; x < tokens.length; x++) {
      let token = tokens[x];
      if (token.length<3) continue;
      for(let skill of keySkills) {
        if(token.indexOf(skill) >=0) {
//        if(skill.indexOf(token) >=0) {
            if(!marked.has(skill)) {
              //console.log('skill:::', skill, '===', token )
              result.push(skill);
              marked.add(skill);
            }
            break;
        }
      }
    }

    return result;
  }


  // let xx = 'Prestige Shantiniketan nodejs, react: Building, Crescent:3, 4th Floor, Whitefield Skills 1) Java with SQL 2) C++ with Multithreading 3) C++';
  // let res = matchSkills(xx);
  // console.log('matched: ', res);