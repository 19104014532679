import { useState, useEffect, useRef } from 'react';

export default function useComponentVisible(initialIsVisible, closeAny, closeMe) {

    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
       // console.log('event:', ref.current,event.target.toString(), event.target.toString().indexOf('att'), 'target:',event);
        if(!closeAny) {
            //setLogin&&setLogin({flag:false, time: Date.now()});
            setTimeout(()=>closeMe&&closeMe({flag:false, time: Date.now()}), 100);
        } else if (ref.current && !ref.current.contains(event.target)) {
           // setIsComponentVisible(false);
           setTimeout( ()=>closeMe&&closeMe({flag:false, time: Date.now()}), 100);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return { ref, isComponentVisible, setIsComponentVisible };
}