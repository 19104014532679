import { Button, Checkbox, FormControlLabel, Grid, ListItem, MenuItem, Modal, Paper, TextField } from "@material-ui/core";
import { useContext, useEffect, useRef, useState } from "react";
import { ArrowDown } from "react-feather";
import ReactToPrint from "react-to-print";

import TextEditor from "../editor/editor-text/TextEditor";
import "./interviews.scss";
import { API, fetchDataWoToken, saveDataWoToken } from "../../config/apiService";
import { useLocation, } from "react-router-dom";
import { SetPopupContext } from "../home/component/MainRouter";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { oneDark } from "react-syntax-highlighter/dist/cjs/styles/prism"
import rehypeRaw from "rehype-raw"
import ThumbnailDiagram from "../blogs/design/ThumbnailDiagram";
import Joblient from "../appbar/Jonlient";

const InterviewReport = (props)=>{
    const setPopup = useContext(SetPopupContext);
    const [feedback, setFeedback] = useState({rating: "", comment: ""});
    const [session, setSession] = useState();
    const [show, setShow] = useState(true)

    
    const location = useLocation();
    //console.log('props:', location);
    let urlParams = location.pathname.split(':');
    let _id;
    if(urlParams.length>0) {
   // console.log('urlParams:', urlParams);
        _id= atob(urlParams[1]);
    }
    useEffect(() => {
        getData();
    }, []);

    const getData = async()=>{
        let res = await fetchDataWoToken(`${API.report}?_id=${_id}`, "Interview Report");
        if (res) {
            console.log('res:', res);
            setSession(res);
            setFeedback({rating: res.feedback.rating, comment: res.feedback.comment});
            //console.log('report:', res)
        }
    }

    let reportRef = useRef();

    const saveSession = ()=>{
        let data = {
            _id : session._id,
            feedback : feedback,
        }
        console.log('save feedback:', data);
        saveDataWoToken(`${API.report}?_id=${_id}`, data,  "Interview Report",setPopup);
    }

    const getContent = ()=>
         <div>
            {session.assessments.map(item=>
            <div>
                {item.category ==='design' ? <div className="pagebreak"> </div> : <></>}
                    
                {item.category ==='design' ? <h4>Design Assessment<br/></h4> : <h4>Code Assessment<br/></h4>}
                <h5>Problem: <span>{item.title}</span></h5>
                <br></br>
                <div style={{}}>
                    <TextEditor style={{}}  toolbar={false} content={item.desc} readOnly={true}></TextEditor>
                </div>
                
                <div style={{marginTop:'1em'}}>
                    {item.category === 'code' ?
                    <div style={{overflow:'hidden', 'maxHeight':'100%'}}>
                        <h4>Solution:</h4>
                        <ReactMarkdown
                            children={item.code}
                            rehypePlugins={[rehypeRaw]}
                            components={{
                                code({ node, inline, className, children, ...props }) {
                                    const match = /language-(\w+)/.exec(className || "")
                                    return !inline && match ? (
                                        <SyntaxHighlighter
                                            {...props}
                                            children={String(children).replace(/\n$/, "")}
                                            style={oneDark}
                                            language={match[1]}
                                            PreTag="div"
                                        />
                                    ) : (
                                        <code {...props} className={className}>
                                            {children}
                                        </code>
                                    )
                                },
                            }}
                        />

                    </div>
                    :
                    <div>
                       {item.draw&&<ThumbnailDiagram item = {item} zoom = {0.5} height = '600px' width = '100%'/>}
                    </div> 
                    }
                </div>
            </div>)}
           </div>

    return session == null ? <div>Invalid url</div> : <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid container direction="row" style={{padding:'50px'}}>
                <Grid item xs={2} style={{marginBottom:'20px'}}>
                    <FormControlLabel control={<Checkbox checked={feedback.show} defaultChecked onChange={()=> setShow(!show)} />} label="Show Test Details" />
                </Grid>
                <Grid item xs={2} style={{marginBottom:'20px'}}>
                    <Button style={{background:'#DBEEEC', marginLeft:'5em', height:'2.6em', borderRadius:'20px'}} 
                        variant="contained" color="seconary" 
                        onClick={()=>{saveSession()}}
                        >
                            Save
                    </Button>
                </Grid>
                <Grid item xs={2} style={{marginBottom:'20px'}}>
                    <ReactToPrint
                        trigger={() => {
                            return (
                            <Button style={{background:'#DBEEEC', marginLeft:'1em', height:'2.6em', borderRadius:'20px'}} variant="contained" color="seconary" >
                                Print Report <ArrowDown />
                            </Button>
                            );
                        }}
                        content={() => reportRef.current}
                    />
                </Grid>
                <Grid item ref={reportRef} style={{
                        }}>

                        <Grid container direction="column" spacing={2} >
                            {/* <Grid item style={{marginBottom:'1em'}}>
                                <h1 style={{marginBottom:'0.5em'}}>Interview Report</h1>
                                <hr></hr>
                            </Grid> */}
                            <Grid item xs={12} style={{marginBottom:'1em'}}>
                                {/* <h2>Interview Report</h2>
                                <div style={{float:'right',marginTop:'-3em', margin:'auto'}}><Joblient></Joblient> </div> */}
                                <span style={{fontWeight:'600', fontSize:'1.8em'}}>Interview Report</span>
                                <div style={{float:'right', marginTop:'-1em'}}><Joblient ></Joblient> </div>
                                <hr></hr>
                            </Grid>

                            <Grid item style={{color:'gray', marginBottom:'0em'}}>
                                <span ><strong>Candidate:</strong> {session.email?session.email:localStorage.getItem('name')},&nbsp;&nbsp;  
                                <strong>Interviewed By:</strong>  {session.createdBy},&nbsp;&nbsp;&nbsp;&nbsp;
                                <strong>On:</strong> {new Date(session.createdOn).toLocaleDateString('en-GB', {
                                        day: 'numeric', month: 'short', year: 'numeric'})} </span>
                            </Grid>
    
                            <Grid item style={{color:'gray', marginBottom:'2em'}}>
                                <span ><strong>Interview Status:</strong> {session.assessments[0].status}&nbsp;&nbsp;</span>
                            </Grid>

                            <Grid item container direction="row">
                                <Grid item xs={3} >
                                    <TextField style={{width:'12em'}}
                                        select
                                        label="Overall Rating"
                                        variant="outlined"
                                        value = {feedback.rating}
                                        onChange={(event) => {
                                            let temp = {...feedback};
                                            temp.rating = event.target.value;
                                            setFeedback(temp)
                                        }}
                                        >
                                        <MenuItem id={1} value="ThumbsUp">Thumbs Up</MenuItem>
                                        <MenuItem id={2} value="ThumbsDown">Thumbs Down</MenuItem>
                                    </TextField>
                                </Grid>

                            </Grid>
                            <Grid item xs ={12}>
                                <span>Overall Comment</span>
                                <TextEditor toolbar={true} content={feedback.comment} contentChanged={(content)=> {let temp = {...feedback};
                                    temp.comment = content;
                                    setFeedback(temp)}} />
                            </Grid>
                            <Grid item xs ={12}>
                                { show && getContent()}
                            </Grid>
                        </Grid>
                </Grid>
            </Grid>
        </Grid>  
}

export default InterviewReport;