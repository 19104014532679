import { Grid, TextField, MenuItem } from "@material-ui/core";
import apiList from "../../config/apiList";
import FileUploadInput from "../login/lib/FileUploadInput";
import DescriptionIcon from "@material-ui/icons/Description";
import { useState } from "react";

const UploadCourse = ()=> {
    // const dispatch = useDispatch();
    // const setPopup = useContext(SetPopupContext);
    const [file, setFile] = useState("");
    const [dir, setDir] = useState("");
    const [sub, setSub] = useState("");

    return <Grid>
            <Grid item>
                <TextField
                    select
                    label="Directory"
                    variant="outlined"
                    value={dir}
                    onChange={(event) => {
                        setDir(event.target.value);
                    }}
                    >
                    <MenuItem value="concept">Concepts</MenuItem>
                    <MenuItem value="interview">Interviews</MenuItem>
                    <MenuItem value="algo">DS Algo</MenuItem>
                    <MenuItem value="ms">Microservices</MenuItem>
                    <MenuItem value="cloud">Cloud</MenuItem>
                    <MenuItem value="java">Java</MenuItem>
                    <MenuItem value="python">Python</MenuItem>
                    <MenuItem value="rest">Rest API</MenuItem>
                    <MenuItem value="sql">DB SQL</MenuItem>
                    <MenuItem value="spark">Spark</MenuItem>
                    <MenuItem value="course">Courses</MenuItem>
                </TextField>
                {/* <TextField
                    select
                    label="Sub Directory"
                    variant="outlined"
                    value={sub}
                    onChange={(event) => {
                        setSub(event.target.value);
                    }}
                    >
                    <MenuItem value="Select"></MenuItem>
                    <MenuItem value="Select"></MenuItem>
                </TextField> */}
            </Grid>
            <Grid item>
                <FileUploadInput
            
                label="Course (.pdf)"
                icon={<DescriptionIcon />}
                // value={files.resume}
                // onChange={(event) =>
                //   setFiles({
                //     ...files,
                //     resume: event.target.files[0],
                //   })
                // }
                uploadTo={ `${apiList.course1}:${dir}`}
                //uploadTo={ `${apiList.course1}:${dir}:${sub}`}
                //handleInput={handleInput}
                identifier={"course"}
                userId = {"admin"}
                file = {file}
                setFile = {setFile}
                />
            </Grid>
</Grid>
}

export default UploadCourse;