import { Grid, Switch } from "@material-ui/core";
import ManageInterview from "./ManageInterview";
import InterviewTemplate from "./InterviewTemplate";
import TabIntGroup from "./TabIntGroup";
import { useState } from "react";


const Interview = ()=> {
    const [view, setView] = useState('manage');
    

    //const getStyle = (v)=> (view == v) ? { color:'blue' } : {color:'black'};
    const getStyle = (v)=> {
        return {
        fontWeight:'bold',
        cursor:'pointer',
        fontSize:'1.1em',
        background: (view == v) ? '#FFA63D' : ''
        }
    }
    
    const setTabIndex = (v) =>{
        if(v===0) {
            setView('manage');
        } else if(v===1) {
            setView('template');
        }
    }
    
    return <div style={{padding:'4px'}}>
        <Grid container direction="column" style={{ padding:'8px', borderRadius:'8px',border:'1px solid #e0e0e0', borderColor:'#e0e0e0'}}>
            <Grid item container direction="row" spacing={2}>
                <TabIntGroup setTabIndex={setTabIndex}></TabIntGroup>
            </Grid>

            <Grid item>
                {   (view === 'manage') 
                    ?<ManageInterview/>
                    : <InterviewTemplate/>
                }
            </Grid>
        </Grid> 
    </div>
   
}

export default Interview;