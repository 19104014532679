//import { designs } from "./designs";

import { Grid, Paper } from "@material-ui/core"
// import { designs } from "../../front/designs"
import { DiagramComponent } from "@syncfusion/ej2-react-diagrams";
import { useContext, useEffect, useState } from "react";
import ViewDiagram from "./ViewDiagram";
import { API, fetchData } from "../../../config/apiService";
import { SetPopupContext } from "../../home/component/MainRouter";
import ThumbnailDiagram from "./ThumbnailDiagram";
import EditDiagram from "./EditDiagram";
import { designNew } from "./DefaultData";
import ViewDiagramPost from "./ViewDiagramPost";
import EditShape from "./EditShape";
import CreateIcon from '@mui/icons-material/Create';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const DesignBlog = ()=>{
    const setPopup = useContext(SetPopupContext);
    const [index, setIndex] = useState(0);
    const [current, setCurrent] = useState();
    const [edit, setEdit] = useState(null);
    const [designs, setDesigns] = useState([]);
    const location = useLocation();
    
    useEffect(() => {
        verifyBlog();
        getDesignData();
        return () => {}
    }, []);

    const verifyBlog = async()=>{
        const designTitle = location.pathname;
        console.log('blog URL:', designTitle);
    }

    const getDesignData = async()=>{
        let data = await fetchDesignData();
        console.log('design data load delay...');
        if (data === null) {
            setTimeout(()=> { 
                fetchDesignData();
            } , 400) 
        }
    }

    const fetchDesignData = async()=>{

        let desData = await fetchData(API.blogDesign, "Design Blog", null, null, setPopup);
       
        if (desData && desData.length > 0) {
            if( index < 0 && desData.length > 0) {
                createComment(desData[desData.length-1]);
            } else {
                createComment(desData[index]);
            }
            setDesigns(desData);
        }
        return desData;
    }

    const createComment = (dd)=> {
        let temp= {...dd};
        let comments = [...dd.comments];
        if(comments==null || comments.length==0) comments = [];
        comments.push({comment:''});
        temp.comments = comments;
        setCurrent(temp);
    }
    const newPost = ()=>{
        let newitem = designNew;
        setIndex(-1);
        setEdit({item: newitem, index: designs.length, mode: 'new'});
    }
    const improvePost = (post)=>{
        const { refresh } = post;
        if(post.item == null) { 
            if(refresh) {
                setEdit(null);
                getDesignData();
            }
            
            return;
        }
        console.log('data for edit or insert:', post);

        const {item} = post;
        const iindex = post.index;
        let newitem = {};
        newitem.title = item.title;
        newitem.postType = post.mode=='improve' ? 2 : item.postType; // 2=improved , 1=origional
        newitem.content = post.mode=='improve' ? '' : item.content;
        newitem.draw = item.draw;
        newitem._id = current._id; //item._id;
        newitem.count = current.repost.length;
        console.log(post.mode,':', newitem)
        setEdit({item: newitem, index: iindex, mode: post.mode == 'edit' ? 'edit' : 'improve'});
    }


return (
    <Grid  xs={12} container direction="row" spacing={2}  style={{marginTop:'1rem'}}>

        {/* {!edit&&<Grid xs={12} item style={{}}>
            <div style={{display:'grid', padding:'1em', borderRadius:'8px', border:'2px solid #e0e0e0', borderColor:'#e0e0e0'}}>

              
                <button type='button' className="menuNormal" style={{width:'130px', fontWeight:'bold', background:'#134c7e', color:'white'}} onClick={()=> {newPost()}}><CreateIcon/>&nbsp;New Post</button>
                <h3 style={{marginBottom:'0.5rem', marginTop:'0.5em'}}><strong>System Design Post</strong></h3> 
            </div>
            <div elevation={3} style={{display:'flex', flexDirection:'row', color:'rgb(0 197 230)', padding:'0.5rem', overflowX: 'scroll', userSelect: 'none'}}>
                {designs && designs.map((design, i) => (
                    <Paper elevation={10} >
                        <div key={i} style={{fontSize:20, width:'233px', padding:'0.4rem', marginRight:'0.3em', cursor:'pointer',borderRadius:'5px', background: (index==i)? 'rgb(107, 195, 255)': 'pink'}} onClick={()=>
                        {
                            if(i != index) {
                                console.log('design:', design)
                                setIndex(i);
                                createComment(design);
                            }
                        }}
                    >
                    <div>
                        <div className="avatar">
                            <span className="title--refresh" style={{padding:'0.2em',background:'#f2f2f2', fontSize:'0.7em'}}>{design.title}</span>
                        </div>
                    </div>

                    <ThumbnailDiagram item = {design} zoom = {0.2} height = '180px' width = '220px'/>
                        </div>
                    </Paper>
                            
                ))}
            </div>
        </Grid>} */}

        
        {/* EDIT POST */}
        { edit ?
                <Grid xs={12} item alignItems='center' alignContent ='center'>
                    <Grid>
                        <EditShape item = {{...edit.item}} api={API.blogDesign} zoom={0.8} index ={edit.index} mode={edit.mode} improvePost={improvePost}/>
                    </Grid>
                </Grid>


        // DISPLAY FULL POST AND ADD COMENT
        :  
            <Grid xs={9} item container>
            {/* { newPost == true ? renderNewPost() :renderSelectedPost() } */}

                <Grid xs={11} item>
                    {current && <ViewDiagram  item = {current} api = {API.blogDesign} zoom={0.8} improvePost={improvePost} style={{border:'2px', borderColor:'red'}}/>}
                </Grid>
                
                {current && current.repost && current.repost.length>0 && current.repost.map((post, key)=>{
                    let comments = [...post.comments];
                    if(comments==null || comments.length==0) comments = [];
                    comments.push({comment:''});
                    return <Grid key={key} xs={11} item container direction="row">
                        <Grid  key={key} xs={1} item></Grid>
                        <Grid  key={key} xs={10} item>
                            <h2 style={{marginTop:'2em', marginBottom:'2em'}}> USER REVIEW:</h2>
                            <ViewDiagram key={key}  item = {{...post, _id: current._id , title: current.title, comments: [...comments]}} api={API.blogDesign} zoom={0.8} improvePost={improvePost} index= {key} />
                        </Grid>
                    </Grid>
                    })
                }
            </Grid>
        }

        {/* <Grid xs={1} item container></Grid> */}


        {/* BLOG LISTVIEW DISPLAY */}
        
        {/* {!edit&&<Grid xs={3} item>
            <div style={{display:'inline-grid'}}>
                <button type='button' className="btnLow" style={{background:'rgb(107 195 255)', width:'100px' , fontWeight:'bold'}} onClick={()=> {newPost()}}>New Post</button>
                <h2 style={{marginBottom:'1.5rem'}}><strong>System Design Post</strong></h2> 
            </div>
            <Paper elevation={10} style={{color:'rgb(0 197 230)',padding:'0.5rem', height:'600px', overflowY: 'scroll', userSelect: 'none'}}>
            {designs && designs.map((design, i) => (
                <div key={i} style={{fontSize:20, padding:'0.2rem', marginBottom:'0.3em', cursor:'pointer',borderRadius:'5px', background: (index==i)? 'rgb(107, 195, 255)': 'pink'}} onClick={()=>
                {
                    if(i != index) {
                        console.log('design:', design)
                        setIndex(i);
                        createComment(design);
                    }
                }}
                >
                <div>
                    <div className="avatar">
                        <span className="title--refresh" style={{padding:'0.2em',background:'#f2f2f2', fontSize:'0.8em'}}>{design.title}</span>
                    </div>
                </div>

                <ThumbnailDiagram item = {design} zoom={0.2} height='180px'/>
            </div>
                        
            ))}
            </Paper>
        </Grid>} */}

        {!edit&&<Grid  item xs={3} style = {{float:'right', marginLeft:'auto',  height:'calc(100vh - 200px)', overflowY: 'scroll', justifyContent:'center' }}>
            <div style={{display:'grid', padding:'1em'}}>
                <button type='button' className="menuNormal" style={{width:'130px', fontWeight:'bold', background:'rgb(177 179 221)', color:'blue'}} onClick={()=> {newPost()}}><CreateIcon/>&nbsp;New Post</button>
                <h4 style = {{marginBottom:'0.5rem', marginTop:'0.5em'}}><strong>System Design Post</strong></h4> 
            </div>
            <div elevation = {3} style = {{display:'grid', color:'rgb(0 197 230)', paddingLeft:'1rem', userSelect: 'none'}}>
                {designs && designs.map((design, i) => (
                    <Paper key = {i} elevation = {10}>
                        <div  style = {{fontSize:20, padding:'0.4rem', marginBottom:'0.3em', cursor:'pointer', borderRadius:'5px', border: (index==i)? '10px solid rgb(107, 195, 255)': '10px solid pink'}} onClick={()=>
                        {
                            if(i != index) {
                                console.log('design:', design)
                                setIndex(i);
                                createComment(design);
                            }
                        }}
                    >
                    <div>
                        <div className="avatar">
                            <span className="title--refresh" style={{padding:'0.2em',background:'#f2f2f2', fontSize:'0.7em'}}>{design.title}</span>
                        </div>
                    </div>

                    <ThumbnailDiagram item = {design} zoom = {0.2} height = '180px' width = '100%'/>
                        </div>
                    </Paper>
                ))}
            </div>
        </Grid>}

    </Grid>
)
}

export default DesignBlog