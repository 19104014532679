import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Grid,
  Paper,
  Modal,
} from "@material-ui/core";


const IntLandingPage = (props)=>{

    return <Modal open={props.open}>
      <Paper
        style={{
            padding: "20px",
            outline: "none",
            minWidth: "30%",
            width:'40%',
            marginLeft:'30%',
            marginTop:'10%',
            //maxHeight:'70%',
            height:'50%'
       }}
      >
      <Grid container direction="column" spacing={2}>
            {/* <Grid item container direction="row" alignItems="flex-start" justifyContent="flex-end">
                <Grid item ><CloseIcon onClick={()=>{props.setOpen(false);}}/></Grid>
            </Grid> */}
            {/* <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ padding: "10px 50px", marginLeft:'16px' }}
                    onClick={() =>{ props.joinInterview('interviewer')}}
                >
                    Join As Interviewer
                </Button>
            </Grid> */}

            <Grid item>
                <h2>Start Your Interview</h2>
            </Grid>
            <Grid item>
                <hr></hr>
            </Grid>
            <Grid item style={{color:'red'}}>
                <span><strong>Please read the instructions carefully before starting the test</strong></span>
            </Grid>
            <Grid item>
                <span>Reset is not allowed during the test</span>
            </Grid>
            <Grid item>
                <span>Back button cannot be pressed</span>
            </Grid>
            <Grid item>
                <span>Code copying from external sources not allowed</span>
            </Grid>
            <Grid item>
                <span>Total Duration 60 Minutes</span>
            </Grid>

            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ padding: "10px 50px", marginLeft:'16px' }}
                    onClick={() =>{ props.joinInterview('candidate')}}
                >
                    Join Interview
                </Button>
            </Grid>
      </Grid>
    </Paper>
  </Modal>
}

export default IntLandingPage;