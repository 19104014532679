import { useEffect, useState } from "react";
import apiList from "../../../config/apiList";
import { fetchData, fetchDataWoToken } from "../../../config/apiService";
import { Grid } from "@material-ui/core";


const HtmlViewer = (props)=>{
    const {path} = props;
    const [git, setGit] = useState();
    
    useEffect(()=>{
        fetGitProblems();
    }, [path])


const fetGitProblems = async()=>{
    let found = false;

    if (localStorage.getItem(path)) {
        console.log('data available in local cache', path);
        try {
            let data = JSON.parse(localStorage.getItem(path));
            setGit(data);

            found = true;
        }catch(ex) {
            console.log('fetchGITData parse error:', ex);
            found = false;
        }
      
    } 
    if (found === false) {
        let url = `${apiList.git}?path=${path}`;
        console.log('url: ', url);
        //url = apiList.course + selected.source + '?' + q + '#page=5&toolbar=0&navpanes=0&scrollbar=0&view=fitwith'
        let data = await fetchData(url);
        setGit(data);
        localStorage.setItem(path, JSON.stringify(data));
    }
 }

return git&&<Grid style={{border:'1px solid lightgray' , padding:'3em'}}>
    <div dangerouslySetInnerHTML={{ __html: git }} />;
</Grid>

}
export default HtmlViewer;