import React, { useState, useContext } from "react";
import { Grid, Button, TextField, LinearProgress } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";


import { SetPopupContext } from "../../home/component/MainRouter";
import { handleUpload } from "./UploadFile";
import { isMobile } from "../../../utils/mobileUtil";

const FileUploadInput = (props) => {
  const setPopup = useContext(SetPopupContext);

  const { uploadTo, identifier, handleInput, userId, file, setFile, text } = props;
  const getName =  ()=>{
    if(!file)
      return file;
    else  if ((typeof file)== "string") {
       let parts = file.split('_');
       if(parts.length>2) {
        return parts[2];
       }
    } else return file;
  }
  
    return ( <><div className="ml-4">
                
                    <div style={{display:'inline-flex', border:'2px dotted gray', alignItems:'center', padding:'4px'}}>
                    <div className="text-[14px]">{text}:&nbsp;</div>
                    {
                      ((typeof file)== "string") ? 
                        <div style={{display:'flex', right:'1em'}}>
                          <input 
                              type="file"
                              style={{width:'6.4em'}}
                              onChange={(event) => {
                              setFile(event.target.files[0]);
                            }}
                          />
                          <a href={file} target="_blank" style={{fontSize:'1em',color:'rgb(27, 73, 234)', cursor: 'pointer',background:'none', marginLeft:'1em', display:'inline-flex', textDecoration: 'none'}} >{getName()}</a> 
                        </div>
                      : <div>
                          <input 
                                type="file"
                                accept=".doc, .docx, .pdf"
                                style={{background:'#dee0e3'}}
                                onChange={(event) => {
                                setFile(event.target.files[0]);
                              }}
                          />   
                          <CloudUpload  className="text-blue-500 ml-2 mr-1 scale-150" onClick = {()=> handleUpload(uploadTo, file, setPopup, 'Resume Upload ')}/>
                          </div>       
                      }
                    </div>

            </div>
          {/* <div style={{marginTop:'-2em'}}>(Optional)</div> */}
          </>
          )
      
      //   <Grid style={{display:'inline-flex', marginRight:'0.2em', padding:'10px', height:'3em', border:'1px solid lightgray' , borderRadius:'6px'}}>
      //     <div style={{marginTop:'0.3em', marginRight:'2em', height:'1.4em', color:'#1d0707', fontWeight:'bold' }}>Upload Profile</div>
      //     <Button 
      //       variant="contained"
      //       color="primary"
      //       component="label"
      //       style={{width: "2em", height:'2em' ,borderRadius:'20px' , background:'#44c6f4', color:'#e6d0d0'}}
      //     >
      //       &nbsp;{props.icon} 
      //       <input 
      //         type="file"
      //         style={{ background:'red', width:'0em' }}
      //         onChange={(event) => {
      //           setFile(event.target.files[0]);
      //         }}
      //       >
              
      //       </input>
           
      //     </Button>
      //     {
      //       ((typeof file)== "string") ?
      //         <a href={file} target="_blank" style={{display:'inline-flex', textAlign:'center', minWidth:'10em',marginLeft:'2em', marginTop:'0.2em'}} >Resume</a> 
      //      :
      //       <TextField
      //         label={props.label}
      //         value={file ? file.name || "" : ""}
      //         InputProps={{
      //           readOnly: true,
      //         }}
      //         variant="outlined"
      //         style={{marginLeft:'1px',minWidth:'10em', width:'10vw'}}
      //       />
      //     }
      //      <Button
      //       variant="contained"
      //       style={{height:'2em' ,borderRadius:'20px',marginLeft:'0rem', background:'#44c6f4', color:'#e6d0d0'}}
      //       onClick = {()=> handleUpload(uploadTo, file, setPopup, 'Resume Upload ')}
      //     >
      //     <CloudUpload color="primary"/>
      //     </Button>
      // </Grid>
      
  //);
};

export default FileUploadInput;
