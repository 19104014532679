import { API, saveData } from "../config/apiService";
import ReactMarkdown from "react-markdown"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { oneDark, oneLight, prism } from "react-syntax-highlighter/dist/cjs/styles/prism"
import rehypeRaw from "rehype-raw"
import { isMobile } from "./mobileUtil";


export const saveAssessNRank = async (data, group, category, setPopup) => {
    data.category = category;
    data.group = group;
    let res = await saveData(API.assess, data, group, setPopup);

    if (res !== false) { // save points
        console.log(group, ' data saved successfully', res);

        console.log('saving leaderInfo collected', res.data.assessId);
        let name = data.title;
        if (data.id) {
            name = data.title + " Set-" + data.id.replace(data.title, "").replace(/[-]/gm, "");
        }
        let setName = name;
        let leaderInfo = { assessId: res.data.assessId, title: setName, group: group, category: category, coins: data.coins };

        console.log('leaderInfo to be saved:', leaderInfo);
        saveData(API.leader, leaderInfo, "Leader");

    } else {
        console.log('Error in saving leader board data')
    }

}

const markdownResetStyle = {
    margin: 0,
    padding: 0,
    background: 'none',
    border: 'none',
    fontSize: 'medium', // Reset to the user's default browser font size
    fontWeight: 'normal',
    lineHeight: 'normal',
    color: 'black', // or any color you want as default text color
    textAlign: 'left',
    listStyle: 'none',
    boxShadow: 'none', // Reset box-shadow
    textShadow: 'none'  // Reset text-shadow
};

export const renderMD = (md) =>
    <div className="bg-teal-200 qzmd-body quiz-section question-section" style={markdownResetStyle}>
        <ReactMarkdown
            children={md}
            rehypePlugins={[rehypeRaw]}
            components={{
                code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "")
                    return !inline && match ? (
                        <SyntaxHighlighter
                            {...props}
                            children={String(children).replace(/\n$/, "")}
                            style={prism}
                            language={match[1]}
                            PreTag="div"
                        />
                    ) : (
                        <code {...props} className=''>
                            {children}
                        </code>
                    )
                },
            }}
        />
    </div>