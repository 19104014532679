import { Grid } from "@material-ui/core";
import { API } from "../../config/apiService";

const ResumeViewer = ({email}) =>{
    const file = `${API.bulkRes}?email=${email}#page=5&toolbar=0&navpanes=0&scrollbar=0&view=fitwidth`;

    return (<Grid container direction="column">
        <Grid item xs={12}></Grid>
         <iframe scrolling="no" frameBorder="0" allowTransparency="true" width="100%" height="1600vh" src={file} allowFullScreen />
    </Grid>)
}

export default ResumeViewer;