import { useContext, useState } from "react";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Profile from "../login/Profile";
import LeaderBoard from "../leaderboard/LeaderBord";
import { SetPopupContext } from "../home/component/MainRouter";
import { isMobile } from "../../utils/mobileUtil";
import { showErrorToast } from "../../utils/toastMsg";
import ResumeViewer from "../login/ResumeViewer";

const { Grid} = require("@material-ui/core")


const ProfilePanel = (props)=>{
    let { userId, mode , email} = props;
    const [query, setQuery] = useState(props.query);
    const [view, setView] = useState(false);
    const [test, setRank] = useState(false);
    const [resume, setResume] = useState(false);

    const setPopup = useContext(SetPopupContext);
    //console.log('props:::', email, props, query);

    const setUnlock = ()=>{
        let usrType = localStorage.getItem('type');
        if (usrType==='recruiter') {
            setQuery(`${props.query}&unlock=${true}`);
            setView(!view); 
            setRank(false)
        } else {
            setPopup({
                open: true,
                severity: "error",
                message: 'Only the recruiter is allowed for this action',
            });
        }
    }
    const handleProfileView = ()=>{
        if (userId) {
            setView(!view); 
            setRank(false);
    
        } else {
            showErrorToast("User detail profile does not exist");
        }
    }

    const handleProfileRank = ()=>{
        if (userId) {
            setRank(!test); 
            setView(false);
        } else {
            showErrorToast("User detail profile does not exist");
        }
    }

    const handleResumeView = ()=>{
        if (email.indexOf('#########') > -1) { // hidden email's resume cannot view
            showErrorToast('To view more candidate profiles, use the recruiter login and set the viewing quota', 3000)
        } else {
            setResume(!resume);
        }
        console.log('resume:',resume)
    }


    return (<Grid container direction="column">
        <Grid item xs={12}>
            <div style={{ display:'flex', cursor:'pointer', float:'left'}}>
                <div style={{display:'flex',  marginLeft:'1em', background:'rgb(15 63 80)', padding:'0px 0px 0 20px', borderRadius:'20px' }} onClick={()=>{setUnlock()}}>
                    {mode!=='applicant'&&<div style={{marginTop:'0.5em', width:'7em', height:'2em',  color:'white'}} >Unlock Profile</div> }
                </div>
            </div>

            <div style={{ display:'flex', cursor:'pointer', float:'right', marginRight:'2em'}}>
                { userId ? <>
                    <div style={{display:'flex',marginBottom:'0.2em', background:'#a670ff', padding:'0px 0px 0 20px', borderRadius:'20px'}} onClick={()=>{handleProfileView()}}>
                            <div style={{marginTop:'0.4em', color:'white', padding:'3px 3px 8px 3px'} } >View Profile</div> 
                            <IconButton style={{marginTop:'0em', marginLeft:'0em', width:'2.5em'}}
                                    aria-label="expand row"
                                    size="small" > 
                                {view ? <KeyboardArrowUpIcon/>:<KeyboardArrowDownIcon/>}
                            </IconButton>
                    </div> 
        
                    <div style={{display:'flex',marginBottom:'0.2em', marginLeft:'10px', background:'#44c6f4', padding:'0px 0px 0 20px', borderRadius:'20px'}} onClick={()=>{handleProfileRank()}}>
                            <div style={{marginTop:'0.4em', color:'white', padding:'3px 3px 8px 3px'} } >View Rank</div> 
                            <IconButton style={{marginTop:'0em', marginLeft:'0em', width:'2.5em'}}
                                    aria-label="expand row"
                                    size="small" > 
                                {test ?  <KeyboardArrowUpIcon/>:<KeyboardArrowDownIcon/>}
                            </IconButton>
                    </div>
                </>  :
                <div style={{display:'flex',marginBottom:'0.2em', background:'#46989f', padding:'0px 0px 0 20px', borderRadius:'20px'}} onClick={()=>{handleResumeView()}}>
                            <div style={{marginTop:'0.4em', color:'white', padding:'3px 3px 8px 3px'} } >View Resume</div> 
                            <IconButton style={{marginTop:'0em', marginLeft:'0em', width:'2.5em'}}
                                    aria-label="expand row"
                                    size="small" > 
                                {resume ? <KeyboardArrowUpIcon/>:<KeyboardArrowDownIcon/>}
                            </IconButton>
                    </div>
                }

            </div>
        </Grid>
        
        {view && <Grid item xs={12}>
                <Profile readOnly={true}  userId={userId} mode={mode}></Profile>
            </Grid>
        }
        {test && <Grid item xs={12}>
                <LeaderBoard userId = {userId} ></LeaderBoard>
            </Grid>
        }
        {resume && <Grid item xs={12}>
                <ResumeViewer email = {email} ></ResumeViewer>
            </Grid>
        }
    </Grid>

    )
}

export default ProfilePanel;