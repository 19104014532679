import { Button } from '@material-ui/core'
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React, { useState } from 'react'
import {useParams} from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import TestCompletedModal from './TestCompletedModal';
import { useDispatch, useSelector } from 'react-redux';
import { API, submitTestResult } from '../../config/apiService';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const Footer = ({ answeredCount }) => {
  const [open, setOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const { testId, code } = useParams();
  const questionData = useSelector((state)=> state.questionReducer.questions);

  const handleClose = () => {
    setOpen(false);
  };
  
  const calculateScore=()=>{
    let score=0;
    questionData?.forEach(que =>{
      if(que.check !== null && que._ps === que.check) score++;
    })
    return score;
  }
  const handleConfirm = async () => {
    // Add your submission logic here
    const requestBody = {
      uniqueCode: code,
      score: calculateScore(),
    };
    const api = `${API.test}/submit/${testId}`;
    await submitTestResult(api, {requestBody,testId});
    setOpen(false);
    setConfirmed(true);
  };

const submitTest = () => {
  setOpen(true);
}

return (
  <footer className="w-full h-14 bg-teal-100 flex items-center px-[10%] justify-end" style={{ bottom: 0, position: 'absolute', zIndex: 2 }}>
    <span>{answeredCount}/{questionData?.length}</span>
    <div className='w-[30%] p-2'>
      <LinearProgressWithLabel value={answeredCount * 100 / questionData?.length} />
    </div>
    <Button onClick={submitTest} style={{ background: '#16a34a', color: 'white', marginLeft: '2em' }}>Submit</Button>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Submit Test"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to submit the test?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
    <TestCompletedModal completed={confirmed} />
  </footer>
)
}

export default Footer