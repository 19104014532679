import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography,
  Modal,
  Slider,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Checkbox,
  InputBase,
  Radio,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { SetPopupContext } from "../home/component/MainRouter";

import apiList from "../../config/apiList";
import { userType } from "../login/lib/isAuth";

import {fetchJobs, setSearchJobQuery, setSearchProfileQuery} from "../../state/action-creators/job/index"
import "./style.scss";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import ProfileSortOption from "../profilesearch/ProfileSortOption";
import { isMobile } from "../../utils/mobileUtil";
import { showErrorToast } from "../../utils/toastMsg";



const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: "none",
  },
  body: {
    height: "inherit",
  },
  button: {
    width: "100%",
    height: "100%",
  },
  jobTileOuter: {
    padding: "30px",
    margin: "20px 0",
    boxSizing: "border-box",
    width: "100%",
  },
  popupDialog: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const JobTile = (props) => {
  const classes = useStyles();
  const { job } = props;
  const setPopup = useContext(SetPopupContext);

  const [open, setOpen] = useState(false);
  const [sop, setSop] = useState("");

  const handleClose = () => {
    setOpen(false);
    setSop("");
  };

  const handleApply = () => {
    console.log(job._id);
    console.log(sop);
    axios
      .post(
        `${apiList.jobPosting}/${job._id}/applications`,
        {
          sop: sop,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        getExtJobs();
        setPopup({
          open: true,
          severity: "success",
          message: response.data.message,
        });
        handleClose();
      })
      .catch((err) => {
        console.log(err.response);
        setPopup({
          open: true,
          severity: "error",
          message: err.response.data.message,
        });
        handleClose();
      });
  };

  const getExtJobs = async (search)=>{
    var config = {
      method: 'get',
      url: 'https://www.ziprecruiter.in/jobs/search?d=&l=Bengaluru%2C+India&lat=12.96&long=77.59&page=2&q=Java+Developer',
      headers: { 
        'Cookie': '_jobboard_session=cc46816289aa29959074657444c830cf; __cf_bm=.NfC7RJiixp05ZMhkEEDLkIcFB3jDdvwr3RpyDwybJU-1686289692-0-Ab8SFDBIx4zCbCyCyah+/ad23bLgMUlVfSrmrNgsocJapntwPM+aynLU/Kyr7w1AVN1n+tDHGzFna9Tu1at4l9U='
      }
    };
    console.log('getExtJobs invoked1:')
    await axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const deadline = new Date(job.deadline).toLocaleDateString();

  return (
    <Paper className={classes.jobTileOuter} elevation={3}>
      <Grid container>
        <Grid container item xs={9} spacing={1} direction="column">
          <Grid item>
            <Typography variant="h5">{job.title}</Typography>
          </Grid>
          <Grid item>
            <Rating value={job.rating !== -1 ? job.rating : null} readOnly />
          </Grid>
          <Grid item>Role : {job.jobType}</Grid>
          <Grid item>Salary : &#8377; {job.salary} per month</Grid>
          <Grid item>
            Duration :{" "}
            {job.duration !== 0 ? `${job.duration} month` : `Flexible`}
          </Grid>
          <Grid item>Posted By : {job.recruiter.name}</Grid>
          <Grid item>Application Deadline : {deadline}</Grid>

          <Grid item>
            {job.skillsets.map((skill) => (
              <Chip label={skill} style={{ marginRight: "2px" }} />
            ))}
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              setOpen(true);
            }}
            disabled={userType() === "recruiter"}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
      <Modal open={open} onClose={handleClose} className={classes.popupDialog}>
        <Paper
          style={{
            padding: "20px",
            outline: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minWidth: "50%",
            alignItems: "center",
          }}
        >
          <TextField 
            label="Write SOP (upto 250 words)"
            multiline
            rows={8}
            style={{ width: "100%", marginBottom: "30px" , border:'5px'}}
            variant="outlined"
            value={sop}
            onChange={(event) => {
              if (
                event.target.value.split(" ").filter(function (n) {
                  return n != "";
                }).length <= 250
              ) {
                setSop(event.target.value);
              }
            }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ padding: "10px 50px" }}
            onClick={() => handleApply()}
          >
            Submit
          </Button>
        </Paper>
      </Modal>
    </Paper>
  );
};

const FilterPopup = (props) => {
  const classes = useStyles();
  const { open, handleClose, searchOptions, setSearchOptions, getData } = props;
  return (
    <Modal open={open} onClose={handleClose} className={classes.popupDialog}>
      <Paper
        style={{
          padding: "50px",
          outline: "none",
          minWidth: "50%",
        }}
      >
        <Grid container direction="column" alignItems="center" spacing={3}>
          <Grid container item alignItems="center">
            <Grid item xs={3}>
              Job Type
            </Grid>
            <Grid
              container
              item
              xs={9}
              justify="space-around"
              // alignItems="center"
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="fullTime"
                      checked={searchOptions.jobType.fullTime}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          jobType: {
                            ...searchOptions.jobType,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Full Time"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="partTime"
                      checked={searchOptions.jobType.partTime}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          jobType: {
                            ...searchOptions.jobType,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Part Time"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="wfh"
                      checked={searchOptions.jobType.wfh}
                      onChange={(event) => {
                        setSearchOptions({
                          ...searchOptions,
                          jobType: {
                            ...searchOptions.jobType,
                            [event.target.name]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label="Work From Home"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item alignItems="center">
            <Grid item xs={3}>
              Salary
            </Grid>
            <Grid item xs={9}>
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => {
                  return value * (100000 / 100);
                }}
                marks={[
                  { value: 0, label: "0" },
                  { value: 100, label: "100000" },
                ]}
                value={searchOptions.salary}
                onChange={(event, value) =>
                  setSearchOptions({
                    ...searchOptions,
                    salary: value,
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid container item alignItems="center">
            <Grid item xs={3}>
              Duration
            </Grid>
            <Grid item xs={9}>
              <TextField
                select
                label="Duration"
                variant="outlined"
                fullwidth = "true"
                value={searchOptions.duration}
                onChange={(event) =>
                  setSearchOptions({
                    ...searchOptions,
                    duration: event.target.value,
                  })
                }
              >
                <MenuItem value="0">All</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="7">7</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid container item alignItems="center">
            <Grid item xs={3}>
              Sort
            </Grid>
            <Grid item container direction="row" xs={9}>
              <Grid
                item
                container
                xs={4}
                justify="space-around"
                alignItems="center"
                style={{ border: "1px solid #D1D1D1", borderRadius: "5px" }}
              >
                <Grid item>
                  <Checkbox
                    name="salary"
                    checked={searchOptions.sort.salary.status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          salary: {
                            ...searchOptions.sort.salary,
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="salary"
                  />
                </Grid>
                <Grid item>
                  <label for="salary">
                    <Typography>Salary</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort.salary.status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          salary: {
                            ...searchOptions.sort.salary,
                            desc: !searchOptions.sort.salary.desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort.salary.desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={4}
                justify="space-around"
                alignItems="center"
                style={{ border: "1px solid #D1D1D1", borderRadius: "5px" }}
              >
                <Grid item>
                  <Checkbox
                    name="duration"
                    checked={searchOptions.sort.duration.status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          duration: {
                            ...searchOptions.sort.duration,
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="duration"
                  />
                </Grid>
                <Grid item>
                  <label for="duration">
                    <Typography>Duration</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort.duration.status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          duration: {
                            ...searchOptions.sort.duration,
                            desc: !searchOptions.sort.duration.desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort.duration.desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={4}
                justify="space-around"
                alignItems="center"
                style={{ border: "1px solid #D1D1D1", borderRadius: "5px" }}
              >
                <Grid item>
                  <Checkbox
                    name="rating"
                    checked={searchOptions.sort.rating.status}
                    onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          rating: {
                            ...searchOptions.sort.rating,
                            status: event.target.checked,
                          },
                        },
                      })
                    }
                    id="rating"
                  />
                </Grid>
                <Grid item>
                  <label for="rating">
                    <Typography>Rating</Typography>
                  </label>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!searchOptions.sort.rating.status}
                    onClick={() => {
                      setSearchOptions({
                        ...searchOptions,
                        sort: {
                          ...searchOptions.sort,
                          rating: {
                            ...searchOptions.sort.rating,
                            desc: !searchOptions.sort.rating.desc,
                          },
                        },
                      });
                    }}
                  >
                    {searchOptions.sort.rating.desc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ padding: "10px 50px" }}
              onClick={() => getData()}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

const JobSearchBar = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const iconSize =  '32px';// isMobile ? '40px' : '30px';
  const [alignment, setAlignment] = React.useState('job');
  const [select, setSelect] = useState(-1);

  const handleClick = (location) => {
    console.log(location);
    history.push(location);
  };

  const [location, setLocation] = useState(1);
  // const [jobs, setJobs] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchProfOptions, setSearchProfOptions] = useState({});

  const [searchOptions, setSearchOptions] = useState({
    query: "",
    location: "",
    jobType: {
      fullTime: false,
      partTime: false,
      wfh: false,
    },
    salary: [0, 100],
    duration: "0",
    sort: {
      salary: {
        status: false,
        desc: false,
      },
      duration: {
        status: false,
        desc: false,
      },
      rating: {
        status: false,
        desc: false,
      },
    },
  });

  const setPopup = useContext(SetPopupContext);
  useEffect(() => {
    //getData();
  }, []);

  const handleChange = (
    event,
    newAlignment
  ) => {
    if (newAlignment !== alignment) {
      setAlignment(newAlignment)
      setOptions('search', "");

      setSearchOptions({
        ...searchOptions,
        query: "",
      })
    }
  };

  const getData = () => {
    getDataExt();
    return;
  };

  const getDataExt = () => {

    //dispatch(fetchJobs(address));
    let query = `q=${searchOptions.query}`
    if(searchOptions.location) {
      query = `${query}&l=${searchOptions.location}`;
    }
  console.log("query123:", query);

  dispatch(setSearchJobQuery(query));
  };

  const classes = useStyles();

  const setOptions = (key, value) => {
    let temp = {...searchProfOptions}
    temp[key] = value;
    temp['touched'] = true;
    setSearchProfOptions(temp);
  }

  const handleSearch = async()=>{
    let query = "";

    if(!searchProfOptions.search) {
      showErrorToast("Please input search keyword, ex. java");
      return;
    }

    if(searchProfOptions.search) {
      query = `search=${searchProfOptions.search}`;
    }
    
    if(searchProfOptions.sort) {
      query = `${query}&sort=${searchProfOptions.sort}`;
    }
    if(searchProfOptions.exp) {
      query = `${query}&exp=${searchProfOptions.exp}`;
    }

    let temp = {...searchProfOptions}
    temp['touched'] = null;
    let q = {query: query, search: searchProfOptions.search}

    console.log('q=========', q)
    dispatch(setSearchProfileQuery(q));
    handleClick("/profilesearch");
  }

  return (
    <>
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        style={{background:'rgba(240, 237, 237, 0.58)', padding:'5px 10px 10px 10px'}}
      >
      <Grid style={{display:'flex', justifyContent:'center'}}>
          <ToggleButtonGroup style={{marginTop:'0px', height:'23px', borderRadius:'6%'}}
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
              >
              <ToggleButton value="job">Job</ToggleButton>
              <ToggleButton value="profile">Profile</ToggleButton>
          </ToggleButtonGroup>
      </Grid>

        { alignment==='job' ?
         <Grid
          item
          container
          direction="column"
          alignItems="center"
        > 
          <Grid item xs>
            <TextField className="search"
              variant="outlined"
              margin="normal"
              required
              fullwidth = "true"
              disableunderline="false"
              value = {searchOptions.query}

              sx= {{"& .MuiOutlinedInput-root.Mui-disabled":{"& > fieldset": {border: '2px solid red'}},
                                  
                  }}

              onChange={(event) =>
                setSearchOptions({
                  ...searchOptions,
                  query: event.target.value,
                })
              }
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  getData();
                  handleClick("/jobs");
                }
              }}
              //className={classes.textField}
              placeholder="Job Search"
              InputProps={{
                style: {fontSize:`${isMobile?'10px':'14px'}`},
                // startAdornment: (
                //   <InputAdornment position="start">
                //     <IconButton disabled={true} onClick={() => setFilterOpen(true)}>
                //       <FilterListIcon/>
                //     </IconButton>
                //   </InputAdornment>
                // ),
                endAdornment: (
                  <InputAdornment position="start"> 
                    <div key={1} style={{color:'lightgray', marginLeft:'-1.8em', fontSize:'10px', marginRight:'0.5em'}}>|</div>
                    <TextField key={"loc"} style={{marginTop:'5px'}}  variant="standard" disableunderline="false" sx= {{"& .MuiOutlinedInput-root.Mui-disabled":{"& > fieldset": {border: '2px solid red'}}}}
                      placeholder="Location"
                      InputProps={{
                        //style: {fontSize:`${isMobile?'36px':''}`}
                      }}                      
                      onChange={(event) =>
                      setSearchOptions({
                        ...searchOptions,
                        location: event.target.value,
                      })}
                    >
                    </TextField>
                    <IconButton key={2} style={{marginLeft:'0.5em', marginRight:'-1em'}} onClick={() => {getData(); handleClick("/jobs");}}>
                      <SearchIcon/>
                    </IconButton>
                  </InputAdornment>
                ),
                
                classes:{notchedOutline:classes.noBorder}
            }}
          />
          <Grid>
            <a onClick={() => {
                let query = `q=hotjobs`
                dispatch(setSearchJobQuery(query));
              handleClick("/jobs")
            }} 
            style={{fontSize:'12px', float:'right',cursor:'pointer',  padding:'0 0.6em', color:'#1700ff', borderRadius:'10px', marginRight:'1em', border:'2px solid red'}}>Hot Job</a>
                      <a  onClick={() => {
                let query = `q=internjobs`
                dispatch(setSearchJobQuery(query));
              handleClick("/jobs")
            }} 
            style={{fontSize:'12px', float:'right', cursor:'pointer', padding:'0 0.6em', marginRight:'1em', color:'#1700ff', borderRadius:'10px', border:'2px solid red'}}>Intern Job</a>

          </Grid>
        </Grid>
        </Grid> 
        :
        <Grid
        item
        container
        direction="column"
        alignItems="center"
        > 
          <Grid item xs={12} style={{display:'flex', justifyContent:'center'}}>
            <TextField className="search"
            variant="outlined"
            margin="normal"
            required
            disableunderline="false"
            value = {searchProfOptions.search}
            
            sx= {{"& .MuiOutlinedInput-root.Mui-disabled":{"& > fieldset": {border: '2px solid red'}}}}
            onChange={(event) =>
              setOptions('search',  event.target.value)
            }
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                handleSearch();
              }
            }}
            
            placeholder="Profile Search"
            InputProps={{
              style:{fontSize:`${isMobile?'12px':'14px'}`},

              startAdornment: (
                <InputAdornment position="start">
                  {/* <IconButton key={1} onClick={() => {}}>
                    <FilterListIcon />
                  </IconButton> */}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <ProfileSortOption key={1}  setOptions = {setOptions}></ProfileSortOption>
                  <IconButton key={2} style={{marginRight:'-1em'}} onClick={() => {handleSearch()}}>
                    <SearchIcon/>
                  </IconButton>
                </InputAdornment>
              ),
              classes:{notchedOutline:classes.noBorder}
              }}
            />
          </Grid>
          <Grid style={{marginTop:'-6px', maxHeight:'27px'}}>
              <Grid item container direction="row" spacing={0} alignItems="center">
                  <Grid item >
                    <FormControlLabel
                        control={
                        <Radio style={{fontSize:'5px'}}
                            name="all"
                            checked={ (select == -1)}
                            onChange={(event) => {
                                setSelect(-1);
                                setOptions('exp',  -1)
                            }}
                        />
                        }
                        label="All"
                    />
                    <FormControlLabel
                        control={
                        <Radio style={{fontSize:'5px'}}
                            name="fresher"
                            checked={ (select == 0)}
                            onChange={(event) => {
                                setSelect(0);
                                setOptions('exp',  0)
                            }}
                        />
                        }
                        label= {`${isMobile ? '0' : 'Fresher'}`}
                    />
                    <FormControlLabel
                        control={
                        <Radio
                            name="exp2"
                            checked={ (select == 1)}
                            onChange={(event) => {
                                setSelect(1);
                                setOptions('exp',  1)
                            }}
                        />
                        }
                        label= {`${isMobile ? '1+' : 'Exp 1+'}`}
                    />

                    <FormControlLabel
                        control={
                        <Radio
                            name="exp6"
                            checked={ (select == 5)}
                            onChange={(event) => {
                                setSelect(5);
                                setOptions('exp',  5)
                            }}
                        />
                        }
                        label= {`${isMobile ? '5+' : 'Exp 5+'}`}
                    />

                    <FormControlLabel
                        control={
                        <Radio
                            name="exp11"
                            checked={ (select == 10)}
                            onChange={(event) => {
                                setSelect(10);
                                setOptions('exp',  10)
                            }}
                        />
                        }
                        label= {`${isMobile ? '10+' : 'Exp 10+'}`}
                    />

                    <FormControlLabel
                        control={
                        <Radio
                            name="exp16"
                            checked={ (select == 15)}
                            onChange={(event) => {
                                setSelect(15);
                                setOptions('exp',  15)
                            }}
                        />
                        }
                        label= {`${isMobile ? '15+' : 'Exp 15+'}`}
                    />
                  </Grid>
              </Grid>
          </Grid>
        </Grid>
        }


      </Grid>
      {/* <FilterPopup
        open={filterOpen}
        searchOptions={searchOptions}
        setSearchOptions={setSearchOptions}
        handleClose={() => {setFilterOpen(false);}}
        getData={() => {
          getData();
          setFilterOpen(false);
        }}
      /> */}
    </>
  );
};

export default JobSearchBar;
