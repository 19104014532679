import {
    Grid,
    Typography,
    Button,
    Modal,
} from "@material-ui/core";
import { useEffect, useState,useContext } from "react";
import { isMobile } from "../../../utils/mobileUtil";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import QuizTestCatalog from "../testCatalog/QuizTestCatalog";
import { SetPopupContext } from "../../home/component/MainRouter";

const AssessTestLink = (props) => {
    let history = useHistory();
    const setPopup = useContext(SetPopupContext);
    const shortlistedCandidate = useSelector(state => state.shortlistReducer.shortlistedCandidates);
    const profiles = props.profiles;
    const [list, setList] = useState();
    const [modal, setModal] = useState(false);
    const [totalSelected, setTotalSelected] = useState(0);
    const [candidates, setCandidates] = useState([]);

    // console.log(shortlistedCandidate)

    useEffect(() => {
        initList();
        shortlistedCandidate.forEach(element => {
            return element.selected = false;
        });
        if (candidates.length === 0)
            setCandidates(shortlistedCandidate);
    }, [props.profiles])

    const initList = () => {
        if (!profiles) return;

        let temp = [...profiles];
        let clist = temp.map((item) => {
            let obj = {};
            obj.name = item.name;
            obj.mobile = item.mobile;
            obj.email = item.email;
            obj.select = true;

            return obj;
        })
        setList(clist);
    }

    const selectAll = (e) => {
        const updatedCandidates = candidates.map(candidate => ({
            ...candidate,
            selected: e.target.checked
        }));
        setCandidates(updatedCandidates);
        setTotalSelected(e.target.checked ? candidates.length : 0)
    }

    const selectSpecific = (index) => {
        if (candidates[index].selected)
            setTotalSelected(totalSelected - 1);
        else
            setTotalSelected(totalSelected + 1);

        const updatedCandidates = [...candidates];
        updatedCandidates[index].selected = !updatedCandidates[index].selected;
        setCandidates(updatedCandidates);
    }

    const selectTestType = (location) => {
        const selectedCandidates = candidates.filter(candidate => candidate.selected === true);
        console.log(selectedCandidates);
        if(selectedCandidates.length===0) {
            setPopup({
                open: true,
                severity: "error",
                message: "Please select atleast 1 candidate to create a test.",
            });
            return;
        }
        // history.push('skilltest');
        setModal(true);
    }

    const handleRoute = (location) => {
        // console.log(location);
        // setMenu(false);
    };


    return <Grid container direction="row">
        <Grid item xs={12}>
            <Typography style={{ display: 'flex', justifyContent: 'center', marginBottom: '0.4em' }} variant={`${isMobile ? 'h3' : 'h5'}`}>Create Test Link</Typography>
            <hr></hr>
        </Grid>
        <Modal open={modal} className="flex justify-between items-center" >
            <QuizTestCatalog width={'40%'} height={'80%'} setModal={setModal} />
        </Modal>
        <Grid item xs={8} style={{ padding: '0.5em', backgroundColor: '#E8E9EB', borderRadius: '4px' }}>
            <div className="flex justify-between">
                <div>Select All <input checked={totalSelected === candidates.length} onChange={(e) => selectAll(e)} type="checkbox"></input></div>
                <div>{totalSelected} selected</div>
            </div>
            {
                candidates && candidates.map((item, key) => {
                    return <div style={{ display: 'inline-flex' }}>
                        <input type="checkbox" onChange={() => selectSpecific(key)} checked={item.selected} />
                        <div style={{ marginLeft: '0.2em', padding: '0.2em', borderRadius: '4px', border: '1px solid lightgray', width: '220px' }}>
                            <div> <strong>Name:&nbsp;</strong>  {item.name} </div>
                            <div> <strong>Mobile:&nbsp;</strong>  {item.mobile} </div>
                            <div> <strong>Email:&nbsp;</strong>  {item.email} </div>
                        </div>
                    </div>

                })
            }

        </Grid>
        <Grid item xs={6}>
            <div style={{ fontWeight: 'bold', marginBottom: '0.3em' }}>Select Test Type</div>
            <ul>
                <li><Button onClick={()=>selectTestType('quiz')}>Quiz Test</Button></li>
                <li><Button onClick={()=>selectTestType('coding')}>Coding Test</Button></li>
                <li><Button onClick={()=>selectTestType('design')}>Design Test</Button></li>
            </ul>
            <div style={{ fontWeight: 'bold', marginTop: '2em' }}>Test Link:</div>
        </Grid>
        <Grid item xs={6}>

        </Grid>

    </Grid>





}

export default AssessTestLink;