

// ws.onmessage = (webSocketMessage) => {
//     const messageBody = JSON.parse(webSocketMessage.data);
//     const cursor = getOrCreateCursorFor(messageBody);
//     cursor.style.transform = `translate(${messageBody.x}px, ${messageBody.y}px)`;
// };        

// document.body.onmousemove = (evt) => {
//     const messageBody = { x: evt.clientX, y: evt.clientY };
//     ws.send(JSON.stringify(messageBody));
// };
// let ws = null;
// export const wsConnect = async(message, id)=> {
    
//     const connectToServer = async()=> {    
//         const ws = new WebSocket('ws://localhost:7071/ws') ;//'+id);
//         return new Promise((resolve, reject) => {
//             const timer = setInterval(() => {
//                 if(ws.readyState === 1) {
//                     clearInterval(timer);
//                     resolve(ws);
//                 }
//             }, 10);
//         });   
//     }
//     if(ws==null) {
//         ws = await connectToServer();
//         console.log('new connection')
//     }
//     ws.send(message);
    
//     //return ws;
// }

//export const send = (message)=>ws.send(message);



export const connectToServer = ()=> {    
    const ws = new WebSocket('ws://localhost:7071/ws1234') ;//'+id);
    return new Promise((resolve, reject) => {
        const timer = setInterval(() => {
            if(ws.readyState === 1) {
                clearInterval(timer);
                resolve(ws);
            }
        }, 10);
    });
}

