import { Paper, Grid, makeStyles, MenuItem, TextField, Button } from "@material-ui/core";
import { useState } from "react";
import TextEditor from "../editor-text/TextEditor"
// import  blogPosts from require('../../../data/blog-post/blogPosts');

const blogPosts = require('../../../data/blog-post/blogPosts').blogPosts;
const useStyles = makeStyles((theme) => ({
    body: {
      padding: "60px 60px",
    },
    inputBox: {
      width: "392px",
    },
    // inputButton: {
    //     width: "120px",
    //     display: 'inline-block',
    //     fontSize: '0.8em',
    //     height: '4.5em',
    //     fontWeight: 500,
    //     color: '#fff',
    //     background: '#FF659D'
    // },
    submitButton: {
      width: "400px",
      marginLeft:'1.2rem'
    },
  }));

const BlogEditorial = (props) => {
    const classes = useStyles();

    const { post, heading } = props;
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');

    const [userInfo, setUserInfo] = useState({
        name:'',
        email:'',
        company:'',
        designation:'',
        experience: '',
    });

    const handleInput = (key, value) => {
        setUserInfo({
          ...userInfo,
          [key]: value,
        });
        console.log('setUserInfo:', key, value, userInfo.name);
    };

    const submitPost = ()=>{
        //TODO:: update into database
        console.log(userInfo, title, content);
    }

    const contentChanged = (content)=>{
        console.log('body:', content);
        setContent(content);
    }
    return (
        <Paper elevation={2} style={{padding:'1rem',}}>
        <Grid xs={12} container direction="column"  spacing={4}>
                <Grid item>
                    <h2><strong>{heading}</strong></h2> 
                </Grid>
                {!post&&<Grid item style={{ width: "100%"}}>
                        <TextField
                            label="Title (upto 150 words)"
                            multiline
                            rows={2}
                            style={{ width: "100%" }}
                            variant="outlined"
                            value={title}
                            onChange={(event) => {
                            if (
                                event.target.value.split(" ").filter(function (n) {
                                return n != "";
                                }).length <= 150
                            ) {
                                setTitle(event.target.value);
                            }
                            }}
                        />
                    </Grid>
                }
                <Grid item>
                    <TextEditor style={{fontSize:30, width: "100%"}} toolbar={post?false:true} content={content} contentChanged={contentChanged}/>
                </Grid>
                <Grid item container direction="row" spacing={1}>
                    <Grid item >
                    <TextField
                        label="Input Name"
                        variant="outlined"
                        className={classes.inputBox}
                        value={userInfo.name}
                        onChange={(event) => {handleInput("name", event.target.value)}}
                    >
                    </TextField>
                    </Grid>
                    <Grid item >
                        <TextField
                            label="Input Email"
                            variant="outlined"
                            className={classes.inputBox}
                            value={userInfo.email}
                            onChange={(event) => {handleInput("email", event.target.value)}}
                        >
                        </TextField>
                    </Grid>
                    <Grid item >
                        <TextField
                            label="Company Name"
                            variant="outlined"
                            className={classes.inputBox}
                            value={userInfo.company}
                            onChange={(event) => {handleInput("company", event.target.value)}}
                        >
                            {/* {
                            educations.map((key)=>{
                                return <MenuItem id={key} value={key}>{key}</MenuItem>
                            })
                            } */}
                        </TextField>
                    </Grid>                    

                    <Grid item >
                        <TextField
                            label="Designation"
                            variant="outlined"
                            className={classes.inputBox}
                            value={userInfo.designation}
                            onChange={(event) => {handleInput("designation", event.target.value)}}
                        >
                            {/* {
                            educations.map((key)=>{
                                return <MenuItem id={key} value={key}>{key}</MenuItem>
                            })
                            } */}
                        </TextField>
                    </Grid>

                    <Grid item>
                        <TextField
                            select
                            label="Total Experience"
                            variant="outlined"
                            className={classes.inputBox}
                            value={userInfo.experience}
                            onChange={(event) => {handleInput("experience", event.target.value)}}
                        >
                            {
                                [...Array(40)].map((_,key)=>{
                                return <MenuItem key={key} value={key}>{key} &nbsp;Years</MenuItem>
                                })

                            }
                        </TextField>
                    </Grid>

                </Grid>
                <Grid item>
                    {/* <input className={classes.inputButton} type="submit" value="Submit Post" name="subscribe" id="mc-embedded-subscribe" class="button"></input> */}
                    <Button style={{background:'#FF659D', height:'3rem' , width:'10rem' }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            submitPost();
                        }}
                        className={classes.submitButton}
                        >
                            {post?'Post Comment':'Submit Post'}
                    </Button>
                </Grid>
        </Grid>
        </Paper>
    )
}

export default BlogEditorial