import { Button, Checkbox, FormControlLabel, Grid , Modal, Paper, Slider, TextField, makeStyles} from "@material-ui/core";
import "./CV.scss";
import SectionWorkExperince from "./SectionWorkExperience";
//import {resdata} from "./resume_data"
import { wordFormatter } from "./wordUtils";
import LanIcon from '@mui/icons-material/Lan';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EditRounded from "@material-ui/icons/EditRounded";
import JSONEditor from '../editor/editor-json/json-editor';
import { useContext, useEffect, useRef, useState } from "react";
//import { ArrowDown, ArrowUp, ArrowUpCircle, Edit, Loader } from "react-feather";
import { useDispatch, useSelector } from 'react-redux';
import {editSection} from "../../state/action-creators/profile/index";
import ReactToPrint from "react-to-print";

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { MenuItem } from "@material-ui/core";
import apiList from "../../config/apiList";
import axios from "axios";
import { SetPopupContext } from "../home/component/MainRouter";
import {editContent, editContentMulti} from "./utils";
import { resEmpty, resSampleData, resSampleDataShort, resTMPLT } from "./resumeTemplate";
import { API, saveData, saveDataWoToken } from "../../config/apiService";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PersonIcon from '@mui/icons-material/Person';
import { ArrowUpward, CloudUploadOutlined } from "@material-ui/icons";
import { handleUpload } from "../login/lib/UploadFile";
import FileUploadInput from "../login/lib/FileUploadInput";
import DescriptionIcon from "@material-ui/icons/Description";
import FaceIcon from "@material-ui/icons/Face";
import TimelapseIcon from '@mui/icons-material/Timelapse';
import JLSVG from '../../assets/svg/jl.svg';
import { showErrorToast } from "../../utils/toastMsg";
import { isMobile } from "../../utils/mobileUtil";
const iconGroup = ['mobile', 'email', 'workexp', 'exp', 'social', 'assess', 'workspace'];
const TEMPLATE_NAME =`CURRENT PROFILE`;
const TEMPLATE_EMPTY = 'TEMPLATE - EMPTY';

const selectIcon = ((section)=> {
    //const JLIcon = require('../../assets/img/joblient.png');
    const JLSVG = require('../../assets/svg/jl.svg');
    let icon = iconGroup.map((_, i)=>{
        if( section.toLowerCase().indexOf(iconGroup[i]) >= 0 ) return iconGroup[i];
    }).filter( (item)=> item!= undefined)[0];
    //console.log('icon', icon);

    switch (icon){
        case 'mobile':
            return <MobileScreenShareIcon />;
        case 'email':
            return <EmailIcon/>;
        case 'workexp':
            return <WorkHistoryIcon className="headerIcon"/>
        case 'social':
            return <LinkedInIcon/>;   
        case 'exp':
            return <TimelapseIcon/>;        
        case 'assess':
            return <AssessmentIcon/>;
            // return <img height={'15px'} width={'15px'} src={JLIcon} alt="Project" />
            //return <JLSVG/>
        default:
            return <WorkspacesIcon className="headerIcon"/>;
    }
});


// profile && profile.contactinfo && Object.keys(profile.contactinfo).forEach((key)=>
// //arr.push(profile.contactinfo[key]);
// <div>{profile.contactinfo[key].content}</div>
// )


const $fontSizeLarge = '1.3em';

const style= {
    header: {
            fontWeight:'bold',
            height: '1.8em',
            display:'inline-flex',
            //width:'100%', 
            border:'2px', 
            background:'#1c0147',
            borderRadius: '30px',
            color: '#f6f1f5',
            marginLeft:'0em', 
            marginBottom:'0.3em',
            marginTop:'1em',
            //verticalAlign:'middle',
            alignItems: 'center',
            justifyContent: 'center',

        },
    headIcon:{
        marginLeft:'-2em', 
        marginTop:'0em',
        display:'block',
        alignItems: 'left',
        justifyContent: 'left',
        textAlign:'left',
    },
    headerRight: {
        fontWeight:'bold',
        //height: '20vh',mode
        //display:'inline-flex',
        width:'151.3%', 
        border:'1.5px solid gray', 
        //background:'#1c0147',
        borderRadius: '8px',
        display:'flex',
        //color: '#f6f1f5',
        marginLeft:'-51%', 
        marginBottom:'1em',
       // marginTop:'1em',
        // borderTop: '2px solid',
        // borderBottom: '2px solid',
        // borderRight: '2px solid',
       // borderColor: 'gray' // 'rgb(132, 126, 125)'
    },

}

const CV = (props) => {
    const { readOnly, userId , mode} = props;
    const token = useSelector(state=>state.profile.profile.token);
    const setPopup = useContext(SetPopupContext);
    const dispatch = useDispatch();
    const [edit, setEdit ] = useState(false);
    //const profile = useSelector(state=>state.profile.section);
    const [profile , setProfile] = useState({});
    const [pic, setPic] = useState(JLSVG);
    const [image, setImage] = useState(false);
    const [showImg, setShowImg] = useState(false);
    const [templates, setTemplates] = useState(resTMPLT);
    const [template, setTemplate] = useState(TEMPLATE_NAME);

    const [width, setWidth] = useState({width:'0%'});
    const [widthR, setWidthR] = useState({width:'100%'});
    const [sel, setSel ] = useState('');
    const [profileSaved , setProfileSaved] = useState();
    const [ basicInfo, setBasicInfo] = useState();
    const [file, setFile] = useState('');
    const [include, setInclude] = useState(true)
    const [multiline, setMultiline] = useState(false)

    //console.log('new profile set: ', profile);

   const resumeRef = useRef();
   useEffect(() => {
        console.log('userId123:',readOnly, userId);
        getData();
        //includeAssessment();
        //setProfile(editData);
    }, []);

    const showPicButton = (show, image)=>{
        {console.log('checking:::::::::::::',readOnly,showImg, image,  (!readOnly && (showImg || !image)))}
        if (image === false || (image + "").indexOf('blob') > -1) {
            console.log("show image", image, (image + "").indexOf('blob'));
            show = true;
        } else {
            show = false;
        }
        

        if(readOnly) {
            show = false;
        }
        setShowImg(show);
        {console.log('checking after:::::::::::::',readOnly,show, image)}
    }
    // const setProfile = ()=>{
    //     dispatch(editSection({...profile}));
    //     //console.log('saved:', profileSaved)
    // }

    const resumeEdit = (sec)=> {
        //setSection(resdata); 
        //console.log('before:',profile, profileSaved)
        
        if(!edit) {
            setSel('doc');
            setWidth({width:'50%'}); 
            setWidthR({width:'50%',maxHeight: '90vh', overflow: 'auto'}); 
        } else {
            setSel('');
            getData();
            setWidth({width:'0%'}); 
            setWidthR({width:'100%'}); 
        }
        setEdit(!edit);
        console.log('edit:', !edit)
    }

    const saveEdit = ()=> {
        setSel('');
        setWidth({width:'0%'}); 
        setWidthR({width:'100%'}); 
        setEdit(!edit);
    }

    const setSection = (data)=>{
        console.log('section----'); 
        //dispatch(editSection(data));
        setProfile(data);
    }

    const setProfileChange = (jsonObject)=>  {
            //console.log('changed data ******', Object.keys(jsonObject).length);
            let temp;
            if( Object.keys(jsonObject).length == 1) {
                temp = {...profile};
                for (let key in jsonObject) {
                    temp[key] = jsonObject[key];
                }
            } else {
                temp = {...jsonObject};
            }
           // console.log('******temp', temp);
            setProfile(temp);
    }

    const getWidth = ()=>{
        return width;
    }
    const getWidthR = ()=>{
        return widthR;
    }

    const getPaperStyle = ()=>{
        return edit ? {padding:'1rem',  maxHeight: '85.9vh', overflow: 'auto'} : {padding:'1rem'}
    }


    const sliderChanged = (v)=>{
        let wR = 100 - v.target.innerText;
        console.log( v.target.innerText , wR); //{width:`${v.target.value}%`});
        setWidth( {width:`${v.target.innerText}%`}); 
        setWidthR( {width:`${wR}%`}); 
    }

    const info = ()=>{
        let arr=[];
        profile && profile.contactinfo && Object.keys(profile.contactinfo).forEach(function(key) {
            arr.push({sec: key, data: profile.contactinfo[key]});
        })
         return arr;
    }

    const handleReset = ()=>{
        //handleReset
    }

    const setAssess = (prof)=>{
        if(prof.contactinfo.assess){
            setInclude(true);
            includeAssessment(true, prof);
          } else if (include) {
                console.log('set assessment:')
                includeAssessment(true, prof);
          }

    }

    const getData = ()=>{
        //const email = localStorage.getItem("email")
        let address = apiList.resume;
        if(userId) {
            if(mode==='applicant') {
                address = `${address}?userId=${userId}&unlock=${mode?'true':'false'}`
            } else {
                address = `${address}?userId=${userId}`
            }
        }
        console.log('userId', 'address: ', userId, address)
        axios
        .get(address, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((resume) => {
          console.log('resume124:', resume);

         
          if ( resume.data.userId !== undefined ) {
              let addressPic = apiList.uploadProfileImage+`?_id=${resume.data.userId}_${resume.data.pic}`;
              console.log('addressPic:', addressPic);
              if(resume.data.pic !== undefined) {
                setImage(addressPic);
                showPicButton(true, addressPic);
              }
              let addressFile = apiList.uploadResume+`?_id=${resume.data.userId}_${resume.data.file}`;
              console.log('addressFile:', addressFile);
              if(resume.data.file !== undefined) {
                  setFile(addressFile)
            }
          }

          if (resume.data.resumeData) {
            //dispatch(editSection(resume.data.resumeData));
           

            setProfile(resume.data.resumeData);
            if(!(readOnly===true)) { // set assessment link for logden user not for other user's profile
                setAssess(resume.data.resumeData)
            }
            // let cur = templates.filter((v)=>v.key===TEMPLATE_NAME);
            // if(cur.length === 0) {
            //     let temp = [...templates];
            //     temp.push({key:TEMPLATE_NAME, value:resume.data.resumeData});
            //     setTemplates(temp);
            //     setTemplate(TEMPLATE_NAME);
            //     console.log('inside current profile:', temp)addressFile
            // } 
            let temp = [...resTMPLT];
            temp.push({key:TEMPLATE_NAME, value:resume.data.resumeData});
            setTemplates(temp);
            setTemplate(TEMPLATE_NAME);

          } else {
            if(!(readOnly===true)) {
                // setPopup({
                //     open: true,
                //     severity: "info",
                //     message: "Profile is empty, complete and save profile",
                // });
                //showErrorToast("Your profile is incomplete, please complete it before continuing with another task.", 1000);
                let temp = {...resSampleDataShort};
                temp.heading.name = resume.data.name;
                temp.contactinfo.email.content = resume.data.email;
                temp.contactinfo.mobile.content = resume.data.mobile;
                setBasicInfo({name: resume.data.name, email: resume.data.email, mobile: resume.data.mobile});
                setTemplate(TEMPLATE_EMPTY)

                //dispatch(editSection(temp));
                setProfile(temp);
                setAssess(temp)
            } else {
                console.log('you are not authorise to get details')
                //dispatch(editSection({}));
                setProfile({});
            }
          }
         
        })
        .catch((err) => {
          console.log('Error123:', err);
          //dispatch(editSection({}));
          //setBasicInfo({name: 'Your Name', email: 'email...', mobile: ''});
          if(!(readOnly===true)) {
            setTemplate(TEMPLATE_EMPTY);
            //dispatch(editSection(resSampleDataShort));
            setProfile(resSampleDataShort);
            setPopup({
                open: true,
                severity: "error",
                message: "Error",
            });
          } else {
            //dispatch(editSection({}));
            setProfile({});
            setPopup({
                open: true,
                severity: "error",
                message: "You are not authorized to view candidate profile details, need login",
            });
          }
        });
    };


    const getDataWT = ()=>{
        const email = localStorage.getItem("email")
        axios
        .get(apiList.resume+`?email=${email}`, {
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem("token")}`,
        //   },
        })
        .then((resume) => {
          console.log('resume123:', resume.data.resume);
          //dispatch(editSection(resume.data.resume));
          setProfile(resume.data.resume);
        })
        .catch((err) => {
          console.log('Error:', err);
          SetPopupContext({
            open: true,
            severity: "error",
            message: "Error",
          });
        });
    };

    const phoneValidation = (phone)=> {

        var phoneRegex = /\+?[\s.-]?\d{0,2}([\s.-]?\d){10,13}/gi;
        var phoneMatches = phone.match(phoneRegex);

        return phoneMatches;

        // const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
        // return !(!phone || regex.test(phone) === false);
    }

    const saveResume = async ()=>{

        // let verify = phoneValidation(profile.contactinfo.mobile.content);
        // console.log('verify:', verify)
        // let verify =profile.contactinfo.mobile.content.match(/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i);                     //(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
        // console.log('verify:', verify)

        // if (!verify) {
        //     showErrorToast("Invalid Mobile Number "+ profile.contactinfo.mobile.content, 3000);
        //     return false;
        // }
        if (localStorage.getItem("type")==='guest') {
            showErrorToast("Guest user profile cannot be modified, signup and update your profile, it's free", 5000);
            return;
        }

        const token = localStorage.getItem("token");
        console.log('token:', token);
        const resumeInfo = {
            meta: { addedBy:"user", state:'full'},
            resumeData: profile
        }
        if(token === null) {
            let res = await saveDataWoToken(`${apiList.resume}/anonymous`, resumeInfo, 'Profile Saved', setPopup);
            console.log(res);
        } else {
            let res = await saveData(apiList.resume, resumeInfo, 'Save Profile', setPopup);
            console.log(res);
            if(res.data && res.data.hasProfile) {
                localStorage.setItem('hasProfile', res.data.hasProfile);
                
            }
        }
        console.log("templates:", templates)
        getData();
        
        saveEdit()
        return true;
    }

    const handleDownload = ()=>{
        //const email = localStorage.getItem("email")
        console.log('profile', profile)
        axios
        .post(apiList.resume+`/download`, profile, {responseType: 'arraybuffer'})
        .then((response) => {
            console.log("data:",response)
            const file = new Blob([response.data], { type: "application/pdf" });
            const fileURL = window.URL.createObjectURL(file);
            window.open(fileURL);
          })
        .catch((err) => {
          console.log('Error:', err);
          setPopup({
            open: true,
            severity: "error",
            message: "Error",
          });
        });
    }

    const includeAssessment = (state, prof=profile)=>{
        console.log('Include Assessment:', state);
        let temp = {...prof};
        if (state===true) {
            console.log('temp.contactinfo:',temp.contactinfo)

            temp.contactinfo.assess = {};
            temp.contactinfo.assess.section = "Assessment Link";
            let tiny = localStorage.getItem('tiny');
            temp.contactinfo.assess.content = `${document.location.host}/assess/${tiny}`; //rawHyNcQkr`;
        } else {
            delete temp?.contactinfo?.assess;
        }

        setProfile(temp);

    }

    const subsec = (info , key)=> {
       
        const {section, content} = info.data;
        const { sec } = info;
       // console.log(sec, 'contactinfo:', profile.contactinfo[sec]);

        return <div key={key} className="section-general">
                <div  style={{display:'inline-flex'}}>
                    {selectIcon(section)}
                    <div style={{marginLeft:'0.4em', fontWeight:'bold'}}> {section}</div>
                </div>
                <div  className="pl-7 md:pl-8">
                    { !edit || (token && sec === 'email') ? <div>{content}</div>
                      : <TextField
                            multiline
                            style={{width:'80%', background:'white', display:'inline-flex'}}
                            value={profile.contactinfo[sec].content}
                            onChange={(event) => {

                                //console.log('res::::', sec, parseInt(event.target.value, 10) , event.target.value);
                                if (sec === 'experience') {
                                    if (event.target.value !="" && parseInt(event.target.value, 10) != event.target.value)
                                        return;
                                }
                                let temp = {...profile}
                                temp.contactinfo[sec].content = event.target.value
                                setProfile(temp);
                            }}
                        />
                    }
                    
                </div>
            </div>
    }
    
    const secHeader = (section, type, edit, setSection, profile, icon)=>{
         //console.log('ICONNNNNNNNNNNNNNNNNN:',section , icon)
        return <div className="flex">
                    {icon&&<div className="w-8 -mr-10 z-50 " style={style.header}>
                        {selectIcon(type)}
                    </div>}
        
                    <div className="w-full" style={style.header}>
                    {
                        edit? <TextField className="text-[9px] md:text-[17px]"
                        multiline
                        
                        style={{ width:'70%', marginTop:'0.1em', marginLeft:'10%', display:'inline-flex',color:'red', background:'white'}}
                        value={profile[type].section}
                        onChange={(event) => {
                            if (event.target.value.length>30) {
                                showErrorToast("Max header allowed are 30 length")
                                return; 
                            }
                            let temp = {...profile}
                            temp[type].section = event.target.value
                            setProfile(temp);
                        }}
                        />
                        :
                        <div className='text-[10px] md:text-2xl'> 
                                {section}
                        </div>
                    }
                    </div>
                </div>
    }
    const useStyles = makeStyles((theme) => ({
        noBorder: {
          border: "none",
          padding: 5
        }
    }));
    const classes = useStyles();
    //console.log("PROFILE::::::::", Object.keys(profile).length, profile, editData)
    if(Object.keys(profile).length==0) return <></>
    
    return (
       
        <div className="styleCV text-[10px] md:text-[17px]" style={{marginTop: `${readOnly?'0em': '2em'}` }}>
           {/* <div style={{height:'600px', width:'1000px'}}> */}
           {readOnly&&<div className="pageTitle">Candidate Profile</div>}
            {   readOnly ?
                <Grid>
                    <Button style={{background:'#44c6f4',color:"white", marginLeft:'2em', marginBottom:'1em', height:'2em', borderRadius:'20px'}}
                        variant="contained"
                        
                        onClick={()=> handleDownload()}
                        >
                        Print <ArrowUpward/>
                    </Button>
                    {
                    (mode==='applicant' && file) && <a type="button" href = {file} target = "_blank" 
                        style = {{cursor:'pointer', float:'right', display:'inline-flex', textDecoration:'none', textAlign:'center', minWidth:'10em',fontSize:'1em', height:'3em', marginLeft:'-1em', marginTop:'0.6em'}}>
                            Download Resume
                    </a> 
           }
                </Grid> 
                :
                <Grid container direction="row">
                    {localStorage.getItem('hasProfile')==='false' && <Grid item xs={12}>
                        <span style={{ marginLeft:'1.7em',  color:'red'}}>Your profile is incomplete, please complete it before continuing with another task.</span>
                    </Grid>}
                    <Grid item xs={'auto'} className="flex text-[10px] md:text-[17px]">
                        <Button style={{background:'#44c6f4',color:'white', marginLeft:'10px', height:'2em', borderRadius:'20px' }} variant="contained" onClick={()=> resumeEdit('doc')}>
                                {edit?'CANCEL':'EDIT'}
                        </Button>
                        {edit&&<Button style={{background:'#44c6f4',color:'white', marginLeft:'1em', height:'2em', borderRadius:'20px' }} variant="contained" onClick={()=>{ 
                                saveResume()}}>
                                SAVE
                        </Button>}
                        {!edit&&<Button style={{background:'#44c6f4',color:'white', marginLeft:'1em',marginRight:'1em', height:'2em' ,borderRadius:'20px'}}
                                variant="contained"
                               
                                onClick={()=> handleDownload()}
                                >
                                PRINT <ArrowUpward/>
                        </Button>}
                        <FormControlLabel  className='ml-10 -mt-2 md:-mt-7' control={<Checkbox checked={include} onChange={()=>{setInclude(!include); includeAssessment(!include)}} />} label="Include Assessment" />

                    {/* <ReactToPrint 
                        trigger={() => {
                            return (
                            <Button disabled={edit} style={{background:'#DBEEEC', marginLeft:'0.5em', height:'1.8em', borderRadius:'20px'}} variant="contained" color="seconary" >
                                Download <ArrowDown />
                            </Button>
                            );
                        }}
                        content={() => resumeRef.current}
                    /> */}
                    </Grid>
                    <Grid item xs={'auto'}>
                        <TextField className="search text-10px" style={{textAlign:'center', marginLeft:'1em', marginTop:'0em'}}
                            sx= {{"& .MuiOutlinedInput-root.Mui-disabled":{"& > fieldset": {border: '2px solid red'}}
                            ,"& .MuiOutlinedInput-input": {"& > fieldset":{
                                padding: '8.5px 14px'
                            }}
                        }}
                            select
                            variant="outlined"
                            margin="normal"
                            required
                            fullwidth="true"
                            disableunderline="true"
                            size="small"
                            //label="Select Resume Data Template"
                            value={template}
                            onChange={(event) => {
                            setTemplate(event.target.value);
                            let item = templates.filter( (v)=>(v.key == event.target.value))
                            if(item.length > 0) {

                                let temp = item[0].value;
                                if(basicInfo != null) {
                                    temp.heading.name = basicInfo.name;
                                    temp.contactinfo.email.content = basicInfo.email;
                                    temp.contactinfo.mobile.content = basicInfo.mobile;
                                }
                                //dispatch(editSection({...temp}));
                                console.log('set profile:',temp)
                                setProfile(JSON.parse(JSON.stringify(temp)));
                            }
                            }}
                            InputProps={{
                                style:{fontSize:'12px'},
                                classes:{notchedOutline:classes.noBorder}
                            }}
                        >
                            {
                            templates.map((item, i)=>
                                <MenuItem key={i} value={item.key}>{item.key}</MenuItem>
                            )
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={'auto'} style={{marginBottom:'0.5em'}}>
                        <FileUploadInput
                            className={classes.inputBox}
                            label="Resume (.pdf)"
                            text ="Upload Resume"
                            icon={<DescriptionIcon color="primary" />}
                            value={file}
                            onChange={(event) => setFile(event.target.files[0])
                                // setFiles({
                                //   ...files,
                                //   resume: event.target.files[0],
                                // })
                            }
                            uploadTo={apiList.uploadResume}
                            //handleInput={handleInput}
                            identifier={"resume"}
                            //userId = {signupDetails.email}
                            file = {file}
                            setFile = {setFile}
                        />
                    </Grid>
                </Grid> 
           }

           <Grid container direction="row"  spacing={0}>
                <div style={{display:'inline-flex',wordWrap:"break-word"}}>
                <div style={{width:'100%'}}>
                    <Paper ref={resumeRef} elevation={2} style={getPaperStyle()}>

                        <Grid container direction="row" spacing={2}>
                            
                                <Grid item xs={4} className="lpan">
   
                                    {/* GENERAL INFO SECTION */}

                                    <div className="mt-24 md:mt-36">{info().map( (item, key)=> subsec(item, key))}</div>

                                    {/* ABOUTME SECTION */}
                                    {console.log('profile.aboutme.section:', profile.aboutme.section) }

                                    {profile && profile.aboutme && profile.aboutme.section &&
                                        <div >
                                            {secHeader(profile.aboutme.section,'aboutme', edit, setSection , profile, !isMobile)}
                                           
                                            {!edit?
                                             <div className="section-item">{wordFormatter(profile.aboutme.content)}</div>
                                            : <TextField
                                                multiline
                                                style={{ width:'100%', display:'inline-flex'}}
                                                value={profile.aboutme.content}
                                                onChange={(event) => {
                                                    let temp = {...profile}
                                                    temp.aboutme.content = event.target.value
                                                    setProfile(temp);
                                                }}
                                            />
                                            }
                                        </div>
                                    }

                                    {profile && profile.coreskills && profile.coreskills.section &&
                                        <div >
                                            {secHeader(profile.coreskills.section,'coreskills', edit, setSection , profile, !isMobile)}
                                            <div className="section-core">
                                                {profile.coreskills.content.map((_, key)=>
                                                edit?editContentMulti(profile,setProfile,'coreskills', key,profile.coreskills.content.length) :
                                                <div key ={key} className="section-item-core">
                                                    <div>{profile.coreskills.content[key].skill}</div>
                                                    <div style={{display:'flex'}}>
                                                        {
                                                            profile.coreskills.content[key].rating&&[...Array(parseInt(profile.coreskills.content[key].rating))].map((_,i)=>{
                                                                return <div key={i} style={{fontSize:24, padding:'1px', color:'lightslategray'}}>*</div>
                                                            })
                                                        
                                                        }
                                                    </div>
                                                </div>
                                                )}
                                            </div>
                                        </div>
                                    }

                                    {profile && profile.techskills && profile.techskills.section &&
                                        <div >
                                            {secHeader(profile.techskills.section,'techskills', edit, setSection , profile, !isMobile)}
                                            <div className="section-core">
                                                {profile.coreskills&& profile.techskills.content.map((_, key)=>
                                                
                                                <div key={key} className="section-item-core">
                                                    <div>{edit?editContent(profile,setProfile,'techskills', key,profile.techskills.content.length) :profile.techskills.content[key]}</div>
                                                </div>
                                                )}
                                            </div>
                                        </div>
                                    }


                                    {/* PERSONAL INFO SECTION */}

                                    {profile && profile.personalinfo && profile.personalinfo.section &&
                                        <div>
                                            {secHeader(profile.personalinfo.section,'personalinfo', edit, setSection, profile, !isMobile)}
                                            <div>
                                                <div className="flex space-x-4 text-[9px] md:text-[17px]"><strong><span>Date of Birth:</span></strong>
                                                    {!edit ? <span>{profile.personalinfo.dob}</span>
                                                    : <TextField
                                                        style={{ maxWidth: "8em", display:'inline-flex'}}
                                                        value={profile.personalinfo.dob}
                                                        onChange={(event) => {
                                                            let temp = {...profile}
                                                            temp.personalinfo.dob = event.target.value
                                                            setProfile(temp);
                                                        }}
                                                    />
                                                }
                                                </div>
                                                <div className="flex space-x-4 text-[9px] md:text-[17px]"><strong className="mr-2 md:mr-9"><span>Location:</span></strong>
                                                    {!edit ? <span>{profile.personalinfo.location}</span>
                                                    : <TextField
                                                        style={{ maxWidth: "8em", display:'inline-flex'}}
                                                        value={profile.personalinfo.location}
                                                        onChange={(event) => {
                                                            let temp = {...profile}
                                                            temp.personalinfo.location = event.target.value
                                                            setProfile(temp);
                                                        }}
                                                    />
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Grid>
                                {/***************************************************************************
                                 ************************************SECOND PART OF RESUME*******************
                                * **************************************************************************
                                */}
                                {/* SECTION - IInd PART*/}

                                <Grid  item xs={8} className="rpan">
                                    <div style={style.headerRight} onMouseOver={()=>setShowImg(!readOnly && true)} onMouseOut={()=>setShowImg(false)}>
                                        <div className="w-[60%] md:w-[40%] flex" style={{display:'flex',  marginLeft:'10%', padding:'0.5em'}} >
                                            <Button className="style_profile_pic h-[100%]" variant="contained" component="label" style={{backgroundImage:`url(${image})`}} >
                                                {/* <img height={'50px'} width={'60px'} style={{zIndex:100, marginLeft:'-20px', marginTop:'0.4em'}} src={JLIcon} alt="Project" >
                                                    no-repeat center fixed
                                                </img> */}
                                                { !readOnly && (showImg || !pic || !image) && 
                                                <div>
                                                <AddAPhotoIcon/>
                                                <input type="file" style={{ display:'none' }}
                                                    onChange={(event) => {
                                                        console.log('file: ', event.target.files[0] );
                                                        if (event.target.files && event.target.files[0]) {
                                                            setPic(event.target.files[0])
                                                            setImage(URL.createObjectURL(event.target.files[0]));
                                                        }
                                                    }}
                                                />
                                                </div>}

                                             </Button>
                                             { showImg&&
                                                <CloudUploadOutlined className="mt-4 md:mt-8 cursor-pointer scale-150"
                                                    onClick = {()=> 
                                                    handleUpload(API.uploadProfileImage, pic, setPopup , 'Profile pic')
                                            }/> }
                 
                                        </div>
                                        
                                        <Grid container direction="column" alignItems="center" style={{marginLeft:'-40%', marginBottom:'10px'}}>
                                            <Grid item sx={8} style={{marginTop:'0em', fontSize:'1.7em', fontWeight:'bold'}}>
                                                {profile && profile.heading && !edit ? <span  style={{display:'inline-block', padding:'0.2em 0 0 0.5em'}}>{profile.heading.name}</span>: 
                                                    <TextField
                                                        style={{ width: "10em", display:'inline-flex'}}
                                                        value={profile.heading.name}
                                                        onChange={(event) => {
                                                            let temp = {...profile}
                                                            temp.heading.name = event.target.value
                                                            setProfile(temp);
                                                        }}
                                                    />
                                                }
                                            </Grid>
                                            <Grid item style={{display:'inline-block',  marginLeft: '0em',marginTop:'0.2em', paddingLeft:'0.4em', fontSize:'0.8em', minWidth:'6em'}}>
                                                { profile.heading && !edit ? <span  style={{display:'inline-block', padding:'0.5em', background:'rgb(219 219 219)', borderRadius:'10px'}}>{profile.heading.designation}</span>:
                                                  <TextField
                                                    style={{ width: "15em", display:'inline-flex'}}
                                                    value={profile.heading.designation}
                                                    onChange={(event) => {
                                                        let temp = {...profile}
                                                        temp.heading.designation = event.target.value
                                                        setProfile(temp);
                                                    }}
                                                  />
                                                }
                                            </Grid>
                                        </Grid>
                                        
                                    </div>

                                    {profile && profile.summary && profile.summary.section &&
                                        <div>
                                            <div>
                                                {secHeader(profile.summary.section,'summary', edit, setSection , profile, true)}
                                            </div>
                                            {
                                                profile.summary.content.map((_, key)=>
                                                edit?editContent(profile,setProfile,'summary', key, profile.summary.content.length) :
                                                <div key={key} className="section-item sub" style={{marginLeft:'2.3em'}}>
                                                    <h6></h6><div className="headingBold">&nbsp;</div>
                                                    {wordFormatter(profile.summary.content[key])}
                                                </div>
                                            )}
                                        </div>
                                    }

                                    {/* SECTION - WORK EXPERIENCE DETAILS*/}

                                    {profile && profile.workexp && profile.workexp.section &&
                                        <div >
                                            <div style={{marginBottom:'1em'}}>
                                                {secHeader(profile.workexp.section,'workexp', edit, setSection , profile ,true)}
                                            </div>
                                            <SectionWorkExperince workexp={profile.workexp} edit={edit}/>
                                        </div>
                                    }
                                    
                                    {/* <div style={{height:'1rem', color:"white"}}>------------------------------------------------</div> */}
                                
                                    {/* SECTION - COURSES */}

                                    {profile && profile.courses && profile.courses.section &&
                                        <div >
                                            <div style={{marginTop:'0em'}}>
                                                {secHeader(profile.courses.section,'courses', edit, setSection , profile,true)}
                                            </div>
                                            {
                                                profile.courses.content.map((_, key)=>
                                                      edit? editContent(profile, setProfile,'courses', key, profile.courses.content.length)
                                                      : <div key={key} className="section-item-dot text" style={{marginLeft:'2.3em'}}>
                                                            <div className="sub">
                                                                <h6></h6><div>{profile.courses.content[key]}</div>
                                                            </div>
                                                        </div>
                                            )}
                                        </div>
                                    }

                                    {/* SECTION - EDUCATION */}

                                    {profile && profile.education && profile.education.section &&
                                        <div>
                                            <div>
                                                {secHeader(profile.education.section,'education', edit, setSection , profile,true)}
                                            </div>
                                            <div>{
                                                profile.education.content.map((_, key)=>
                                                    edit?editContent(profile,setProfile,'education', key, profile.education.content.length) :
                                                    <div key={key} className="sub" style={{marginLeft:'2.3em', marginTop:'0.2em', marginBottom:'0.2em'}}>
                                                        <h6><span className="mt-4">{profile.education.content[key]}</span></h6>
                                                    </div>

                                                )}
                                            </div>
                                        </div>
                                    }

                                    {/* SECTION - AWARDS */}

                                    {profile && profile.awards && profile.awards.section &&
                                        <div>
                                            <div>
                                                {secHeader(profile.awards.section,'awards', edit, setSection , profile, true)}
                                            </div>
                                            {
                                                profile.awards.content.map((_, key)=>
                                                edit?editContent(profile,setProfile,'awards', key,profile.awards.content.length) :
                                                <div key={key} className="section-item sub" style={{marginLeft:'2.3em'}}>
                                                    <h6></h6><div className="headingBold">&nbsp;</div>
                                                    {wordFormatter(profile.awards.content[key])}
                                                </div>
                                            )}
                                        </div>
                                    }
                                </Grid>
                        </Grid>
                    </Paper>
                </div>
                </div>
           </Grid>
        </div>
    )
}

export default CV;