
// import '../../editor/editor-shape/index.css';
import './Diagram.scss';
import * as React from "react";
import { DiagramComponent, SymbolPaletteComponent, Node, PrintAndExport, DiagramConstraints } from "@syncfusion/ej2-react-diagrams";
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import TextEditor from '../../editor/editor-text/TextEditor';
import { Collapse, Grid, ListItem, ListItemText } from '@material-ui/core';
import { API, saveData } from '../../../config/apiService';
import { SetPopupContext } from '../../home/component/MainRouter';
import {nodes, connectors, dragEnter, getNodeDefaults, getConnectorDefaults, gridlines, updateShapes} from '../../editor/editor-shape/ShapeUtil'

const ThumbnailDiagram = (props)=>{
    const { zoom, item, height, width } = props;
    let diagramInstance;
    //console.log('props:',props)
    React.useEffect(() => {
        //preventeditDiagram();
        return () => {
            if(diagramInstance)
                diagramInstance.destroy();
        }
    },[item, diagramInstance]);

    const preventeditDiagram =()=> {
        //diagramInstance.clearSelection();
        let zoomOptions = {
            type: "ZoomOut",
            //Sets the factor by which we can zoom in or zoom out
            zoomFactor: 1
          }
          //zoomout the diagram
        //   diagramInstance.zoomTo(zoomOptions)
        //   diagramInstance.zoomTo(zoomOptions)
          //diagramInstance.constraints = DiagramConstraints.None;
    }

    return (<Grid container direction="column" spacing={1} style={{}}>
            <Grid item container direction="row">
                <Grid item xs={12}> 
                <DiagramComponent  ref={diagram => (diagramInstance = diagram)}  width={width} height ={height} style={{overflow:'hidden'}} snapSettings = {{
                    horizontalGridlines: gridlines,
                    verticalGridlines: gridlines
                    }} 
                    nodes = {nodes(item.draw.nodes)} 
                    connectors = {connectors(item.draw.connectors)}
                    getNodeDefaults = {(node) => getNodeDefaults(node)}
                    getConnectorDefaults = {(obj) => getConnectorDefaults(obj)}
                    dragEnter = {(args) =>dragEnter(args)}
                    created={() => {
                        // Sets the zoomFactor
                        //Defines the focusPoint to zoom the Diagram with respect to any point
                        //When you do not set focus point, zooming is performed with reference to the center of current Diagram view.
                        diagramInstance.zoom(zoom, {
                        x: 0,
                        y: 0,
                        });
                        diagramInstance.constraints = DiagramConstraints.None;
                    }}
                />
            </Grid>
      </Grid>
    </Grid>)

}

export default ThumbnailDiagram